import { ApiService } from "../../config/apiServices";
import {REGISTER_AS_OPTIONS } from "../config/registerAsUrl/configUrl"

// export const getRegisterAsOptions = () => {
//     return ApiService.get(`${GET_REGISTER_AS_OPTIONS}`)
// }


export const getRegisterAsOptions = async () => {
    return ApiService.get(`${REGISTER_AS_OPTIONS}/1`)
    }
    
    export const getAtomAuthenticationOptions = async (payload) => {
      return ApiService.get(`${REGISTER_AS_OPTIONS}/2`, payload)
    }
