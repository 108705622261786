import React, { createContext, useState, useEffect } from "react";
import { getAllCityList, getAllCountryList, getAllStateList } from "../../../services/commonApiService/CommonApiService";


// Create a context
export const BasicDetailsContext = createContext();

// Create a provider component

export const BasicDetailsProvider = ({ children }) => {
  const [basicDetails, setBasicDetails] = useState([]);
  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);

  // Function to fetch countries
  // const fetchCountries = async () => {
  //   try {
  //     const response = await getAllCountryList();
  //     setCountries(response.data);
  //   } catch (error) {
  //     console.error("Error fetching countries:", error);
  //   }
  // };

  // Function to fetch states by country ID
  // const fetchStates = async (countryId) => {
  //   try {
  //     const response = await getAllStateList(countryId);
  //     setStates(response.data);
  //   } catch (error) {
  //     console.error("Error fetching states:", error);
  //   }
  // };

  // Function to fetch cities by state ID
  // const fetchCities = async (stateId) => {
  //   console.log("stateId", stateId);
  //   try {
  //     const response = await getAllCityList(stateId);
  //     setCities(response.data);
  //   } catch (error) {
  //     console.error("Error fetching cities:", error);
  //   }
  // };

  // Fetch countries on initial render
  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  return (
    <BasicDetailsContext.Provider
      value={{
        basicDetails,
        // countries,
        // states,
        // cities,
        setBasicDetails,
        // setCountries,
        // setStates,
        // setCities,
        // fetchCities,
        // fetchStates
      }}
    >
      {children}
    </BasicDetailsContext.Provider>
  );
};
