import React from 'react'
import "./regstyle.scss";


const RejectStatusCard = () => {
    return (
        <>
            <div>
                <div role="alert" aria-live="assertive" className="text-center">
                 
                 <p>Welcome to the <strong>Unified Sports Portal!</strong></p>
                 
                 <p>You Registration request is <span style={{color:"red"}}>not meeting</span> USP Criteria so it is <span style={{color:"red"}}>Rejected</span>.</p>
                 

                </div>

               
            </div>
        </>
    )
}

export default RejectStatusCard