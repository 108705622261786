import {
  REGISTER_USER,
  UPDATE_FIELDS_AT_EVERY_STEP,
  REGISTER_AS_OPTIONS,
  GET_REGISTRATION_LISTING,
  GET_CORRECTION_DATA
} from "./commonRegistartionApi/commonRegistrationApi";

import { UnAuthApiService, ApiService } from "./apiServices";
import { isConstructorDeclaration } from "typescript";
// export const BASE_URL = "http://127.0.0.1:8000";
// export const BASE_URL = "http://15.207.88.166:4001";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const userDataString = localStorage.getItem("login_Details");
const userData = JSON.parse(userDataString);
// console.log("userDatalocal",userData.type)

export const registerUser = async (payload) => {
  return UnAuthApiService.post(`${REGISTER_USER}`, payload);
};

export const updateDetailsAtEveryStep = async (payload) => {
  return  ApiService.post(`${UPDATE_FIELDS_AT_EVERY_STEP}`, payload);
};

export const get_Registration_Listing = async (id) => {
  let userDatatype = JSON.parse(localStorage.getItem("login_Details"))
  let user = JSON.parse(localStorage.getItem("user"))
  if(userDatatype?.type == 'individual' || user){
    return await ApiService.get(`${GET_REGISTRATION_LISTING}${id}`)
  }
  
 return;
}


export const getCorrectionData= async (id) => {
  return ApiService.get(`${GET_CORRECTION_DATA}/${id}`)
}