
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "./style.css";

import { useState } from 'react';
import { Link } from 'react-router-dom';



const Individual = () => {

    return (
        <>
            <Container className="py-5">
                <Row>
                    <Col lg={12} className="py-5">
                        <Card style={{ border:"1px solid"}}>
                            {/* <Card.Header> */}
                                {/* <div className="d-flex justify-content-between"> */}
                                <div className="regHeader">
                                 <aria-label class="nameHeader">Registration Status</aria-label>
                                </div>


                                    {/* <div>
                                        <Link to='/individual-profile'>
                                            <button className="button_title" >View Profile</button>
                                        </Link>


                                    </div> */}
                                {/* </div> */}

                            {/* </Card.Header> */}
                            <Card.Body className="py-5">

                                <Card.Text role="alert" aria-live="assertive" className="text-center profile_update_text">
                               <p>Welcome to the <strong>Unified Sports Portal!</strong></p> <br/>
                                <p style={{textAlign:"justify", paddingRight:"20px" , paddingLeft:"20px"}}>Your <span style={{color:"green"}}>onboarding</span> process is <span style={{color:"green"}}>initiated</span>, and now you need to further update/add your 
                                organization's details for the activation of your account on the Unified Sports Portal. 
                                After successful registration, your <strong>Provisional Registration Number</strong> will be generated, and your 
                                documents will be verified. <span style={{fontWeight:"500"}}>Once the documents are verified, your actual dashboard will be activated for 
                                using the features of the Unified Sports Portal.</span></p>
                                </Card.Text>


                                <div className="d-flex justify-content-end mt-5">
                                    <div>
                                        <Link to="/institution-profile-update">
                                        <Button variant="btn btn-success">Update Profile</Button>
                                        </Link>
                                       

                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>


        </>
    )
}

export default Individual