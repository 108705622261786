

export const  LeftStepperValues = (condition) => {
  switch (condition) {
    case "resister_as":
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
        "Register As",
        "Institution Details",
        "Personal Details",
        "Sport Details",
        "Education Details",
        "Bank Details",
      ];
    case "register_as_support_staff":
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
        "Register As",
        "Institution Details",
        "Basic Details",
        "Personal Details",
        "Bank Details",
      ];
    case "register_as_athlete":
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
        "Register As",
        "Institution Details",
        "Personal Details",
        "Sport Details",
        "Education Details",
        "Bank Details",
      ];
    case "register_as_technical_official":
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
        "Register As",
        "Institution Details",
        "Basic Details",
        "Personal Details",
        "Bank Details",
      ];
    case "common":
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
    
      ];
    default:
      return [
        "Aadhaar Verification",
        "Verify Contact Details",
        "Register As",
        "Institution Details",
        "Basic Details",
        "Personal Details",
        "Bank Details",
      ];
  }
};


