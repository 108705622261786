import { useForm } from "react-hook-form";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import LoginLeft from "../../components/LoginLeft";
import sajhaImg from "../../asserts/Footer/jharkhand logo 1 (1).png"
import jk_img from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png"
import {ReactComponent as BackIcon} from "../../asserts/login/backIcon.svg";
import{ReactComponent as ForwardIcon} from "../../asserts/login/forwardIcon.svg"

/**
 * @author KTSPL
 * @returns
 */
function Loginwithemail_V2() {
  const { handleSubmit, register } = useForm();
  const navigate=useNavigate()

  return (
    <div className="sign_in_right_container">
      <div className="loginLeft">
        <LoginLeft />
        <div className="jharkhand_logos_login">
          <div>
            <Link to="/">
              <img src={jk_img} width="80" />
            </Link>
          </div>
          <div>
            <Link to="/">
              <img src={sajhaImg} width="75" />
            </Link>
          </div>
        </div>
      </div>
      <div className="loginRight loginRightv2">
        <h1>Create New Password</h1>
        <p className="sub-heading px-5 mb-3">Enter new Password</p>
        <form>
          <div className="input_form_container">
            <input placeholder="New Password" id="email" />
            <input
              placeholder="Confirm Password"
              id="password"
              {...register("password")}
            />
          </div>

          <div className="sign_in_btn_container">
            <Link to="/login_email">
            <button className="submit-btn continue-btn" type="submit">
              Continue
            </button>
            </Link>
          </div>
        </form>
        <div className="arrow_container_forger_password">
            <p onClick={()=>navigate('/forget-password')}>Go Back</p>
          {/* <BackIcon onClick={()=>navigate('/forget-password')}/>
          <ForwardIcon onClick={()=>navigate('/login_email')}/> */}
        </div>
      </div>
    </div>
  );
}

export default Loginwithemail_V2;
