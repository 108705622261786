import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

const Error404 = () => {
  return (
    <>
      <Container fluid className="comming_bg center_title">
        <Container className="">
          <Row>
            <Col lg={12}>
              <div className="py-5 text-center mt-5 comming_soon_container">
                <span class="text1">
                  The Page You are looking for is not available
                </span>
                <span class="text2">PAGE NOT FOUND</span>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Error404;
