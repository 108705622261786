import React from 'react'

const Others = ({ Field,ErrorMessage ,isReadOnly,organisationList}) => {
   return (
      <>
         <h6 className="txtGap"> Facilities and Programs:</h6>
         <div className="row">
            <div className="col-lg-2 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label labels">Sports Offered</label>
                  <Field
                     name="sports_offered"
                     className="form-control"
                     placeholder="Sports Offered"
                     disabled={isReadOnly('sports_offered')}
                  />
                  {(!isReadOnly('sports_offered') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="sports_offered" component="div" className="text-danger" />
               </div>
            </div>
            <div className="col-lg-3 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label labels">Facilities Available</label>
                  <Field
                     name="facilities"
                     className="form-control"
                     placeholder="Facilities Available"
                     disabled={isReadOnly('facilities')}
                  />
                  {(!isReadOnly('facilities') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="facilities" component="div" className="text-danger" />
               </div>
            </div>
            <div className="col-lg-3 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label labels">Achievements<span className="text-danger">*</span></label>
                  <Field
                     name="achievements"
                     className="form-control"
                     placeholder="Achievements"
                     disabled={isReadOnly('achievements')}
                  />
                  {(!isReadOnly('achievements') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="achievements" component="div" className="text-danger" />
               </div>
            </div>
            <div className="col-lg-3 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label labels">Affiliated Organizations<span className="text-danger">*</span></label>
                  <Field
                     name="affiliated_sport_body"
                     className="form-control"
                     placeholder="Affiliated Organizations"
                     disabled={isReadOnly('affiliated_sport_body')}
                  />
                  {(!isReadOnly('affiliated_sport_body') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="affiliated_sport_body" component="div" className="text-danger" />
               </div>
            </div>



            <div className="col-lg-3 mb-3">
               <div className="form-group mb-3">
                  <label className="text-label labels">Additional Information<span className="text-danger">*</span></label>
                  <Field
                     name="additional_information"
                     className="form-control"
                     placeholder="Additional Information"
                     disabled={isReadOnly('additional_information')}
                  />
                 
                  {(!isReadOnly('additional_information') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="additional_information" component="div" className="text-danger" />
               </div>
            </div>






















         </div>
      </>
   )
}

export default Others
