import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.scss";
import img1 from "../../asserts/photoGallery/img1.png";
import img2 from "../../asserts/photoGallery/img2.png";
import img3 from "../../asserts/photoGallery/img3.png";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

function Index() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(3)

  let appendNumber = 4;
  let prependNumber = 1;

  const prepend2 = () => {
    swiperRef.prependSlide([
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
    ]);
  };

  const prepend = () => {
    swiperRef.prependSlide(
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>"
    );
  };

  const append = () => {
    swiperRef.appendSlide(
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>"
    );
  };

  const append2 = () => {
    swiperRef.appendSlide([
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
    ]);
  };

  useEffect(() => {
    const handleResize = () => {
        // Update slidesPerView based on screen width
        const newSlidesPerView = window.innerWidth <= 1000 ? 1 : 3;
        setSlidesPerView(newSlidesPerView);

        // Update Swiper instance
        if (swiperRef && swiperRef.current) {
            swiperRef.current.params.slidesPerView = newSlidesPerView;
            swiperRef.current.update();
        }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
}, [swiperRef]);


  return (
    <>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={-500}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="myPhotoSwiper"
      >
        <SwiperSlide>
          <div className="relative">
            <img src={img1} className="photo-slider" />
            <div className="photo-overlay">
              <h3>Academy Management System</h3>
              <button className="discover-btn">Discover</button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={img2} className="photo-slider" />
            <div className="photo-overlay">
              <h3>Academy Management System</h3>
              <button className="discover-btn">Discover</button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={img3} className="photo-slider" />
            <div className="photo-overlay">
              <h3>Academy Management System</h3>
              <button className="discover-btn">Discover</button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={img1} className="photo-slider" />
            <div className="photo-overlay">
              <h3>Academy Management System</h3>
              <button className="discover-btn">Discover</button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={img2} className="photo-slider" />
            <div className="photo-overlay">
              <h3>Academy Management System</h3>
              <button className="discover-btn">Discover</button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Index;
