import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

const MyTimer = ({ startTimer, resetTimer, onRunningChange }) => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(null);

  useEffect(() => {
    if (startTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 45);
      setExpiryTimestamp(time);
    }
  }, [startTimer]);

  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      console.warn("onExpire called");
      onRunningChange(false);
    },
  });

  useEffect(() => {
    if (expiryTimestamp) {
      restart(expiryTimestamp, true);
    }
  }, [expiryTimestamp, restart]);

  useEffect(() => {
    if (resetTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 45);
      restart(time, true);
    }
  }, [resetTimer, restart]);

  useEffect(() => {
    onRunningChange(isRunning);
  }, [isRunning, onRunningChange]);

  return (
    <>
      {/* {seconds > 0 ? (
        <span>0 : {seconds}</span>
      ) : (
        <span className="danger">Expired</span>
      )} */}

      <span className={`${seconds > 0 ? "" : "text-danger"}`}>
        {seconds > 0 ? `0: ${seconds}` : "Expired"}
      </span>
    </>
  );
};

export default MyTimer;
