import BirsaFootball from "../asserts/Infrastructure/Birsa Munda Football Stadium.jpg"
import BirsaHockey from "../asserts/Infrastructure/Birsa Munda Hockey Stadium.webp"
import Tickait from "../asserts/Infrastructure/Tikait Umrao Shooting Range.jpg"
import VeerAquatic from "../asserts/Infrastructure/Veer Budhu Aquatic Stadium.jpg"


export const coordinates = [
  { 
    
      id: 0, 
      lng: 85.38673182822048, 
      lat: 23.381429412356646, 
      location: 'Kanke, Rānchī District, Jharkhand', 
      title: "Thakur Vishwanath Shahdeo Indoor Stadium", 
      details: 'The Thakur Vishwanath Shahdeo Indoor Stadium is a stadium located in Kanke, Ranchi, Jharkhand, India. It is primarily used for badminton matches and has hosted several national and international tournaments.The stadium is part of the Mega Sports Complex, which also includes the JSCA Cricket Stadium and the Astroturf Stadium Morhabadi. The complex is a major sporting hub in Ranchi and hosts a variety of sporting events throughout the year. The Thakur Vishwanath Shahdeo Indoor Stadium has a seating capacity of over 3,000 spectators and is equipped with modern facilities, including a wooden badminton court, air conditioning, and floodlights. It also has a gymnasium and a training centre for badminton players.', 
      managerDet:"+91 9431594839",
      address: "The Thakur Vishwanath Shahdeo Indoor Stadium is a stadium located in Kanke, Ranchi, Jharkhand, India. It is primarily used for badminton matches and has hosted several national and international tournaments", 
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuRM3Tln_-c6oSYxELuLU6MT3q91i1HInCWfam7RgAyyhTRqCMKah5OI85KXGBCAyeuS0&usqp=CAU",
      sportFacilities: 'Indoor Badminton',
      link: "https://www.google.com/maps/dir//H6MM%2B5P9+Jawaharlal+Nehru+Stadium,+New+Delhi,+Jawaharlal+Nehru+Stadium,+Pragati+Vihar,+New+Delhi,+Delhi+110003/@28.5829248,77.2317772,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390ce2f93178ea85:0x639d387dcb58c69!2m2!1d77.2343521!2d28.5829248?entry=ttu"
  },
//   { 
//       id: 1, 
//       lng: 85.39473629570651, 
//       lat: 23.379661781982755,
//       location: 'Tikait Umrao Shooting Range, Hotwar',
//       title: "Tikait Umrao Shooting Range", 
//       details: 'The Tikait Umrao Shooting Range is a shooting range located in Hotwar, Ranchi, India. It has a seating capacity of 2,000 and a 10-metre, 60-point indoor shooting range. The Tikait Umrao Shooting Range has hosted several notable events, including the East Zone Shooting Championship in 2014 The Tikait Umrao Shooting Range offers a variety of facilities, including a dressing room, VIP enclosure, and air-conditioning. However, some sources indicate that the air-conditioning may not be functioning properly.', 
//       managerDet:"",
//       address: "The Tikait Umrao Shooting Range is a shooting range located in Hotwar, Ranchi, India. It has a seating capacity of 2,000 and a 10-metre, 60-point indoor shooting range.", 
//       image: Tickait,
//       sportFacilities: 'Shooting',
//       link: "https://www.google.com/maps/dir//28.5557159,77.2169244/@28.5424465,77.240957,13z?entry=ttu",
//   },
//   { 
//       id: 2, 
//       lng: 85.3875, 
//       lat: 23.378, 
//       location: 'Hotwar, Khelgaon, Ranchi, Jharkhand 834009', 
//       title: "Birsa Munda Athletics Stadium", 
//       details: 'The Birsa Munda Athletics Stadium is a prominent sports venue located in Ranchi, Jharkhand, named after the legendary tribal freedom fighter and folk hero, Birsa Munda. The Birsa Munda Athletics Stadium serves as a venue for various athletics events and competitions throughout the year. This may include inter-school athletics meets, district-level championships, state-level competitions, national-level tournaments, and athletic trials for selection to regional and national teams.', 
//       managerDet:"",
//       address: "The Birsa Munda Athletics Stadium is a prominent sports venue located in Ranchi, Jharkhand, named after the legendary tribal freedom fighter and folk hero, Birsa Munda.", 
//       image: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Birsa_Munda_Athletics_Stadium.jpg",
//       sportFacilities: '9-lane synthetic track, a 10-lane 100m track, an 8-lane warm-up track, a seating capacity, high mast lights, and a media centre',
//       link: "https://www.google.com/maps/dir/28.521229,77.2282896/Birsa+Munda+Athletics+Stadium/@26.0209764,56.8505198,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x39f4e3a4fb0fae11:0x2db4717ceeb4c092!2m2!1d85.3879765!2d23.3777393?entry=ttu"
//   },
//   { 
//       id: 3, 
//       lng: 85.3885430915244, 
//       lat: 23.38263875617962, 
//       location: 'Khatanga, Ranchi, Jharkhand 834009', 
//       title: "Veer Budhu Aquatic Stadium", 
//       details: 'The Veer Budhu Aquatic Stadium is a state-of-the-art swimming facility located in Hotwar, Ranchi, India. It was built in 2014 and has a seating capacity of 3,194. The stadium is fully air-conditioned and has a 10,000 square metre area. The stadium has a number of facilities, including a fully equipped gymnasium, a sauna, a steam room, and a massage parlour. It also has a cafeteria and a souvenir shop.The stadium has hosted a number of national and international swimming events, including the 2016 South Asian Games. It is also a popular venue for training camps and competitions.', 
//       address: "The Veer Budhu Aquatic Stadium is a state-of-the-art swimming facility located in Hotwar, Ranchi, India. It was built in 2014 and has a seating capacity of 3,194.", 
//       image: "https://www.google.com/maps/dir//Veer+Budhu+Aquatic+Stadium/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e331958d7e2b:0x9c476c668db006f1?sa=X&ved=1t:3061&ictx=111"
//   },
  { 
      id: 1, 
      lng: 85.32950261105033, 
      lat: 23.393101040823634, 
      location: 'Ranchi University, Morabadi, Ranchi, Jharkhand 834008', 
      title: "Birsa Munda Football Stadium", 
      details: 'The Birsa Munda Football Stadium is a stadium in Ranchi, India. It is primarily used for football matches and athletics. The stadium was opened in 2009 and has a capacity of 40,000. The stadium serves as a venue for various football events and competitions throughout the year. This may include local league matches, inter-school tournaments, state-level championships, and exhibition matches featuring professional teams. It has also hosted prestigious national-level tournaments and friendly matches.In addition to hosting matches, the Birsa Munda Football Stadium provides training facilities for football players to practise and improve their skills. It may offer coaching clinics, training camps, and practice sessions conducted by experienced coaches and trainers.Overall, the Birsa Munda Football Stadium stands as a symbol of football culture and sporting excellence in Ranchi, providing a platform for players to showcase their talent, compete at various levels, and contribute to the development of football in Jharkhand.', 
      managerDet:"+91 7903573979",
      address: "The Birsa Munda Football Stadium is a stadium in Ranchi, India. It is primarily used for football matches and athletics. The stadium was opened in 2009 and has a capacity of 40,000.", 
      image: BirsaFootball,
      sportFacilities: 'Football',
      link : "https://www.google.com/maps?sca_esv=a8b1485e4aab9472&rlz=1C1OPNX_enIN1104IN1104&uact=5&gs_lp=Egxnd3Mtd2l6LXNlcnAiHEJpcnNhIE11bmRhIEZvb3RiYWxsIFN0YWRpdW0yERAuGIAEGJECGMcBGIoFGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBhAAGBYYHjILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMgsQABiABBiGAxiKBTIgEC4YgAQYkQIYxwEYigUYrwEYlwUY3AQY3gQY4ATYAQJI4gZQAFiRAnABeAGQAQCYAa0BoAGtAaoBAzAuMbgBA8gBAPgBAfgBApgCAqACuwGoAgrCAhYQABgDGLQCGOUCGOoCGIwDGI8B2AEBwgIWEC4YAxi0AhjlAhjqAhiMAxiPAdgBAZgDBboGBAgBGAq6BgYIAhABGBSSBwMxLjGgB5wN&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KZMufdU74fQ5MeDiFSt6MnaY&daddr=Ranchi+University,+Morabadi,+Ranchi,+Jharkhand+834008",
  },
  { 
      id: 2, 
      lng: 85.3863495, 
      lat: 23.3814549, 
      location: 'Khelgaon-Tatisilwai Rd, Khatanga, Ranchi, Jharkhand 834009', 
      title: " Harivansh Tana Bhagat Indoor Stadium", 
      details: 'The Harivansh Tana Bhagat Indoor Stadium serves as a venue for a wide range of indoor sports events and competitions throughout the year. This may include district-level championships, state-level tournaments, national-level competitions, and exhibition matches in various indoor sports disciplines.In addition to hosting events, the stadium provides training facilities for athletes and sports enthusiasts to practise and improve their skills in indoor sports. It may offer coaching clinics, training camps, and practice sessions conducted by experienced coaches and trainers.Overall, the Harivansh Tana Bhagat Indoor Stadium serves as an important hub for indoor sports and recreational activities in Ranchi, providing athletes and sports enthusiasts with modern facilities to pursue their passion for sports and contribute to the development of the sporting culture in Jharkhand.', 
      managerDet:"+91 9570165737",
      address: "The Harivansh Tana Bhagat Indoor Stadium serves as a venue for a wide range of indoor sports events and competitions throughout the year.", 
      image: "https://www.sportsjharkhand.com/wp-content/uploads/2020/12/thumb_88515309.jpg",
      sportFacilities: '',
      link: "https://www.google.com/maps/dir/28.521229,77.2282896/Harivansh+Tana+Bhagat+Indoor+Stadium/@25.8847962,76.0985676,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x39f4e35d7392d0a5:0xa2a547fca1b2e94f!2m2!1d85.3858736!2d23.3816169?entry=ttu"
  },
//   { 
//       id: 6, 
//       lng: 85.27480230941833, 
//       lat: 23.310447106364183, 
//       location: 'HEC Township, Birsa Nagar, Ranchi, Jharkhand 834004', 
//       title: "Jharkhand Cricket Association International Stadium", 
//       details: 'The JSCA International Stadium is an international cricket stadium which comes under Jharkhand State Cricket Association, established in the year 2011, located in Ranchi, Jharkhand with seating capacity of 50,000. It is the home ground of the Jharkhand cricket team. The Chennai Super Kings played home games at this venue. The JSCA International Stadium may offer training facilities for cricket players, including practice pitches, nets, and coaching clinics. These facilities provide aspiring cricketers with opportunities to hone their skills and receive professional coaching under the guidance of experienced coaches and trainers.Overall, the JSCA International Stadium Complex is a prestigious venue that has significantly contributed to the promotion of cricket in Jharkhand and the broader Indian cricketing landscape. Its modern infrastructure, world-class facilities, and strategic location have made it a preferred destination for hosting cricket matches and other major events.', 
//       address: "The JSCA International Stadium is an international cricket stadium which comes under Jharkhand State Cricket Association, established in the year 2011, located in Ranchi, Jharkhand with seating capacity of 50,000.", 
//       image: "https://as2.ftcdn.net/v2/jpg/05/24/45/97/1000_F_524459794_CsNPRperKIRXRBOkPM1PEGOqXrs7MXqJ.jpg",
//       sportFacilities: ' Cricket',
//       link: "https://www.google.com/maps?sca_esv=a8b1485e4aab9472&rlz=1C1OPNX_enIN1104IN1104&uact=5&gs_lp=Egxnd3Mtd2l6LXNlcnAiM0poYXJraGFuZCBDcmlja2V0IEFzc29jaWF0aW9uIEludGVybmF0aW9uYWwgU3RhZGl1bTIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIYDGIoFMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEjNAVAAWABwAHgBkAEAmAGcAaABnAGqAQMwLjG4AQPIAQD4AQL4AQGYAgGgAqEBmAMAkgcDMC4xoAenCA&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KVvR4TT-H_U5MX0b7z6rN532&daddr=876F%2B3WP,+HEC+Township,+Birsa+Nagar,+Ranchi,+Jharkhand+834004"
//   },
//   { 
//       id: 7, 
//       lng: 85.39280415337821, 
//       lat: 23.378683691500477, 
//       location: 'Gumla, Jharkhand, 835207', 
//       title: "Sidhu Kanhu Velodrome Stadium", 
//       details: 'The Sidhu Kanhu Velodrome Stadium, located in Ranchi, Jharkhand, is a significant sporting venue primarily dedicated to cycling events.The stadium serves as a venue for various cycling events and competitions at the district, state, national, and international levels. These may include track cycling championships, velodrome races, time trials, and other cycling events organised by cycling federations and sports organizations.In addition to hosting events, the stadium provides training opportunities for cyclists to improve their skills and fitness levels. It may offer coaching programs, training camps, and practice sessions conducted by experienced coaches and trainers.Overall, the Sidhu Kanhu Velodrome Stadium serves as a key facility for promoting cycling sports and fostering athletic development in Ranchi and Jharkhand. It provides a platform for cyclists to train, compete, and pursue their passion for cycling at various levels of proficiency.', 
//       address: "The Sidhu Kanhu Velodrome Stadium, located in Ranchi, Jharkhand, is a significant sporting venue primarily dedicated to cycling events", 
//       image: "https://lh3.googleusercontent.com/p/AF1QipM-84WI5guvYdm4lplldT6PqGSQCLS1DaFQRPgg=s1360-w1360-h1020",
//       sportFacilities: 'Cycling',
//       link: "https://www.google.com/maps/dir//Sidhu+Kanhu+Velodrome+Stadium/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e3c24146358d:0x82768603fcced8d3?sa=X&ved=1t:3061&ictx=111"
//   },
//   { 
//       id: 8, 
//       lng: 85.3895288247639, 
//       lat: 23.381513439676734, 
//       location: 'Gumla, Jharkhand, 835207', 
//       title: "Ganpat Rai Indoor Stadium, Hotwar Stadium Details", 
//       details: 'The Ganpat Rai Indoor Stadium is a sports facility located in Hotwar, Ranchi, Jharkhand, India. It is named after Ganpat Rai, a renowned Indian wrestler. The stadium has a seating capacity of 2,000 and is equipped with facilities for indoor sports such as badminton, basketball, volleyball, and table tennis.', 
//       address: "The Ganpat Rai Indoor Stadium is a sports facility located in Hotwar, Ranchi, Jharkhand, India. It is named after Ganpat Rai, a renowned Indian wrestler. ", 
//       image: "https://www.sajha.in/wp-content/uploads/2013/05/indoor_img2.jpg",
//       sportFacilities: 'Gymnastic, Wrestling, Cycling',
//       link: "https://www.google.com/maps/dir/28.521229,77.2282896/Ganpat+Rai+Indoor+Stadium,+Hotwar+Stadium+Details/@25.8848418,76.0985676,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x39f4e3a39421e3b7:0xb630935caac6efd2!2m2!1d85.3895181!2d23.3813362?entry=ttu"
//   },
  { 
    
      id: 3, 
      lng: 85.3287329957068, 
      lat: 23.389264312816, 
      location: 'Birsa Munda Stadium Road, Ranchi University, Morabadi, Ranchi, Jharkhand 834008', 
      title: "Astro Turf Hockey Stadium", 
      details: 'Astro Turf Hockey Stadium is to serve as a venue for field hockey matches, tournaments, and training sessions. It provides a conducive environment for players to practise and compete in the sport at various levels. The AstroTurf Hockey Stadium serves as a venue for various field hockey events and competitions at different levels. These may include district-level championships, state-level tournaments, national-level competitions, and international matches organised by hockey federations and sports organizations.', 
      managerDet:"+91 9798304430",
      address: "Astro Turf Hockey Stadium is to serve as a venue for field hockey matches, tournaments, and training sessions.", 
      image: "https://pbs.twimg.com/media/BAATXE_CUAApOnW.jpg:large",
      sportFacilities: 'Hockey',
      link: "https://www.google.com/maps?rlz=1C1OPNX_enIN1104IN1104&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPNIBBzU0MGowajeoAgCwAgA&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KRllSqc-4fQ5MUtc7CNDiZ0S&daddr=98QH%2BJF9,+Birsa+Munda+Stadium+Road,+Ranchi+University,+Morabadi,+Ranchi,+Jharkhand+834008"
  },
//   { 

//       id: 10, 
//       lng: 85.37854022883586, 
//       lat: 23.391918941517346, 
//       location: 'NH 23, Hazaribag Road, Booty More Rd, Hanuman Nagar, Ranchi, Jharkhand 834009', 
//       title: "Ranchi Gymkhana Club", 
//       details: 'The Ranchi Gymkhana Club has epitomized social  and recreational excellence in the heart training sessions.of Ranchi, Jharkhand. Boasting a plethora of amenities, from tennis courts to a swimming pool and gymnasium, the club caters to sports enthusiasts of all stripes. Beyond athletics, members enjoy diverse recreational offerings including a library and card room, alongside multiple dining venues and bars. With banquet halls for social events, the club serves as a vibrant nexus for community engagement, facilitating not only leisure pursuits but also fostering lasting social connections among its members and their families', 
//       address: "The Ranchi Gymkhana Club has epitomized social  and recreational excellence in the heart of Ranchi, Jharkhand. Boasting a plethora of amenities, from tennis courts to a swimming pool and Gym.", 
//       image: "https://pbs.twimg.com/media/BAATXE_CUAApOnW.jpg:large",
//       sportFacilities: 'Swimming, Gymnasium',
//       link: "https://www.google.com/maps?rlz=1C1OPNX_enIN1104IN1104&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyBwgDEAAYgAQyBwgEEAAYgAQyBwgFEAAYgAQyBwgGEAAYgAQyBwgHEAAYgAQyBwgIEAAYgAQyBwgJEAAYgASoAgiwAgE&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KU8PXoKT4_Q5Mc4mHejOIxhm&daddr=NH+23,+Hazaribag+Road,+Booty+More+Rd,+Hanuman+Nagar,+Ranchi,+Jharkhand+834009"
//   },
];
