import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import check from "../../../asserts/Registration/checkicon.png";
import moment from "moment";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";

const RegistrationFinalSubmitCard = ({ organisationList }) => {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));
  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    uploadedDocuments,
    setUploadedDocuments,
    handleBack,
    handleNext,
  } = useContext(VerticalStepperContext);
  const { loading, setLoading } = useContext(VerticalStepperContext);
  const formatDateTime = (dateString) => {
    return moment(dateString).format("DD/MM/YY [at] HH:mm");
  };

  useEffect(() => {
    if (!token) {
      setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
      navigate("/registration", { replace: true });
      localStorage.removeItem("currentStep");
      localStorage.removeItem("bankDetails");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("access_token");
    } else if (loginDetails) {
      if (loginDetails?.type !== "individual") {
        setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
        navigate("/registration", { replace: true });
        localStorage.removeItem("currentStep");
        localStorage.removeItem("bankDetails");
        localStorage.removeItem("login_Details");
        localStorage.removeItem("access_token");
      }
    }
  }, []);
  return (
    <>
      <div>
        <div className="" role="alert" aria-live="assertive">
          Welcome to the <b>Unified Sports Portal!</b>
          <br />
          You have successfully submitted your Registration details On{" "}
          <span style={{ color: "red" }}>
            {organisationList?.updated_at
              ? formatDateTime(organisationList.updated_at)
              : "N/A"}
          </span>
          .<br />
          Your Provisional Registration Number{" "}
          <span style={{ color: "green" }}>
            {organisationList?.provisinal_registration_id}
          </span>
          .
        </div>

        <div className="mt-2">
          <div className="divgap">
            <u className="divgap">
              <b>
                <h5>Current status</h5>
              </b>
            </u>
            <img
              className="divgap"
              alt="check icon"
              src={check}
              style={{ width: "60px", height: "60px" }}
            />
            <h5>
              <div className="divgap">
                Form Submitted to the{" "}
                <span style={{ color: "red" }}>Admin!</span>
              </div>
              <div>
                Last Sync at{" "}
                <span style={{ color: "green" }}>
                  {organisationList?.updated_at
                    ? formatDateTime(organisationList.updated_at)
                    : "N/A"}
                </span>
              </div>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationFinalSubmitCard;
