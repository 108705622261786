import React, { createContext, useState } from 'react';

// Create a context
export const RegisterAsContext = createContext();

// Create a provider component
export const RegisterAsProvider = ({ children }) => {
  const [registerAs, setRegisterAs] = useState({
    userType: "",
  }); 

  return (
    <RegisterAsContext.Provider value={{ registerAs, setRegisterAs }}>
      {children}
    </RegisterAsContext.Provider>
  );
};
