import React, { createContext, useState } from 'react';
import { documentUpdate } from '../../services/insitituteApiService';
import swal from "sweetalert";



export const DocumentUploadContext = createContext();

export const FormProvider = ({ children }) => {
    
    const [formValues, setFormValues] = useState({});
    const [status, setStatus] = useState('ongoing');
    // const [btnSubmit, setBtnSubmit] = useState(false);
    const userDataString = localStorage.getItem("login_Details");
	const userData = JSON.parse(userDataString);
  
	const handleFormSubmit = async (values, status) => {
        console.log("fileValue",values);
		const formData = new FormData();
		for (const key in values) {
			formData.append(key, values[key]);
		}
		formData.append("tax_identification_key", values?.identification_select);
		formData.append("affiliation_key", values?.affiliation_select);
		formData.append("id", userData?.user?.id);
		formData.append("status", status);

	
		try {
			const response = await documentUpdate(formData);
			// setBtnSubmit(false);
			if (response?.data?.status === true) {
				swal("Success", response?.data?.message, "success");

				if (status === "final_submit") {
					// navigate("/final-status", { state: { organisationList } });
				}
			} else {
				swal("Error", response.data.message, "error");
			}
		} catch (error) {
			swal("Error", "An unexpected error occurred. Please try again.", "error");
		}
	};

    return (
        <DocumentUploadContext.Provider value={{ formValues, setFormValues, handleFormSubmit, status }}>
            {children}
        </DocumentUploadContext.Provider>
    );
};
