import React, { useContext, useEffect } from "react";
import "./regstyle.scss";

import { FormCorrectionContext } from "../../../Context/FormCorrection/FormCorrectionContext";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../utils/formatDateTime";

const RegisterReSubmitCard = () => {
  const { correctionListData, fetchCorrectionForm } = useContext(
    FormCorrectionContext
  );

  const userDataString = localStorage.getItem("login_Details");
  const userData = JSON.parse(userDataString);
  const navigate = useNavigate();

  const handleClick = () => {
    // Update localStorage
    localStorage.setItem("IndividualcurrentStep", "0");

    // Navigate to the individual profile update page
    navigate("/institution-profile-update");
  };

  useEffect(() => {
    fetchCorrectionForm(userData.user.id);
  }, [userData?.user?.id, fetchCorrectionForm]);
  return (
    <>
      <div className="mt-3">
        <div className="">
          <div role="alert" aria-live="assertive"className="text-center">
           <p>Welcome to the <stong>Unified Sports Portal!</stong></p> 
            
            <p>Your Application is <span style={{color:"green"}}>verified</span> by the Admin and you are informed to
            <span style={{color:"red"}}> re-submit</span> some information's as we</p>
            
            <p>found that some information's are <span style={{color:"red"}}>not correct</span> or <span style={{color:"red"}}>not meeting </span>
            Registrations Criteria on the USP.</p>
           
            <br />
            {/* {formatDateTime(correctionListData?.data?.updated_at).toLocaleString()} */}
            {formatDateTime(correctionListData?.data?.updated_at).toLocaleString()}
          </div>
          <div className="mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {correctionListData?.data?.registration_correction?.map(
                  (feildsData) => {
                    return (
                      <>
                        <tr>
                          <td className="text-primary ">
                            <strong>
                              {feildsData?.label.replace(/_/g, " ")}
                            </strong>
                          </td>
                          <td>{feildsData?.remark}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </Table>
            <div className="m-auto w-100 text-center">
              <Link to="/institution-profile-update" onClick={handleClick}>
                <Button className="btn btn-success">
                  Re-Submit Registration
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterReSubmitCard;
