export const scholarCardData = [
    {
      id:1,
      head:"Jharkhand State Sports Scholarship",
      para:"The Jharkhand State Sports Scholarship is awarded to outstanding student-athletes who have excelled in state, national, or international sporting events. This scholarship aims to encourage and support students in their pursuit of sporting excellence while continuing their academic journey."
    },
    {
      id:2,
      head:"Jharkhand Sports Talent Search Scholarship",
      para:"The Jharkhand Sports Talent Search Scholarship is designed to identify and support talented athletes from underprivileged backgrounds. This scholarship aims to provide them with the necessary resources to develop their sporting skills and pursue their dreams."
    },
    {
      id:3,
      head:"Jharkhand Indigenous Sports Scholarship",
      para:"The Jharkhand Indigenous Sports Scholarship is dedicated to promoting and preserving the traditional sports and games of the state. This scholarship supports athletes who excel in indigenous sports, contributing to the preservation of Jharkhand's rich cultural heritage."
    },

  ]


  export const scholarListData = [
    {
      id:1,
      head:"1. Eligibility Criteria",
      title:"Eligibility Criteria",
      paradata:[
       {
        id:1,
        para:"Resident of Jharkhand",
       },
       {
        id:2,
        para:"Enrolled in a recognized educational institution within the state",
       },
       {
        id:3,
        para:"Achieved outstanding performance in state, national, or international sporting events",
       },
      ]
    },
    {
      id:2,
      head:"2. Purpose of the Scholarship",
       title:" Purpose of the Scholarship",
       paradata:[
        {
         id:1,
         para:"Financial support for talented athletes",
        },
        {
         id:2,
         para:"Assist training, competition, and development",
        },
        {
         id:3,
         para:"Cover coaching, equipment, nutrition, travel expenses",
        },
       ]
       
      
    },
    {
      id:3,
      head:"3. Selection Process",
       title:"Selection Process",
       paradata:[
        {
         id:1,
         para:"Based on achievements, performance, potential, financial need",
        },
        {
         id:2,
         para:"Selection committee from sports bodies",
        },
        {
         id:3,
         para:"Predefined evaluation criteria",
        },
       ]
    },
    {
      id:4,
      head:"4. Duration and Renewal",
       title:"Duration and Renewal",
       paradata:[
        {
         id:1,
         para:"Specific period, often one year",
        },
        {
         id:2,
         para:"Renewal based on performance and adherence",
        },
        {
         id:3,
         para:"Reapplication or periodic reviews",
        },
       ]
    },
    {
      id:5,
      head:"5. Benefits and Support",
       title:"Benefits and Support",
       paradata:[
        {
         id:1,
         para:"Financial assistance for sports expenses",
        },
        {
         id:2,
         para:"Access to training facilities, coaching, sports science",
        },
        {
         id:3,
         para:"Resources for performance enhancement",
        },
       ]
    },
    {
      id:6,
      head:"6 .Impact and Recognition",
       title:"Impact and Recognition",
       paradata:[
        {
         id:1,
         para:"Recognizes and supports talented athletes",
        },
        {
         id:2,
         para:"Provides resources for sports excellence",
        },
        {
         id:3,
         para:"Promotes sports ecosystem in the state",
        },
        {
          id:4,
          para:"Nurtures next generation of sports stars",
         },
       ]
      

    },

  ]