function LatestBanner() {
  return (
    <div className="latest-news-container">
      <div className="container latest_news_container">
        <div className="heading-text text-center">
          <h3 className="lates_news_heading mt-5">LATEST NEWS</h3>
        </div>
      </div>
    </div>
  );
}

export default LatestBanner;
