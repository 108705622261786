import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { VerticalStepperContext } from '../../Context/VerticalStepperCount/VerticalStepper';
import Swal from 'sweetalert2';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  button: {
    color: 'white',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  MuiStepLabel: {
    color: 'white',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps({values}) {
  return values;
}


function getStepContent(step) {
  switch (step) {
    case 0:
      return ``;
    case 1:
      return '';
    case 2:
      return ``;
    case 3:
      return ``;
    case 4:
      return ``;
    case 5:
      return ``;
    case 6:
      return ``;
    case 7:
      return ``;
    default:
      return 'Unknown step';
  }
}

export default function VerticalLinearStepper({values}) {

  const classes = useStyles();
  const { currentStep, setCurrentStep, handleBack, handleNext } = useContext(VerticalStepperContext);
  const steps = getSteps({values});

  ;

  useEffect(() => {
    // Update the active step when the context changes
    setCurrentStep(currentStep);
  }, [currentStep, setCurrentStep]);

  useEffect(() => {
    // Show SweetAlert when all steps are completed
    if (currentStep === steps.length) {
      Swal.fire({
        title: 'Success!',
        text: 'You have completed all steps.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }
  }, [currentStep, steps.length]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={currentStep.level} orientation="vertical" style={{ backgroundColor: 'transparent' }}>
        {steps.map((label, index) => (
        
          <Step key={label}>
         
            <StepLabel className={classes.MuiStepLabel} >
              <p style={{  margin: '0px 0px 0px 0px' }}> {label}</p>
            </StepLabel>
            <StepContent>
              <Typography>{ getStepContent(index)}</Typography>
            </StepContent>
          </Step> 
        ))}
      </Stepper>
      <div className={classes.actionsContainer}>
        <div className={classes.buttonsContainer}></div>
      </div>
      {currentStep.level === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
        </Paper>
      )}
    </div>
  );
}
