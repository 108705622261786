import { UnAuthApiService } from "../../config/apiServices";
import { GET_ALL_E_TENDER_NOTIFICATION } from "../../config/commonRegistartionApi/commonRegistrationApi";

// export const getAllEtenderWebsiteNotification = (payload = {}) => {
//   return UnAuthApiService.get(GET_ALL_E_TENDER_NOTIFICATION, payload);
// };


export const getAllEtenderWebsiteNotification = (notifyName) => {

  return UnAuthApiService.get(`${GET_ALL_E_TENDER_NOTIFICATION}${notifyName}`)
};
