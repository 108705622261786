import React, { useContext } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { InsituteRegistrationContext } from '../../Context/InsituteRegistrationAllData/InsituteRegistrationAllListData';
import Modal from 'react-bootstrap/Modal';
import { mapProfileViewData } from '../../utils/mapper/ProfileViewMapper';
import pdf from "../../asserts/pdf.png";
import docicon from "../../asserts/google-docs.png";

const IndividualProfile = ({ show, setShow, handleClose, handleShow }) => {
    const { organisationList, ListOganisationById } = useContext(InsituteRegistrationContext);
    // console.log("organisationList====>",organisationList)
    let serialNumber = 1;

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="res_card_heading">View Profile Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center ">
                               
                                <div>
                                    <h6>All Excel Uploaded Registration Information's are available here... </h6>
                                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th className="text-primary">S.no</th>
                                                    <th className="text-primary" style={{ marginLeft: '20px' }}>Form Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {organisationList && Object.entries(organisationList).map(([key, value], index) => {
                                                    // Skip rendering for "country_code" and "country" keys
                                                    if (key === "country_code" || key === "country" || key === "city" || key === "user_category_main" || key === "category_type" || key === "sub_category_type" || key === "created_at" || key === "updated_at" || key === "state" || key === "org_pwd" || key === "status" || key === "id" || key === "provisinal_registration_id" || key === "attempt_count" || key === "accreditation_status") return null;

                                                    return (
                                                        <tr key={key}>

                                                            <td>{serialNumber++}</td>

                                                            {/* Key and Value */}
                                                            <td>
                                                                <div>
                                                                    {Array.isArray(value) && key === "registration_docs" ? (
                                                                        value.map((doc) => {
                                                                            const fileExtension = doc.doc_file.split('.').pop().toLowerCase();
                                                                            console.log("fileExtension===>",fileExtension)

                                                                            let fileIcon;
                                                                            if (fileExtension === 'pdf') {
                                                                                fileIcon = pdf; // Use the actual path to your PDF icon
                                                                            } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                                                                                fileIcon = docicon; // Use the actual path to your DOC icon
                                                                            } else {
                                                                                fileIcon = doc.doc_file; // If it's an image, use the file itself
                                                                            }

                                                                            return (
                                                                                <div key={doc.id} className="mb-3 mx-3">
                                                                                    <strong>{doc.doc_name.replace(/_/g, ' ')}</strong>
                                                                                    <img
                                                                                        src={fileIcon}
                                                                                        alt={doc.doc_name}
                                                                                        style={{ width: '50px', height: 'auto', display: 'block', marginTop: '5px' }}
                                                                                    />
                                                                                    <a href={doc.doc_file} target="_blank" rel="noopener noreferrer" className="mt-2 d-block">
                                                                                        View File
                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <>
                                                                            <div style={{ fontWeight: 'bold' }} className="mx-3">
                                                                                {key.replace(/_/g, ' ')}
                                                                            </div>
                                                                            <div className="mx-3">
                                                                                {key === 'attempt_count' ? value + 1 : key === 'year_of_operation' ? formatDate(value) : mapProfileViewData(key, value)}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default IndividualProfile