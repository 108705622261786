import "./styles.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import search from "../../asserts/Athlete/search.png";
import ankitBhati from "../../asserts/Athlete/ankitbhati.png";
import hockey from "../../asserts/Athlete/hockey.png";
import Slider from "../../components/SwipperSlider/index";
import dropdown from "../../asserts/Athlete/arrow.png";
import girl from "../../asserts/Athlete/girl.png";
import football from "../../asserts/Athlete/football.png";
import rahul from "../../asserts/Athlete/rahul.png";
import boy2 from "../../asserts/Athlete/boy2.png";
import ComingSoon from "../../components/ComingSoonPage/ComingSoon";

 

// function Index() {
//   const data = [
//     {
//       id: 1,
//       athleteName: "Salima Tete",
//       Sport: "Hockey",
//       Gender: "Female",
//       age: 22,
//       State: "Jharkhand",
//       image: girl,
//       sportImage: hockey,
//       link: "/athletes_profile",
//     },
//     {
//       id: 1,
//       athleteName: "Rahul Sharma",
//       Sport: "Hockey",
//       Gender: "Male",
//       age: 24,
//       State: "Jharkhand",
//       image: ankitBhati,
//       sportImage: football,
//       link: "/athletes_profile",
//     },
//     {
//       id: 1,
//       athleteName: "Mayank Joshi",
//       Sport: "Hockey",
//       Gender: "Male",
//       age: 23,
//       State: "Jharkhand",
//       image: boy2,
//       sportImage: hockey,
//       link: "/athletes_profile",
//     },
//     {
//       id: 1,
//       athleteName: "Sushant Singh",
//       Sport: "Hockey",
//       Gender: "Male",
//       age: 26,
//       State: "Jharkhand",
//       image: rahul,
//       sportImage: football,
//       link: "/athletes_profile",
//     },
//     {
//       id: 1,
//       athleteName: "Shradha Vastrakar",
//       Sport: "Hockey",
//       Gender: "Female",
//       age: 24,
//       State: "Jharkhand",
//       image: girl,
//       sportImage: football,
//       link: "/athletes_profile",
//     },
//   ];

//   const [selectSport, setSelectSport] = useState("");
//   const [selected, setSelected] = useState("All");
//   const [allAthletes, setAllAthletes] = useState(data);

//   const handleClick = (gender) => {
//     setSelected(gender);
//   };

//   useEffect(() => {
//     const filteredData =
//       selected === "All"
//         ? data
//         : data.filter((item) => item.Gender === selected);
//     setAllAthletes(filteredData);
//   }, [selected]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handleSportsChange = (e) => {
//     setSelectSport(e.target.value);
//   };
//   return (
//     <div style={{ backgroundColor: "#f6f7f9" }}>
//       <div className="athletes-container">
//         <div className="athlete-cont">
//           <div className="text-center">
//             <p className="text-white athletes-head">ATHLETES</p>
//           </div>
//           <div className="e-tender-search-field">
//             <div>
//               <input placeholder="Search..." className="input" />
//             </div>
//             <div>
//               <img width="100" src={search} />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="athletes-heading">
//         <p>Athletes</p>
//       </div>
//       <div className="athletes-flex">
//         <div class="centered-children">
//           <div
//             className={`gender-option ${selected === "All" ? "selected" : ""}`}
//             onClick={() => handleClick("All")}
//           >
//             <p>All</p>
//           </div>
//           <div
//             className={`gender-option ${
//               selected === "Female" ? "selected" : ""
//             }`}
//             onClick={() => handleClick("Female")}
//           >
//             <p>Female</p>
//           </div>
//           <div
//             className={`gender-option ${selected === "Male" ? "selected" : ""}`}
//             onClick={() => handleClick("Male")}
//           >
//             <p>Male</p>
//           </div>
//         </div>
//         <div className="athelte-dropdown">
//           <select
//             value={selectSport}
//             className="athlete-dropdown-select"
//             onChange={handleSportsChange}
//           >
//             <option value="">Sports</option>
//             <option value="option1">Hockey</option>
//             <option value="option2">Tennis</option>
//             <option value="option3">Football</option>
//           </select>
//           <img src={dropdown} className="dropdown-arrow" />
//         </div>
//       </div>

//       <div className="table2-athlete">
//         <div
//           className={`${`Athlete-TableHeader`} position-relative py-4 fw-bold body-text`}
//         >
//           <div>ATHLETE NAME</div>
//           <div>SPORT</div>
//           <div>GENDER</div>
//           <div>AGE</div>
//           <div>STATE</div>
//         </div>
//         <div className="bodyContainer">
//           {allAthletes.map((item, index) => (
//             <Link to={item?.link} className="link-style">
//               <div className="Athlete-DivContainer" key={index}>
//                 <div style={{ display: "flex", paddingLeft: "20px" }}>
//                   <div>
//                     <img width="30" src={item.image} />
//                   </div>

//                   <div style={{ paddingLeft: "10px" }}>{item.athleteName}</div>
//                 </div>
//                 <div
//                   className="m-2"
//                   style={{ display: "flex", paddingLeft: "20px" }}
//                 >
//                   <div>
//                     <img width="20" src={item.sportImage} />
//                   </div>
//                   <div style={{ paddingLeft: "10px" }}>{item.Sport}</div>
//                 </div>
//                 <div className="m-2">{item.Gender}</div>
//                 <div className="m-2">{item.age}</div>
//                 <div className="m-2">{item.State}</div>
//               </div>
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Index;

const Athlete = () => {
  return (
    <div className="head-container-main">
      <ComingSoon />
    </div>
  );
};
export default Athlete; //all the bugs are fixed