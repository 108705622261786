import React from 'react'
import moment from 'moment';
import "./regstyle.scss";

const RegistrationIsSubmitCard = ({organisationList}) => {
   
    const formatDateTime = (dateString) => {
        return moment(dateString).format('DD/MM/YY [at] HH:mm');
    };
    return (
        <>
            <div>
                <div className="text-center" role="alert" aria-live="assertive">
                    <p>Welcome to the <strong>Unified Sports Portal!</strong></p><br />
                   <p> You have <span style={{color:"green"}}>successfully </span> submitted  your Registration details On {organisationList?.updated_at ? formatDateTime(organisationList.updated_at) : 'N/A'}.</p><br />
                   <p> Your Provisional Registration Number <strong>{organisationList?.provisinal_registration_id}</strong>.</p>

                </div>

                <div className="py-5 text-center mt-3">
                    <div className="">
                        <u>
                        <strong>Current status</strong>
                        </u>
                        <h5>
                            <div>Form <span style={{color:"green"}}>Submitted</span> to the <strong>Admin</strong>!</div>
                            <div>Last Sync at <span style={{color:"red"}}>{organisationList?.updated_at ? formatDateTime(organisationList.updated_at) : 'N/A'}</span></div>
                        </h5>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RegistrationIsSubmitCard