import React, { createContext, useState, useEffect, useContext ,useCallback} from "react";
import { getInsituteCorrectionList } from "../../services/insitituteApiService";

export const FormCorrectionContext = createContext();

export const FormCorrectionProvider = ({ children }) => {
  const [correctionListData, setCorrectionListData] = useState([]);
  const userDataString = localStorage.getItem("login_Details");
  const userData = JSON.parse(userDataString);
  // console.log("userData===>",userData?.user?.id);


  
  const fetchCorrectionForm = useCallback(async (id) => {
    try {
      const response = await getInsituteCorrectionList(id);
      setCorrectionListData(response.data);
    } catch (error) {
      console.error("Error fetching correction list:", error);
    }
  }, []);

//   useEffect(() => {
//     if (userData?.user?.id) {
//       fetchCorrectionForm(userData.user.id);
//     }
//   }, [userData?.user?.id, fetchCorrectionForm]);


  
// console.log(userData, "userdaaaa===>")
//   useEffect(() => {
//     if (userData?.user?.id) {
//       fetchCorrectionForm(userData?.user?.id);
//     }
//   }, []);

  return (
    <FormCorrectionContext.Provider value={{ correctionListData ,fetchCorrectionForm}}>
      {children}
    </FormCorrectionContext.Provider>
  );
};
