import React from 'react'

const Club = ({ Field,ErrorMessage ,isReadOnly,organisationList}) => {
    return (
        <>
            <h6 className="txtGap" style={{marginTop:"30px"}}>Additional Information</h6>
            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Number of Members</label>
                    <Field
                     name="club_member_count"
                     className="form-control"
                     placeholder="Number of Members"
                     disabled={isReadOnly('club_member_count')}
                  />
                   {(!isReadOnly('club_member_count') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="club_member_count" component="div" className="text-danger" />
                   
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels ">Facilities Available</label>
                    <Field
                     name="facilities"
                     className="form-control"
                     placeholder="Facilities Available"
                     disabled={isReadOnly('facilities')}

                  />
                  {(!isReadOnly('facilities') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="facilities" component="div" className="text-danger" />
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Achievements</label>
                    <Field
                     name="achievements"
                     className="form-control"
                     placeholder="Achievements"
                     disabled={isReadOnly('achievements')}
                  />
                  {(!isReadOnly('achievements') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="achievements" component="div" className="text-danger" />
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Affiliated Organizations</label>
                    <Field
                     name="affiliated_sport_body"
                     className="form-control"
                     placeholder="Affiliated Organizations"
                     disabled={isReadOnly('affiliated_sport_body')}
                  />
                  {(!isReadOnly('affiliated_sport_body') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="affiliated_sport_body" component="div" className="text-danger" />
                </div>
            </div>
        </>
    )
}

export default Club