import React, { createContext, useState } from 'react';

// Create a context
export const AtomContext = createContext();

// Create a provider component
export const AtomProvider = ({ children }) => {
  const [atom, setAtom] = useState({
    atom_authentication: "",
    name_of_legel_entity: ""
  }); 

  return (
    <AtomContext.Provider value={{ atom, setAtom }}>
      {children}
    </AtomContext.Provider>
  );
};
