import axios from "axios";
import { UnAuthApiService } from "../../config/apiServices";
import {
  GET_CITY_URL,
  GET_COUNTRY_URL,
  GET_MEDIA,
  GET_STATE_URL,
} from "../../config/commonRegistartionApi/commonRegistrationApi";

export const getAllCountryList = () => {
  return UnAuthApiService.get(GET_COUNTRY_URL);
};

export const getAllStateList = (countryId) => {
  return UnAuthApiService.get(`${GET_STATE_URL}${countryId}`);
};

export const getAllCityList = (stateId) => {
  return UnAuthApiService.get(`${GET_CITY_URL}${stateId}`);
};

export const getMedia = () => {
  // return UnAuthApiService.get(GET_MEDIA);
  return axios.get(GET_MEDIA);
};
