import React from "react";
import "./styles.scss";
// import nationalGames from "assets/png/37Nationalgames.png";
import { BsFacebook, BsShare, BsTwitter } from "react-icons/bs";
import classNames from "classnames";
import dayjs from "dayjs";
import useWindowDimensions from "../../utils/windowSize";
import ScrollerComponent from "../../common/ScrollerComponent/ScrollerComponent";
import nationalGames from "../../asserts/png/37Nationalgames.png";
let arr = [
  {
    id: 71,
    stateId: 1200,
    sportId: null,
    title: "Inauguration of 38th National Games website",
    description: "<p>Inauguration of 38th National Games website</p>",
    status: "ENABLED",
    createdAt: "2024-09-09T11:49:50.000Z",
    imageId: 367,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: null,
  },
  {
    id: 70,
    stateId: 1200,
    sportId: null,
    title:
      "Chief Minister Dhami hosts strategic meeting on 38th National Games preparation ",
    description:
      "<p>Chief Minister Dhami hosts strategic meeting on 38th National Games preparation&nbsp;</p>",
    status: "ENABLED",
    createdAt: "2024-09-09T11:46:58.000Z",
    imageId: 366,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: null,
  },
  {
    id: 69,
    stateId: 1200,
    sportId: null,
    title: "Chief Minister receives IOA Flag from Uttarakhand NGOC ",
    description:
      "<p>Chief Minister receives IOA Flag from Uttarakhand NGOC&nbsp;</p>",
    status: "ENABLED",
    createdAt: "2024-09-09T11:45:23.000Z",
    imageId: 365,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: null,
  },
  {
    id: 68,
    stateId: 1200,
    sportId: null,
    title: "Flag Handed to Uttarakhand for 38th National Games ",
    description:
      "<p>Flag Handed to Uttarakhand for 38th National Games&nbsp;</p>",
    status: "ENABLED",
    createdAt: "2024-09-09T11:39:43.000Z",
    imageId: 364,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: null,
  },
  {
    id: 67,
    stateId: 1200,
    sportId: 9,
    title: "Paramjeet Bisht & Suraj Panwar - 20 Km Athletics Olympics ",
    description:
      "<p>Paramjeet Bisht &amp; Suraj Panwar - 20 Km Athletics Olympics&nbsp;</p>",
    status: "ENABLED",
    createdAt: "2024-08-28T17:30:05.000Z",
    imageId: 363,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: "Athletics",
  },
  {
    id: 64,
    stateId: 1200,
    sportId: 9,
    title: "Ankita Dhyani - Women 5000m ",
    description: "<p>Ankita Dhyani - Women 5000m&nbsp;</p>",
    status: "ENABLED",
    createdAt: "2024-08-28T17:18:44.000Z",
    imageId: 359,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: "Athletics",
  },
  {
    id: 62,
    stateId: 1200,
    sportId: 6,
    title: "Lakshay Sen  - Mens Singles Badminton",
    description: "<p>Lakshay Sen&nbsp; - Mens Singles Badminton</p>",
    status: "ENABLED",
    createdAt: "2024-08-28T12:53:56.000Z",
    imageId: 355,
    img: "https://dummyimage.com/850x650/339966/fff",
    state_name: "Uttarakhand",
    sport_name: "Badminton",
  },
];
function RenderNews() {
  const { width } = useWindowDimensions();

  const [news, setnews] = React.useState(arr);
  return (
    <div>
      {news?.map((item, id) => (
        <div
          key={id}
          className="newsInsideSection"
          style={{
            borderBottom:
              width >= 640 && id !== news?.length - 1
                ? "1px solid #707070"
                : "none",
            cursor: "pointer",
          }}
          onClick={() => {
            // history.push(`/latest/news/${id}`);
          }}
        >
          <img
            src={item?.img ?? item?.imageSrc}
            alt="imageSrc"
            className="mb-3"
            style={{
              width: "100%",
              borderRadius: "1rem",
              height: `${width >= 640 ? "150px" : "200px"}`,
              objectFit: "cover",
            }}
          />
          <div
            style={{
              border: "1px solid #707070",
              width: "100%",
            }}
            className={width >= 640 ? "hidden" : ""}
          />
          <div
            className="postion_rel"
            style={{
              marginTop: width < 640 ? "-1.2rem" : 0,
            }}
          >
            <p className="newsTagStyle">NEWS</p>
            <p
              className="line-clamp-3 bold "
              style={{
                marginTop: width < 640 ? "1.5rem" : "2rem",
              }}
            >
              {item?.title}
            </p>
            <p className="color-light-gray bold text-sm">
              {dayjs(item?.createdAt).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

function LatestNewsSinglePage() {
  const route = window.location.pathname;
  const { width } = useWindowDimensions();
  const lastString = route?.split("/")?.pop();
  const [news, setnews] = React.useState(arr);

  return (
    <>
      <div className="latest-news-container">
        <div className="container latest_news_container">
          <div className="heading-text text-center">
            <h3 className="lates_news_heading mt-5">LATEST NEWS</h3>
          </div>
        </div>
        {/* <div className="slider-cont">
          <Slider />
        </div> */}
      </div>
      <div className='newsContainer'>
      {/* {width >= 640 && <AboutusHeader firstText="Latest" secondText="News" />} */}
      <div className='newsInsideSectionSeparator'>
        <div
          className='newsSectionBackground'
          style={{
            top: width < 640 ? 0 : "",
            height: width < 640 ? "10rem" : "25rem",
          }}
        />
        <div
          className={classNames(
            width < 640
              ? "mt-5"
              : `col-span-2 px-2  newsInsideSectionForLeftNewsLeftMargin`
          )}
          style={{
            backgroundColor: "#F8F8F8",
            border: "1px solid #EFEFEF",
            zIndex: 1,
          }}
        >
          <img
            src={nationalGames}
            alt="nationalGames"
            style={{ width: "100%", display: width < 640 ? "none" : "block" }}
          />
        
          <h3
            className={classNames(
              "bold mt-2 text-2xl news_heading_prev_new_stories",
              width < 640 ? "px-2" : ""
            ) }
          >
            Previous New Stories
          </h3>

          <div
            className={width < 640 ? "hidden" : "mt-2"}
            style={{
              overflowY: "scroll",
              scrollbarWidth: "none", /* For Firefox */
              msOverflowStyle: "none", /* For Internet Explorer and Edge */
            }}
          >
            <RenderNews />
          </div>
          <div className={classNames(width < 640 ? "" : "hidden")}>
            <ScrollerComponent
              style={{
                gridAutoColumns: "200px",
                padding: "0 0.5rem",
              }}
            >
              <RenderNews />
            </ScrollerComponent>
          </div>
        </div>
        <div
          className={classNames(
            `col-span-5 newsInsideSectionForRightNews`,
            width < 640 ? "mt-5" : ""
          )}
          style={{
            zIndex: 1,
          }}
        >
          <img
            loading="lazy"
            src={news?.[lastString]?.img ?? news?.[lastString]?.imageSrc}
            alt="imageSrc"
            style={{
              width: "100%",
              borderRadius: "1rem",
              height: width > 757 ? "600px" : "300px",
              objectFit: "cover",
            }}
          />
          <div className='p5'>
            <p className='newsTagStyle'>NEWS</p>
            <div className='marginTop'>
              <h2 className="individual_news_single_heading" >{news?.[lastString]?.title}</h2>
              <p className="color-light-gray">
                {dayjs(news?.[lastString]?.createdAt).format(
                  "MMM DD, YYYY"
                )}
              </p>
              <MarkupComponent
                htmlContent={news?.[lastString]?.description}
              />
            </div>
          </div>
          <div
            className={classNames("flex", width < 640 ? "" : "mx-5")}
            style={{
              border: "1px solid #7c7980",
              borderTopLeftRadius: "0.7rem",
              width: "fit-content",
            }}
          >
            <div
              className="p-2 bold"
              style={{
                borderTopLeftRadius: "0.6rem",
                borderBottomRightRadius: "0.7rem",
                color: "white",
                background: "#7c7980",
              }}
            >
              Enjoy this article? Why not share...
            </div>
            <div
              className="p-2 bold flex gap-3"
              style={{
                width: "fit-content",
                alignItems: "center",
              }}
            >
              <BsTwitter />
              <BsFacebook />
              <BsShare />
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={classNames(
          width < 640
            ? "hidden"
            : `newsSponsors grid place-items-center my-5`
        )}
      >
        <p className="color-light-gray text-xl">PARTNERS</p>
        <img src={partners} alt="partners" style={{ width: "80%" }} />
      </div> */}
    </div>
     
    </>

  );
}

export default LatestNewsSinglePage;

const MarkupComponent = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
