import "./styles.scss";
import { useState, useRef, useEffect, useContext } from "react";

import dummyImg from "../../../../asserts/Registration/dummyImg.jpg";
import uploadBtn from "../../../../asserts/Registration/uploadBtn.png";
import { CFormSelect, CFormInput, CButton, CContainer } from "@coreui/react";
import { CInputGroup, CInputGroupText, CFormText } from "@coreui/react";
import { VerifyContactContext } from "../../../contexts/verifyDetailsContext/verifyContactContext";

import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import { LocationContext } from "../../../../Context/LocationContext";
import { UploadDocument } from "../../../apiServices/educationApi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";

function PersonalDetails() {
  const [imageSrc, setImageSrc] = useState(dummyImg);
  const user = JSON.parse(localStorage.getItem("user"));
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isDisabledButton, setIsDisabledButton] = useState({
    profile_image: true,
  });

  const [isLoadingUpload, setIsLoadingUpload] = useState({
    profile_image: false,
  });

  const [isUploaded, setIsUploaded] = useState({
    profile_image: false,
  });

  const [formData, setFormData] = useState({
    profile_image: "",
  });
  // const { contactVerification, setContactVerification } =
  //   useContext(VerifyContactContext);
  const {
    userData,
    setUserData,
    userDocuments,
    setUserDocuments,
    correctionData,
    db_step_regestration,
    isPasswordSet,
    setIsPasswordSet,
    isPasswordClose,
  } = useContext(VerticalStepperContext);

  const { countries, states, cities, fetchStates, fetchCities } =
    useContext(LocationContext);
  const today_date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    fetchStates(userData?.country || 101);
  }, [userData?.country]);

  useEffect(() => {
    fetchCities(userData?.state);
  }, [userData?.state]);

  // useEffect(()=>{
  //   if( userData?.mobile === null  ){
  //     setUserData((obj)=>{
  //       return { ...obj,email:contactVerification?.email}
  //     })
  //   }
  // },[])
  // console.log(correctionData, "correc==>")
  const handleChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  const handlePincodeChange = (e) => {
    let value = e.target.value;
    setUserData({ ...userData, pincode: value });
    // setIsTyping(true);

    const newTimeout = setTimeout(() => {
      // setIsTyping(false);
      if (value === "") {
        setPincodeError("");
      } else {
        // Regular expression for basic email validation
        const pincodePattern = /^[1-9][0-9]{2}\s{0,1}[0-9]{3}$/;

        if (!pincodePattern.test(value)) {
          setPincodeError("Please enter a valid Pincode.");
        } else {
          setPincodeError("");
        }
      }
    }, 1000);
    // setTypingTimeout(newTimeout);
  };

  // console.log(userDocuments, "userco")

  const handleProfilePicUpload = async (e) => {
    setIsLoadingUpload({ ...isLoadingUpload, other: true });

    try {
      let response = await UploadDocument(formData.profile_image);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, profile_image: true });
        setIsUploaded({ ...isUploaded, profile_image: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          profile_image: true,
          profile_image_link: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, profile_image: false });
    }
  };
  // console.log("correction firstName",userData.status , "ask_for_resubmit" ,correctionData?.hasOwnProperty("first_name"))
  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    // Check the file type
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Only JPG, JPEG, and PNG files are allowed.",
      });

      e.target.value = ""; // Clear the input
      return;
    }

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "Image size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, profile_image: true });
      return;
    }
    const formData = new FormData();
    // const file = e.target.files[0];

    formData.append("profile_image", file);
    formData.append("id", user?.id);
    setFormData({ ...formData, profile_image: formData });
    if (file) {
      setIsDisabledButton({ ...isDisabledButton, profile_image: false });
    }
  };

  const handleViewClick = (docUrl) => {
    window.open(docUrl, "_blank");
  };

  const handleReUpload = () => {
    setIsUploaded({ ...isUploaded, profile_image: false });
    setUserDocuments({ ...userDocuments, profile_image: false });
  };

  const updatePasswordFields = (key, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (key === "confirmPassword") {
      if (userData.password !== value) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
  };

  const handlePasswordReset = () => {
    setIsPasswordSet(false);
  };

  const handleClosePassword = () => {
    setIsPasswordSet(true);
  }
  // console.log("profile image",correctionData)

  // console.log(userData, "userdatasss==>");
  return (
    <div className="personal-details-main-container">
      <div className="personal-details-container">
        <p className="personal-details-header ">Personal Details </p>
      </div>
      <div className="personal-details-form">
        <div className="">
          <div className="basic-information-4">
            <div className="email-password-1">
              <label className="label-pd">Upload Profile Photo</label>
            </div>
            {/* <div class="row"> */}
            {/* <div class="col-2"> */}
            {userDocuments?.profile_image ? (
              <div>
                <img
                  className="upload-box marginpd"
                  // width="160px"
                  // height="212px"
                  src={userDocuments.profile_image_link}
                  alt="Profile"
                />
              </div>
            ) : (
              <div className="upload-box text-upload">
                <span>Profile photo</span>
                {/* <img
                  className="upload-box"
                    width="160px"
                     height="212px"
                    
                   
                    alt=""
                  /> */}
              </div>
            )}
            {/* </div> */}

            {/* <div class="col-10"> */}
            {/* <div class="row"> */}
            <CFormInput
              className="uploadbutton marginpd"
              name=""
              onChange={handleProfileImageChange}
              type="file"
              id=""
              accept=".jpg,.jpeg,.png"
              text={
                <>
                  Only PNG, JPG, JPEG files are allowed.
                  <br />
                  {userData.status === "ask_for_resubmit" &&
                  !correctionData?.hasOwnProperty("profile_image") ? (
                    <span style={{ color: "red" }}>
                      {correctionData?.profile_image?.remark}
                    </span>
                  ) : userData.status === "ongoing" &&
                    !userDocuments?.profile_image ? (
                    <>
                      <br />
                      <span style={{ color: "red", marginLeft:"10px" }}>
                        Please upload a profile image.
                      </span>
                    </>
                  ) : null}
                </>
              }
              disabled={
                userDocuments?.profile_image || isUploaded?.profile_image
              }
            />

            <div className="upload-field mb-3">
              {/* <div class="col-4"> */}
              <button
                className="upload-btn-pd marginpd"
                onClick={handleProfilePicUpload}
                disabled={isDisabledButton?.profile_image}
              >
                <span>
                  {isUploaded?.profile_image || userDocuments?.profile_image
                    ? "Uploaded"
                    : isLoadingUpload?.profile_image
                    ? "Uploading..."
                    : "Upload"}
                </span>
              </button>
              {/* </div> */}

              {/* <div class=" pad-button"> */}
              {userDocuments?.profile_image && (
                // <div class="col-3 text-center">
                <CButton
                  className="gapping"
                  onClick={() =>
                    handleViewClick(`${userDocuments.profile_image_link}`)
                  }
                  color="success"
                  variant="outline"
                >
                  View
                </CButton>
                // </div>
              )}
              {userDocuments?.profile_image && (
                // <div class="col-5 text-center">
                <CButton
                  className="gapping"
                  color="success"
                  variant="outline"
                  onClick={handleReUpload}
                  disabled={
                    userData.status === "ask_for_resubmit" &&
                    !correctionData?.hasOwnProperty("profile_image")
                  }
                >
                  Reupload
                </CButton>
                // </div>
              )}
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>

        <div className="personal-details-address">
          <div className="personal-details-address-6">
            <label className="label-pd">First Name</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter First Name"
              value={userData?.first_name}
              onChange={(e) => handleChange("first_name", e.target.value)}
              onKeyDown={(e) => {
                // Allow only letters, space, and some common punctuation marks
                if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("first_name")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.first_name?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.first_name.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.first_name && (
                  <>
                    <span style={{ color: "red", marginLeft:"10px" }}>
                      Please fill in the first name.
                    </span>
                  </>
                ))
              }
            />
          </div>

          <div className="basic-information-2">
            <label className="contentbox">Middle Name</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Middle Name"
              value={userData?.middle_name}
              onChange={(e) => handleChange("middle_name", e.target.value)}
              onKeyDown={(e) => {
                // Allow only letters, space, and some common punctuation marks
                if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("middle_name")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.middle_name?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.middle_name.remark}
                    </span>
                  </>
                )
              }
            />
          </div>
          <div className="basic-information-1">
            <label className="contentbox">Last Name</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Last Name"
              value={userData?.last_name}
              onChange={(e) => handleChange("last_name", e.target.value)}
              onKeyDown={(e) => {
                // Allow only letters, space, and some common punctuation marks
                if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("last_name")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.last_name?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.last_name.remark}
                    </span>
                  </>
                )
              }
            />
          </div>
        </div>
        <div className="personal-details-address">
          <div className="basic-information-1">
            <label className="label-pd">Father's Name</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Father's Name"
              value={userData?.father_name}
              onChange={(e) => handleChange("father_name", e.target.value)}
              onKeyDown={(e) => {
                // Allow only letters, space, and some common punctuation marks
                if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("father_name")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.father_name?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.father_name.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.father_name && (
                  <>
                    <span style={{ color: "red", marginLeft:"10px" }}>
                      Please fill in the father's name
                    </span>
                  </>
                ))
              }
            />
          </div>

          <div className="basic-information-3">
            <label className="label-pd">DoB</label>
            <CFormInput
              className="formCNT"
              type="date"
              placeholder="dd/mm/yyyy"
              max={today_date}
              value={userData?.dob}
              onChange={(e) => handleChange("dob", e.target.value)}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("dob")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.dob?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.dob.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.dob && (
                  <>
                    <span style={{ color: "red", marginLeft:"10px" }}>
                      Please fill in your DOB.
                    </span>
                  </>
                ))
              }
            />
          </div>

        {/* </div> */}
        {/* =--- */}


        {/* <div className="personal-details-basic-information"> */}
          {/* <div className="basic-information-5">
            <label className="contentbox">Age</label>
            <input
              className="formCNT"
              type="number"
              placeholder="Enter Age"
              value={userData?.age}
              onChange={(e) => handleChange("age", e.target.value)}
            />
          </div> */}
          <div className="basic-information-7">
            <label className="label-pd">Gender</label>
            <CFormSelect
            className="formSlt"
              options={[
                { label: "Select Gender", value: "" },
                { label: "Female", value: "F" },
                { label: "Male", value: "M" },
                { label: "Other", value: "O" },
              ]}
              value={userData?.gender || "Select Gender"}
              onChange={(e) => handleChange("gender", e.target.value)}
              style={{ padding: "7px" }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("gender")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.gender?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.gender.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.gender && (
                  <>
                    <span style={{ color: "red", marginLeft:"10px" , marginBottom:"20px" }}>Select Gender</span>
                  </>
                ))
              }
            />
          </div>
        </div>

        <div className="personal-details-address">
          <div className="email-password-1">
            <label className="contentbox">Mobile Number</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Mobile Number"
              value={userData?.mobile || ""}
              disabled
              text={
                <>
                  <span style={{ color: "blueviolet" }}>Verified</span>
                </>
              }
              onChange={(e) => handleChange("mobile", e.target.value)}
            />
          </div>
          <div className="email-password-1">
            <label className="contentbox">Email ID</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Email ID"
              value={userData?.email || ""}
              disabled
              text={
                <>
                  <span style={{ color: "blueviolet" }}>Verified</span>
                </>
              }
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>
        </div>
        {/* ------------password container ------------ */}

        {/* {Number(db_step_regestration) < 5 && ( */}
        {/* {alert(isPasswordSet)} */}
        {/* userData?.steps >= 4 && */}
        {isPasswordSet && userData?.status === "ongoing" && (
          <CButton
            className="reset-password-btn"
            type="submit"
            color="primary"
            onClick={handlePasswordReset}
          >
            Do you want to Reset your Password ?
          </CButton>
        )}
        {/* userData?.steps < 4 &&  */}
        {userData?.status === "ongoing" && !isPasswordSet && (
          <>
            <div className="password-container">
              <div className="email-password-2">
                <label className="label-pd">Create Password</label>
                <div>
                  <CInputGroup>
                    <CFormInput
                      className="formCNT"
                      placeholder="Enter Create Password"
                      type={showPassword ? "text" : "password"}
                      value={userData?.password || ""}
                      onChange={(e) =>
                        updatePasswordFields("password", e.target.value)
                      }
                      disabled={
                        userData.status === "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty("password")
                      }
                    />

                    <CInputGroupText
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer",height: "37.6px" }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </CInputGroupText>
                  </CInputGroup>
                  <CFormText className="text-danger">
                    {userData.status === "ongoing" && !userData.password && (
                      <span style={{ color: "red" }}>Password is required</span>
                    )}
                  </CFormText>
                </div>
              </div>
              <div className="email-password-3">
                <label className="label-pd">Confirm Password</label>
                <div>
                  <CInputGroup>
                    <CFormInput
                      className="formCNT"
                      placeholder="Enter Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={userData?.confirmPassword || ""}
                      onChange={(e) =>
                        updatePasswordFields("confirmPassword", e.target.value)
                      }
                      disabled={
                        userData.status === "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty("password")
                      }
                    />

                    <CInputGroupText
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{ cursor: "pointer",height: "37.6px" }}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </CInputGroupText>
                  </CInputGroup>
                </div>

                <CFormText className="text-danger">
                  {passwordError && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </CFormText>

                
              </div>
              {isPasswordClose && (
                <div>
                  <CButton
                    color="danger"
                    className="mt-5"
                    onClick={handleClosePassword}
                  >
                    Close
                  </CButton>
                </div>
              )}
              {/* <div>
              <CButton color="danger"  className="mt-5" onClick={handleClosePassword}>Close</CButton>
              </div> */}
              
            </div>
          </>
        )}

        <p className="personal-details-address-header">Address Details</p>

        <div className="personal-details-address">
          <div className="personal-details-address-6">
            <label className="label-pd">Country</label>

            <CFormSelect
              className="formSlt"
              options={[
                "Select Country",
                { label: "India", value: "101" },

                ...countries?.map((country) => ({
                  label: country?.name,
                  value: country?.id,
                })),
              ]}
              value={userData?.country || "101"}
              onChange={(e) => handleChange("country", e.target.value || "101")}
              style={{ padding: "7px",marginLeft:"10px" }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("country")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.country?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.country.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.country && (
                  <>
                    <span style={{ color: "red" }}>Select Country</span>
                  </>
                ))
              }
            />
          </div>

          <div className="personal-details-address-6">
            <label className="label-pd">State</label>
            <CFormSelect
              className="formSlt"
              options={[
                "Select State",
                ...states?.map((state) => ({
                  label: state?.name,
                  value: state?.id,
                })),
              ]}
              value={userData?.state}
              onChange={(e) => handleChange("state", e.target.value)}
              style={{ padding: "7px",marginLeft:"10px" }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("state")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.state?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.state.remark}
                    </span>
                  </>
                )
              }
            />
          </div>

          <div className="personal-details-address-6">
            <label className="label-pd">City</label>
            <CFormSelect
              className="formSlt"
              options={[
                "Select City",
                ...cities?.map((city) => ({
                  label: city?.name,
                  value: city?.id,
                })),
              ]}
              value={userData?.city}
              onChange={(e) => handleChange("city", e.target.value)}
              style={{ padding: "7px",marginLeft:"10px" }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("city")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.city?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.city.remark}
                    </span>
                  </>
                )
              }
            />
          </div>
          <div className="personal-details-address-4">
            <label className="label-pd">Pincode</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Pincode"
              type="text"
              maxLength={6}
              value={userData?.pincode}
              onChange={handlePincodeChange}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("pincode")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.pincode?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.pincode.remark}
                    </span>
                  </>
                )
              }
            />
            {pincodeError && (
              <span style={{ color: "red" }}>{pincodeError}</span>
            )}
          </div>
          <div className="personal-details-address-1 astrick-ld">
            <label className="label-pd">Address</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Address"
              value={userData?.address}
              onChange={(e) => handleChange("address", e.target.value)}
              onKeyDown={(e) => {
                // Allow only letters, space, and some common punctuation marks
                if (/[^a-zA-Z\s'.,]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("address")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.address?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.address.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.address && (
                  <>
                    <span style={{ color: "red" }}>
                      Please fill your address
                    </span>
                  </>
                ))
              }
            />
          </div>

          <div className="personal-details-address-5">
            <label className="label-pd">Landmark</label>
            <CFormInput
              className="formCNT"
              placeholder="Enter Landmark"
              value={userData?.landmark}
              onChange={(e) => handleChange("landmark", e.target.value)}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("landmark")
              }
              text={
                userData.status === "ask_for_resubmit" &&
                correctionData?.landmark?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.landmark.remark}
                    </span>
                  </>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
