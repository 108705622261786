import { link } from "fs";

export const sports = [
  "Aquatics",
  "Judo",
  "Archery",
  "Kabaddi",
  "Athletics",
  "Karate Do",
  "Badminton",
  "Kho-kho",
  "Basketball",
  "Rugby",
  "Boxing",
  "Sailing",
  "Canoeing",
  "Sepak-Takraw",
  "Kayaking",
  "Shooting",
  "Rowing",
  "Squash",
  "Cycling",
  "Taekwondo",
  "Equestrian",
  "Tennis",
  "Fencing",
  "Table Tennis",
  "Gymnasitcs",
  "Triathlon",
  "Golf",
  "Volleyball",
  "Handball",
  "Beach Volleyball",
  "Beach Handball",
  "Weightlifting",
  "Hockey",
  "Wrestling",
  "Wushu",
];

export const departmentsOfSports = [
  { label: "Home", value: "home", link: "/" },
  { label: "About", value: "aboutUs", arrowIcon: true },
  { label: "Calender", value: "calendar", link: "/calendar" },
  { label: "Athletes", value: "athletes", arrowIcon: true },
  { label: "Notification", value: "notification", arrowIcon: true },
  { label: "Infrastructure", value: "infrastructure", arrowIcon: true },
];

export const footerOptions = [
  { label: "Home", value: "" },
  { label: "Calendar", value: "calendar" },
  { label: "Infrastructure", value: "infrastructure" },
  { label: "Schemes", value: "comingsoon" },
  { label: "Budget", value: "comingsoon" },
  { label: "Contact Us", value: "contact-us" },
  { label: "Registration", value: "registration" },
  { label: "Login", value: "login_email" },
];
export const footerOptionsAbout = [
  { label: "Objectives", value: "athletes" },
  { label: "Mission & Vision", value: "mission_vision" },
  { label: "Scholarship", value: "scholarship" },
  { label: "Organizational Structure", value: "organisational_structure" },
];
export const footerOptionsLatest = [
  { label: "News", value: "latest-news" },
  { label: "Photos", value: "photoGallery" },
  { label: "Videos", value: "latest-videos" },
];
export const footerOptionsNotification = [
  { label: "Latest Updates", value: "comingsoon" },
  { label: "Tender", value: "e-tender" },
  { label: "Govt. Circular", value: "comingsoon" },
  { label: "Right to Information", value: "right-to-information" },
];
export const footerOptionsAthletes = [
  { label: "Profile", value: "athletes" },
  { label: "Rankings", value: "athletes_profile" },
  { label: "Head to Head", value: "head_to_head" },
];
export const menuItemsList = [
  { label: "Home", value: "home", link: "/" },
  { label: "About Us", value: "aboutUs" },
  { label: "Calender", value: "calender" },
  { label: "Athletes", value: "athletes" },
  { label: "Notification", value: "notification" },
  { label: "Infrastructure", value: "infrastructure" },
];

export const menuPopUpOptions = [
  { label: "Schemes", value: "schemes", link: "/comingsoon" },
  { label: "Budget", value: "budget", link: "/comingsoon" },
  { label: "Latest", value: "latest", arrowIcon: true },
  { label: "Contact Us", value: "contact-us", link: "/contact-us" },
  // { label: "Login", value: "login_email", link: "/login_email" },
  // { label: "Registration", value: "registration", link: "/registration" },
  // { label: "Registration Aadhar", value: "registration_aadhar" },
];

export const companyInfo = [
  `Directorate of Sports & Youth Affairs,
  DoTACS & Y, Gate No. 29 Birsa Munda Football Stadium,
   Morabadi, Ranchi-834008`,
  `Pin- 248008`,
  `Ph- +91 0651 2400740`,
  `Email Id : kheljharkhand@gmail.com`,
];

export const aboutUsData = {
  name: "About Us",
  listofSubmenu: [
    { label: "Objectives", value: "Objectives", link: "/aboutUs" },
    {
      label: "Mission & Vision",
      value: "Mission & Vision",
      link: "/mission_vision",
    },

    { label: "Scholarship", value: "Scholarship", link: "/scholarship" },
    {
      label: "Organizational Structure",
      value: "Organizational Structure",
      arrowIcon: true,
      link: "/organisational_structure",
    },
    // { label: "Sports Association", value: "Sports Association" },
  ],
};
export const AthletesData = {
  name: "Athletes",
  listofSubmenu: [
    { label: "Profile  ", value: "Profile", link: "/athletes" },
    { label: "Rankings", value: "Rankings", link: "/athletes_profile" },
    {
      label: "Head to Head",
      value: "head_to_head",
      arrowIcon: true,
      link: "/head_to_head",
    },
  ],
};

export const NotificationData = {
  name: "Notification",
  listofSubmenu: [
    {
      label: "Latest Updates",
      value: "Latest Updates",
      link: "website-notification",
    },
    { label: "Tender  ", value: "E-tender", link: "/e-tender" },
    {
      label: "Policies",
      value: "Policies",
      arrowIcon: true,
      link: "/comingsoon",
    },
    { label: "Govt. Circular", value: "Govt. Circular", link: "/comingsoon" },
    {
      label: "Right to Information ",
      value: "Right to Information ",
      link: "/comingsoon",
    },
  ],
};

export const InfrastructureData = {
  name: "Infrastructure",
  listofSubmenu: [
    { label: "Stadia", value: "Stadia", link: "/infrastructure" },
    {
      label: "Multipurpose Halls",
      value: "Multipurpose Halls",
      link: "/infrastructure",
    },
    {
      label: "Centre of Execellence ",
      value: "Centre of Execellence ",
      arrowIcon: true,
      link: "/infrastructure",
    },
    { label: "Academies", value: "Academies", link: "/infrastructure" },
    { label: "Hostels", value: "Hostels", link: "/infrastructure" },
    {
      label: "Sports Universities",
      value: "Sports Universities",
      link: "/infrastructure",
    },
    {
      label: "Sports Complex",
      value: "Sports Complex",
      link: "/infrastructure",
    },
  ],
};

export const LatestData = {
  name: "Latest",
  listofSubmenu: [
    { label: "News", value: "News", link: "/latest-news" },
    { label: "Photos", value: "Photos", link: "/photoGallery" },
    {
      label: "Videos",
      value: "Videos",
      arrowIcon: true,
      link: "/latest-videos",
    },
  ],
};
