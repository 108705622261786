import React from "react";
import { MDBSpinner } from "mdb-react-ui-kit";


function Loader() {
  const spinnerStyle = {
    width: "100px",
    height: "100px",
    
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backdropFilter: "blur(10px)", 
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    position: "fixed", 
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  };


  return (
    <div style={containerStyle}>
      <MDBSpinner style={spinnerStyle} role="status">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
   
    </div>
  );
}

export default Loader;
