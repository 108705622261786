import { Link } from "react-router-dom";
import "./styles.scss";

function Index() {
  return (
    <>
      <div className="marquee enable-animation">
        <ul className="marquee__content">
          <li>
            <Link to="/e-tender#jharkhand-e-tender-table" className="link">
              <span className="marque-heading">E-Tender</span>
            </Link>{" "}
            <span className="dot">•</span>  <a href="#E-Tender" className="marquee_title_label">
            Establishment of High Performance
            Sports Science Centre in Ranchi
            </a>
          </li>
          <li>
            {" "}
            <Link to="/latest-news" className="link">
              <span className="marque-heading">News </span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#new_sub" className="marquee_title_label">
            Tribal women's team won the final
            match
            </a>
          </li>
          <li>
            {" "}
            <Link to="/scholarship" className="link">
              <span className="marque-heading">Scholarship</span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#jharkhand-sports-scholarship" className="marquee_title_label">
            Jharkhand State Sports Scholarship
            </a>
          </li>
          <li>
            {" "}
            <Link to="/latest-videos" className="link">
              <span className="marque-heading">Ceremony</span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#latest-videos-id" className="marquee_title_label">
            1st Uttarakhand Engineers Federation
            Cricket Cup-2023
            </a>
          </li>
        </ul>
        <ul className="marquee__content">
          <li>
            <Link to="/e-tender#jharkhand-e-tender-table" className="link">
              <span className="marque-heading">E-Tender</span>
            </Link>{" "}
            <span className="dot">•</span> 
            <a href="#E-Tender" className="marquee_title_label">
            Establishment of High Performance
            Sports Science Centre in Ranchi
            </a>
           
          </li>
          <li>
            {" "}
            <Link to="/latest-news" className="link">
              <span className="marque-heading">News </span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#new_sub" className="marquee_title_label">
            Tribal women's team won the final
            match
            </a>
          </li>
          <li>
            {" "}
            <Link to="/scholarship" className="link">
              <span className="marque-heading">Scholarship</span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#jharkhand-sports-scholarship" className="marquee_title_label">
            Jharkhand State Sports Scholarship
            </a>
          </li>
          <li>
            {" "}
            <Link to="/latest-videos" className="link">
              <span className="marque-heading">Ceremony</span>
            </Link>{" "}
            <span className="dot">•</span>
            <a href="#latest-videos-id" className="marquee_title_label">
            1st Uttarakhand Engineers Federation
            Cricket Cup-2023
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Index;
