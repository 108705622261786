

export const mapProfileViewData = (label, value) => {

    // if (label == "city") {
    //     return "enter city"
    // } else if (label == "country") {
    //     return "enter country"
    // }

    return value
}