import React from 'react'
import "./styles.scss";

function LoginLeft() {
  return (
    <div className="loginLeft_main">
      
    </div>
  )
}

export default LoginLeft;
