import "./App.css";
import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import RootRoutes from "./routing/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { DataContextProvider } from "./helpers/UserDataContext";
import { AadhaarProvider } from "./registrationServices/contexts/aadhaarContext/adharContext";
import { PersonalDetailsProvider } from "./registrationServices/contexts/personalDetailContext/personalDetailsContext";
import { VerifyContactProvider } from "./registrationServices/contexts/verifyDetailsContext/verifyContactContext";
import { LoadingContextProvider } from "./Context/Loading/LoadingContext";

import { ToastContainer } from "react-bootstrap";
import { BasicDetailsProvider } from "./registrationServices/contexts/basicDetailsContext/basicDetailsContext";
import { RegisterAsProvider } from "./registrationServices/contexts/registerContext/registerAsContext";
import { AtomProvider } from "./registrationServices/contexts/atomContext/atomContext";
import { VerticalStepperProvider } from "./Context/VerticalStepperCount/VerticalStepper";
import { LocationProvider } from "./Context/LocationContext";
import { SportsProvider } from "./Context/SportsContext";
import { FormProvider } from "./Context/InsituteDocumentUpload/DocumentUploadContext";
import { FormCorrectionProvider } from "./Context/FormCorrection/FormCorrectionContext";
import { InsituteRegistrationProvider } from "./Context/InsituteRegistrationAllData/InsituteRegistrationAllListData";

function App() {

  const navigate = useNavigate();
  const location = useLocation();


  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     event.preventDefault();
  //     window.history.go(1);
  //   };

  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);
 
  // useEffect(() => {
  //   const handlePopState = () => {
  //     // Show an alert
  //     alert('You cannot go back from this page.');

  //     // Redirect to the current location to prevent navigation
  //     navigate(location.pathname, { replace: true });
  //   };

  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [navigate, location.pathname]);



  return (
    // <BrowserRouter>
      <DataContextProvider>
        <LoadingContextProvider>
          <FormProvider>
            <FormCorrectionProvider>
              <AadhaarProvider>
                <AtomProvider>
                  <PersonalDetailsProvider>
                    <VerifyContactProvider>
                      <BasicDetailsProvider>
                        <RegisterAsProvider>
                          <LocationProvider>
                            <SportsProvider>
                            <InsituteRegistrationProvider>
                            <FormCorrectionProvider>
                              <ToastContainer />
                              <RootRoutes />
                              </FormCorrectionProvider>
                              </InsituteRegistrationProvider>
                            </SportsProvider>
                          </LocationProvider>
                        </RegisterAsProvider>
                      </BasicDetailsProvider>
                    </VerifyContactProvider>
                  </PersonalDetailsProvider>
                </AtomProvider>
              </AadhaarProvider>
            </FormCorrectionProvider>
          </FormProvider>
        </LoadingContextProvider>
      </DataContextProvider>
    // </BrowserRouter>
  );
}

export default App;
