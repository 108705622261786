import React from "react";
import "./styles.scss";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";


const rtiContent = [
  {
    heading: "Characteristics, functions and duties of the organisation",
    desc: `<b>Organization Name - </b><span>Sports Directorate</span>
    <p>With the aim of promoting sports and giving it new heights, the State Government has constituted the Directorate of Sports under the Department of Tourism, Art-Culture, Sports and Youth Affairs. The Director is the highest official of the Directorate of Sports.</p>
    <p>Functions and duties of Sports Directorate-</>
    <li>To provide basic facilities for training.</li>
    <li>To honour outstanding performing players.</li>
    <li>Control and operation of residential and day-boarding training centers operated in the state.</li>
    <li>Coordination with district level offices (Office of the District Sports Officer).</li>
    <li>Implementation of schemes approved by the Department of Tourism, Art-Culture, Sports and Youth Affairs.</li>
    `,
  },
  {
    heading: "Powers and duties of officers and employees",
    desc: ` <li>Powers and Responsibilities.</li>
    <li>Duties and Authorities.</li>`,
  },
  {
    heading:
      "The procedure followed in the decision making process which includes means of supervision and accountability",
      desc: ` <li>Supervision Overview.</li>
      <li>Accountability Mechanisms.</li>`,
  },
  {
    heading: "Norms established for discharge of functions",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Rules, regulations, instructions, directories and records held under the control of the Directorate or used for the discharge of its functions",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Details of categories of documents held by the Directorate or under its control",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Particulars of any arrangement which exists for consultation with, or representation by, members of the public in relation to the formulation of its policy and its implementation",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Boards, councils, committees and other bodies consisting of two or more persons constituted as their members or for the purpose of advising",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Directory of officers and employees",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Monthly remuneration received by each of its officers",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Particulars of reports on all schemes, proposed expenditure and disbursements made and the budget allocated to each agency",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Manner of execution of subsidy programs (details of beneficiaries of the programmes)",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Particular recipients of concessions, permits or authorizations granted by it",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Details in respect of information available in any electronic form",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Particulars of facilities available to citizens for obtaining information, including the working hours of any library or reading room, if maintained for public use",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Names, designations and other particulars of Public Information Officers- Name of Public Information Officer",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
  {
    heading: "Such other information as may be prescribed",
    desc: ` <li>Compliance Standards.</li>
    <li>Operational Guidelines.</li>`,
  },
];

function Index() {
  return (
    <div>
  
      <div className="rti-container">
          <div className="container">
          <div className="text-center">
            <p className="text-white common-heading-rti">RIGHT TO INFORMATION</p>
          </div>
        </div>
      </div>

      <div className="RTI-contents">
        <div  className="RTI-heading">
          <h2>Section 4(1)(b) under the Right to Information Act</h2>
          <p>
          With the aim of promoting sports and giving it new heights, the State Government has constituted the Directorate of Sports under the Department of Tourism, Art-Culture, Sports and Youth Affairs. The Director is the highest official of the Directorate of Sports.
          </p>
        </div>
        <div className="RTI-details">
          {rtiContent.map((item, index) => (
            <div key={index} className="accordion-wrapper">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  className="accordion-heading"
                >
                  {item.heading}
                </AccordionSummary>
                <AccordionDetails>
                  {item.desc && (
                    <div  className="Rti-li" dangerouslySetInnerHTML={{ __html: item.desc }} />
                  )}
                </AccordionDetails>
              </Accordion>
              {index < rtiContent.length - 1 && (
                <div className="accordion-gap" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Index;
