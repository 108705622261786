import React from 'react'
import "./regstyle.scss";


const RegistrationPassCard = () => {
  return (
    <>
    <div>
        <div role="alert" aria-live="assertive"className="text-center">
        
         <p>Welcome to the <strong>Unified Sports Portal!</strong></p>
        <p>Your Application is <span style={{color:"green"}}>verified</span> and we will shortly <span style={{color:"green"}}>Activate</span> your Dashboard.</p> 
        

        </div>

       
    </div>
</>
  )
}

export default RegistrationPassCard