import React from "react";
import "./styles.scss";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import LoginLeft from "../../components/LoginLeft";
import sajhaImg from "../../asserts/Footer/jharkhand logo 1 (1).png"
import jk_img from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png"
import {ReactComponent as BackIcon} from "../../asserts/login/backIcon.svg";
import{ReactComponent as ForwardIcon} from "../../asserts/login/forwardIcon.svg"

const OTP_Verification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { handleSubmit, register } = useForm();
  const onSubmit = (e) => console.log("data", e);
  return (
    <>
      <div className="sign_in_right_container">
        <div className="loginLeft">
          <LoginLeft />
          <div className="jharkhand_logos_login">
          <div>
            <Link to="/">
              <img src={jk_img} width="80" />
            </Link>
          </div>
          <div>
            <Link to="/">
              <img src={sajhaImg} width="75" />
            </Link>
          </div>
        </div>
        </div>

        <div className="loginRight loginRightv2">
          <h1>OTP Verification</h1>
          <p className="px-5 mb-3">Enter the OTP sent to - John@gmail.com</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="otp-verify-login">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  fontSize: "20px",
                  margin: "10px 10px 10px 10px",
                  border: "none",
                  backgroundColor: "whitesmoke",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="timer-text">
              <p>00:120 Sec</p>
              <p>
                Don’t receive code? <Link className="link-style">Re-send</Link>
              </p>
            </div>
            <Link to="/create-new-password">
            <button className="submit-btn" type="submit">
              Submit
            </button>
            </Link>
          </form>
          <div className="arrow_container_forger_password">
            <p onClick={()=>navigate('/forget-password')}>Go Back</p>
          {/* <BackIcon onClick={()=>navigate('/forget-password')}/>
          <ForwardIcon onClick={()=>navigate('/create-new-password')}/> */}
        </div>
        </div>
      </div>
    </>
  );
};

export default OTP_Verification;
