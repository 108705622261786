import "./style.scss";

type TOrganisationalDetails = {
  profile: any;
};

const OrganisationalDetails: React.FC<TOrganisationalDetails> = ({
  profile,
}) => {
  return (
    <div className="cheif-guest-board d-flex justify-content-between align-items-center gap-3 mb-4  card-less-hover card-hover">
      <img
        height="100%"
        width="100%"
        src={profile.backgroundImage}
        alt="bg-img"
        className="image"
      />
      <img
        height="100%"
        width="100%"
        src={profile.backgroundImage1}
        alt="bg-img"
        className="image_pattern"
      />
      <div className="text-area">
        <h1 className="profile-name">{profile?.name}</h1>
        {/* <p>{profile?.position}</p> */}
        <p className="profile-position" dangerouslySetInnerHTML={{ __html: profile?.position }} />
        <p className="profile-state" dangerouslySetInnerHTML={{ __html: profile?.state }} />
        <p className="profile-state mx-5" dangerouslySetInnerHTML={{ __html: profile?.cum }} />
        <p className="profile-address" dangerouslySetInnerHTML={{ __html: profile?.address }} />
        <p>{profile?.contact}</p>
        <p>{profile?.sportsDirectorate}</p>
        <p>{profile?.mobile}</p>
      </div>
      <img
        width="30%"
        height="auto"
        src={profile.image}
        alt="img"
        className="person_image card-hover card-less-hover"
      />
    </div>
  );
};

export default OrganisationalDetails;
