import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { countriesData } from "../../../common/aboutUs";
import { FaExclamationTriangle } from "react-icons/fa";
import Loader from "../Loader/Loader";
import OtpInput from "react-otp-input";
import { LoadingContext } from "../../../Context/Loading/LoadingContext";
import Swal from "sweetalert2";
import MyTimer from "./Timer/timer";
import { Col, Container, Row } from "react-bootstrap";
import { VerifyContactContext } from "../../contexts/verifyDetailsContext/verifyContactContext";
import {
  mobileOtpGeneration,
  resendMobileOtp,
  mobileOtpVerification,
  emailOtpGeneration,
  emailOtpVerification,
  resendEmailOtp,
} from "../../apiServices/verifyContactApi";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function VerifyContactDetails() {
  //
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(VerticalStepperContext);

  const [otpMoNumber, setOtpMoNumber] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [error, setError] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const { loading, setLoading } = useContext(LoadingContext);
  const [timerStart, setTimerStart] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isMobileResend, setIsMobileResend] = useState(true);
  const [isEmailResend, setIsEmailResend] = useState(true);
  const [emailTimerStart, setEmailTimerStart] = useState(false);
  const [resetEmailTimer, setResetEmailTimer] = useState(false);
  const [isEmailRunning, setIsEmailRunning] = useState(false);

  const { contactVerification, setContactVerification } =
    useContext(VerifyContactContext);

   
  const handleChangeCountry = (event) => {
    setSelectedCode(event.target.value);
    
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }
  const handleConfirmMobile = async () => {
    setLoading(true);
    try {
      let response = await mobileOtpVerification({
        otp: otpMoNumber,
        mobile: contactVerification.mobile,
      });
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });

        setContactVerification({
          ...contactVerification,
          isMobileVerified: true,
          isMobileOtpDisabled: false,
          // mobile: "",
        });
        // setOtpMoNumber("");
      } else {
        if (response?.data?.message) {
          Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSendMobileOtp = async () => {
    setLoading(true);
    try {
      let response = await mobileOtpGeneration({
        mobile: contactVerification.mobile,
      });
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.msg}`,
          icon: "success",
        });
        setTimerStart(true);
        setIsMobileResend(false);
        setContactVerification({
          ...contactVerification,
          isMobileDisabled: true,
          isMobileOtpDisabled: true,
        });
      } else if (response?.data?.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Server is Down, Please try again Later!",
        });
      } else {
        if (response?.data?.message) {
          Swal.fire(response?.data?.msg);
        } else {
          console.log("error", response.data.msg);
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSendotpEmail = async () => {
    setLoading(true);
    try {
      let response = await emailOtpGeneration({
        email: contactVerification.email,
      });
      console.log(response);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.msg}`,
          icon: "success",
        });
        setEmailTimerStart(true);
        setIsEmailResend(false);
        setContactVerification({
          ...contactVerification,
          isEmailDisabled: true,
          isEmailOtpDisabled: true,
        });
      } else {
        if (response?.data?.msg) {
          Swal.fire(response?.data?.msg);
        } else {
          console.log("error", response.data.msg);
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;

    setContactVerification({ ...contactVerification, email: value });
    setUserData({ ...userData, email: value });
    setIsTyping(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      setIsTyping(false);
      if (value === "") {
        setError("");
      } else {
        // Regular expression for basic email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(value)) {
          setError("Please enter a valid email address.");
        } else {
          setError("");
        }
      }
    }, 1000);
    setTypingTimeout(newTimeout);
  };

  const handleMobileNumberChange = (e) => {
    let value = e.target.value;

    if (/^[0-5]/.test(value)) {
      value = value.slice(1);
    }

    setContactVerification({ ...contactVerification, mobile: value });
    setUserData({ ...userData, mobile: value });

    setIsTyping(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      setIsTyping(false);
      if (value === "") {
        setPhoneError(""); // Clear the error if the field is empty
      } else if (selectedCode === "+91" && value.length !== 10) {
        setPhoneError("Mobile number must be Valid.");
      } else {
        setPhoneError("");
      }
    }, 1000);

    setTypingTimeout(newTimeout);
  };

  const handleResendMobileOTP = async () => {
    setLoading(true);
    try {
      let response = await resendMobileOtp({
        mobile: contactVerification.mobile,
      });
      if (response?.data?.status == true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.msg}`,
          icon: "success",
        });
        setTimerStart(false); // Ensure the timer starts fresh
        setResetTimer((prev) => !prev); // Trigger timer reset
        setTimerStart(true); // Start the timer
        setContactVerification({
          ...contactVerification,
          isMobileOtpDisabled: true,
        });
      } else {
        if (response?.data?.message) {
          Swal.fire(response?.data?.msg);
        } else {
          console.log("error", response.data.msg);
        }
      }
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleResendotpEmail = async () => {
    // console.log(response, "response")
    setLoading(true);
    try {
      let response = await resendEmailOtp({ email: contactVerification.email });
      if (response?.data?.status == true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setEmailTimerStart(false); // Ensure the timer starts fresh
        setResetEmailTimer((prev) => !prev); // Trigger timer reset
        setEmailTimerStart(true);
        setContactVerification({
          ...contactVerification,
          isMobileOtpDisabled: true,
        });
      } else {
        if (response?.data?.message) {
          Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      // console.log("error---", error);
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmEmail = async () => {
    setLoading(true);
    try {
      let response = await emailOtpVerification({
        otp: otpEmail,
        email: contactVerification.email,
      });
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setContactVerification({
          ...contactVerification,
          isEmailVerified: true,
          isEmailOtpDisabled: false,
        });
      } else {
        if (response.data.message) {
          Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      // console.log(error?.response?.data?.message.email[0], "error==>");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setContactVerification({
      ...contactVerification,
      emailOtp: otpEmail,
      mobileOtp: otpMoNumber,
    });
  }, [otpEmail, otpMoNumber]);

  // // Manage the Previous Button
  // useEffect(() => {
  //   const handlePopState = () => {
  //     const confirmMessage = "Please Logout to go back.";
  //     alert(confirmMessage);
  //     window.history.pushState(null, "", window.location.pathname);
  //   };
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);
  // // // till here

  useEffect(() => {
    const handlePopState = () => navigate("/");
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);


  const displayValue = (countriesData) => {
    const regex = /\d+/; // matches one or more digits
      return countriesData.match(regex)[0];
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="verifyContactDetails-container mx-5">
          <div>
            <p className="contact-header mx-2">Verify Contact Details</p>
          </div>
          <Container>
            <Row>
              {/* /// Mobile Number Verify */}
              <Col lg={6} className="">
                <div className="left-container-verifycontact">
                  <div className="mobile-image-container">
                    <label className="label">Mobile Number</label>
                  </div>
                  <div className="input-field-verifyContact">
                    <select
                      //className="form-select country-code-drop px-2 mx-1"
                      className="form-select countcd mb-2"
                      aria-label="Default select example"
                      onChange={handleChangeCountry}
                      value={selectedCode}
                    >
                       {countriesData.map((country, index) => (
                        
                        <option 
                         key={index} value={country.code}
                        >
                         {`+${country.code}`} &nbsp;&nbsp;&nbsp;&nbsp; {country.name}
                         
                          {/* {`+${displayValue.code} 
                          (${displayValue.name})
                          `} */}
                        </option>
                      ))} 
                      
                    
                    </select>
                    <input
                      placeholder="Enter Mobile Number"
                      type="text"
                      class="emailField"
                      // pattern="[1-9]{1}[0-9]{9}"
                      value={contactVerification.mobile}
                      onChange={handleMobileNumberChange}
                      disabled={
                        contactVerification.isMobileVerified ||
                        contactVerification.isMobileDisabled
                      }
                      onKeyPress={(event) => {
                        const inputValue = event.target.value;

                        // Prevent non-numeric characters
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }

                        // Prevent the first character from being 0 to 5
                        if (inputValue === "" && /[0-5]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        event.preventDefault(); // Prevent paste
                      }}
                      onDrop={(event) => {
                        event.preventDefault(); // Prevent drag and drop
                      }}
                      maxLength={10}
                      className={`mobile_input_wrap mb-2 ${
                        phoneError ? "error" : ""
                      }`}
                    />
                    <button
                      disabled={
                        !!phoneError ||
                        contactVerification.mobile === "" ||
                        isTyping ||
                        contactVerification.isMobileDisabled
                      }
                      className="get_top_title " id="mobile_otp_btn"
                      onClick={handleSendMobileOtp}
                    >
                      {contactVerification.isMobileVerified
                        ? "Verified"
                        : "Get OTP"}
                    </button>
                  </div>

                  {phoneError && (
                    <div className="error-container">
                      <FaExclamationTriangle
                        style={{ marginRight: "5px", color: "red" }}
                      />
                      <span className="error-message">{phoneError}</span>
                    </div>
                  )}

                  {contactVerification.isMobileOtpDisabled && (
                    <>
                      {/* <div className="recaptcha-verifyDetails py-2 mt-2">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          onChange={onChange}
                        />
                      </div> */}
                      <div className="otpConfirm-verifyDetails">
                        <div className="otp-image-contact">
                          <label className="label">OTP</label>
                        </div>
                        <div className="otp-input-reg-contact">
                          <OtpInput
                            value={otpMoNumber}
                            onChange={setOtpMoNumber}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="input_box"
                                onKeyDown={handleKeyDown}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="didnot-get-the-code-verify">
                        <p>
                          Didn't get the code?{" "}
                          <button
                            className={`resend-btn ${
                              isRunning ? "disabled" : ""
                            }`}
                            onClick={handleResendMobileOTP}
                            disabled={
                              isRunning ||
                              contactVerification.mobile.length < 10 ||
                              isMobileResend
                            }
                          >
                            Resend
                          </button>
                          <span style={{ marginLeft: "8px" }}>
                            <MyTimer
                              startTimer={timerStart}
                              resetTimer={resetTimer}
                              onRunningChange={setIsRunning}
                            />
                          </span>
                        </p>
                      </div>
                    </>
                  )}

                  <div className="proceed-btn-verify-contact">
                    <button
                      type="submit"
                      disabled={
                        !contactVerification.mobile ||
                        !contactVerification.mobileOtp ||
                        otpMoNumber.length < 6 ||
                        contactVerification.isMobileVerified
                      }
                      className="proceed-btn"
                      onClick={handleConfirmMobile}
                    >
                      {contactVerification.isMobileVerified
                        ? "Verified"
                        : "Confirm"}
                    </button>
                  </div>
                </div>
              </Col>

              {/* /// Email */}
              <Col lg={6}>
                <div className="right-container-verifycontact">
                  <div className="mobile-image-container">
                    <label className="label">Email ID</label>
                  </div>
                  <div className="d-flex input-field-verifyContact">
                    <input
                      placeholder="Enter Email Id"
                      type="text"
                      className="mb-2"
                      value={contactVerification.email}
                      onChange={handleEmailChange}
                      disabled={
                        contactVerification.isEmailVerified ||
                        contactVerification.isEmailDisabled
                      }
                    />
                    <button
                      onClick={handleSendotpEmail}
                      className="get_top_title"
                      disabled={
                        !!error ||
                        contactVerification.email === "" ||
                        isTyping ||
                        contactVerification.isEmailDisabled
                      }
                    >
                      {contactVerification.isEmailVerified
                        ? "Verified"
                        : "Get OTP"}
                    </button>
                  </div>
                  {error && (
                    <div className="error-container">
                      <FaExclamationTriangle
                        style={{ marginRight: "5px", color: "red" }}
                      />
                      <span className="error-message">{error}</span>
                    </div>
                  )}

                  {contactVerification.isEmailOtpDisabled && (
                    <>
                      {/* <div className="recaptcha-verifyDetails">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          onChange={onChange}
                        />
                      </div> */}
                      <div className="otpConfirm-verifyDetails">
                        <div className="otp-image-contact">
                          <label className="label">OTP</label>
                        </div>
                        <div className="otp-input-reg-contact">
                          <OtpInput
                            value={otpEmail}
                            onChange={setOtpEmail}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="input_box"
                                onKeyDown={handleKeyDown}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="didnot-get-the-code-verify">
                        <p>
                          Didn't get the code?{" "}
                          <button
                            className={`resend-btn ${
                              isEmailRunning ? "disabled" : ""
                            }`}
                            onClick={handleResendotpEmail}
                            disabled={isEmailResend || isEmailRunning}
                          >
                            Resend
                          </button>
                          <span style={{ marginLeft: "8px" }}>
                            <MyTimer
                              startTimer={emailTimerStart}
                              resetTimer={resetEmailTimer}
                              onRunningChange={setIsEmailRunning}
                            />
                          </span>
                        </p>
                      </div>
                    </>
                  )}

                  <div className="proceed-btn-verify-contact">
                    <button
                      type="submit"
                      disabled={
                        !contactVerification.email ||
                        !contactVerification.emailOtp ||
                        contactVerification.isEmailVerified
                      }
                      className="proceed-btn"
                      onClick={handleConfirmEmail}
                    >
                      {contactVerification.isEmailVerified
                        ? "Verified"
                        : "Confirm"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}

export default VerifyContactDetails;
