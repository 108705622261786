import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";

function ModalMenu() {
  let navigate = useNavigate()
  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    userDocuments,
    setUploadedDocuments,
    handleNext,
    handleBack,
  } = useContext(VerticalStepperContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleSubmit = async () => {
    await handleNext();
    navigate("/final-submit_individual_registration", { replace: true });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to submit the Details ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalMenu;
