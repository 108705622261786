import React, { useState, useEffect, useContext } from "react";

import { FormCorrectionContext } from "../../../Context/FormCorrection/FormCorrectionContext";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { get_Registration_Listing } from "../../../config/registrationConfigUrl";
import { getCorrectionData } from "../../../config/registrationConfigUrl";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import Loader from "../../../registrationServices/registrationComponent/Loader/Loader";
import { formatDateTime } from "../../../utils/formatDateTime";

const RegisterReSubmitCard = () => {
  const {
    correctionData,
    setCorrectionData,
    userData,
    setUserData,
    currentStep,
    setCurrentStep,
  } = useContext(VerticalStepperContext);
  const [isDatAvailable, setIsDataAvailable] = useState(false);
  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchCorrectionforData = async (id) => {
    try {
      let response = await getCorrectionData(id);
      if (response?.data?.status !== true) {
        console.log("error", response.data.message);
        // Swal.fire(response?.data?.message || "Error Fetching Correction Data");
        return;
      }
      let dataArray = response?.data?.data?.registration_correction || [];
      // console.log('correction', dataArray )

      const convertedObject = dataArray.reduce((acc, curr) => {
        acc[curr.key] = {
          label: curr.label,
          value: curr.value,
          remark: curr.remark,
          key: curr.key,
        };
        return acc;
      }, {});
      setCorrectionData({ ...(convertedObject || {}) });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchCorrectionforData(user?.id);
    }
  }, []);

  const getRegistrationList = async () => {
    try {
      let response = await get_Registration_Listing(user?.id);
      if (response?.data?.status === true) {
        // setOrganizationList(response?.data?.data);
        let country = {};
        if (response?.data?.data?.country === null) {
          country.country = "101";
        }
        setUserData({ ...userData, ...response?.data?.data, ...country });
        // setUserData({...response.data.data})
        // console.log(response?.data, "respon==>")
      } else {
        if (response?.data?.message) {
          // console.log("error", response.data.message);
        } else {
          // console.log("error", response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
      //   Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  useEffect(() => {
    //info
    if (user?.id) {
      getRegistrationList();
    }
  }, []);

  // console.log("correctionListData==>", correctionData);

  // localStorage.setItem(
  //   "user",
  //   JSON.stringify({
  //     id: loginDetails.user?.id || user?.id,
  //     name: loginDetails.user?.name || user?.name,
  //   })
  // );

  useEffect(() => {
    if (correctionData && correctionData.length > 0) {
      setIsDataAvailable(true);
    }
  }, [correctionData]);

  return (
    <>
      {isDatAvailable ? (
        <Loader />
      ) : (
        <div className="mt-3">
          <div className="">
            <div className="text-center"role="alert" aria-live="assertive">
              <p>Welcome to the <b>Unified Sports Portal!</b></p>
              <br />
             <p> Your Application is <span style={{color:"green"}}>verified</span> by the <span style={{color:"red"}}>Admin</span>, and you are informed to
              <span style={{color:"red"}}>re-submit</span> some information as we
              </p>
              <br />
              <p>
              found that some information is <span style={{color:"red"}}>not correct</span> or <span style={{color:"red"}}>not meeting</span>
              <b>Registrations Criteria</b> on the <b>USP</b>.
              </p>
              <br />
              <span style={{color:"green", marginTop:"10px",marginBottom:"10px"}}>
              {formatDateTime(
                userData?.updated_at
              ).toLocaleString()}
              </span>
            </div>
            <div className="mt-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(correctionData).map((fieldsData, index) => (
                    <tr key={index}>
                      <td className="text-primary">
                       {fieldsData?.label.replace(/_/g, " ")}
                      </td>
                      <td>{fieldsData?.remark}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="m-auto w-100 text-center p-2">
                <Link to="/auth_registration">
                  <Button className="btn btn-success">
                    Re-Submit Registration
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterReSubmitCard;
