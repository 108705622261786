import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { getAllKitSize } from '../registrationServices/apiServices/sportDetailsApi';
import { getAllSports } from '../registrationServices/apiServices/sportDetailsApi';
import Swal from 'sweetalert2';
// Create a Context for the location data
export const SportsContext = createContext();
// export const useLocation = () => useContext(SportsContext);

// Create a Provider component
export const SportsProvider = ({ children }) => {
  const [kitSizeList , setKitSizeList] = useState([]);
  const [sportsList , setSportsList ] = useState([]);

  // Function to fetch countries
  async function fetchKitSizes() {
    try {
      let response = await getAllKitSize();
      if (response?.status == 200) {
          let data = Array.isArray(response?.data?.data) ? response.data.data : [];
          setKitSizeList([...data])
          return
      }
    //   const errorMessage = response?.data?.error || "An unknown error occurred";
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: errorMessage,
    //   });
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An unknown error occurred";
    //   Swal.fire("Oops", errorMessage, "error");
    }
  }

   const fetchSportsList = useCallback(async () => {
    try {
      let response = await getAllSports();
      if (response?.status === 200) {
        let data = Array.isArray(response?.data?.data) ? response.data.data : [];
        setSportsList([...data]);
        // console.log(response.data.data, "sport-data");
        return;
      }
      const errorMessage = response?.data?.error || "An unknown error occurred";
      
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An unknown error occurred";
  
    }
  }, []);
    

 
  

console.log('kit Sizes list', kitSizeList);


  return (
    <SportsContext.Provider value={{ kitSizeList , fetchKitSizes , sportsList ,setSportsList, fetchSportsList  }}>
      {children}
    </SportsContext.Provider>
  );
};