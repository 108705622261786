export const missionContent = [
    {
      heading: "Promotion of Sports Participation :",
      content: `Establishment of a robust talent identification and development pipeline, starting from the grassroots level.`,
    },
    {
      heading: "Talent Identification and Development :",
      content: `Creation of state-of-the-art sports academies and training centers across the state.`,
    },
    {
      heading: "Infrastructure Development :",
      content: `Implementation of scientifically designed training programs and coaching methodologies.`,
    },
    {
      heading: "Participation in National and International Events :",
      content: `Collaboration with national and international sports organizations to leverage expertise and resources.`
      ,
    },
 
  ];

  export const missionRightContent = [
    {
      heading: "Education and Awareness :",
      content: `Increased participation and success of Jharkhand athletes in national and international competitions.`,
    },
    {
      heading: "Ethical Practices and Sportsmanship :",
      content: `Development of a strong sports culture and increased recognition for sports in the state.`,
    },
    {
      heading: "Capacity Building and Training :",
      content: [
        `Attraction of private investments and sponsorships for sports development initiatives.
      `,
      ],
    },
  ];

  export const Vision = [
    {
      heading: "Promoting sports culture at all levels:",
      content: `The Sports Authority's vision to promote and contribute to the development of sports culture at all levels of the state resonates with the PM's vision of promoting a sports culture from the grassroots level.`,
    },
    {
      heading: "Access to training facilities:",
      content: `The vision to provide access to basic and high-performance training facilities for athletes and coaches aligns with the PM's emphasis on creating world-class sports infrastructure.`,
    },
    {
      heading: "Nurturing talent:",
      content: `The Sports Authority's vision to identify and nurture sports talent at the grassroots level and provide the best training environment directly supports the PM's vision of nurturing sporting talent.`,
    },
    {
      heading: "Health and fitness:",
      content: `The vision to promote sports for a healthy and fit Jharkhand aligns with the broader goal of promoting a sports-loving nation.`,
    },
    {
      heading: "Economic and social development:",
      content: `The Sports Authority's vision to ensure economic and social development of the youth through sports resonates with the PM's emphasis on providing opportunities for athletes to excel.`,
    },
    {
      heading: "National integration and development:",
      content: `The vision to encourage youth participation in sports for national integration and development aligns with the PM's vision of promoting sports as a means of fostering unity and national pride.`,
    },
  ];

  export const utility = [
    {
      heading: "Inclusive Participation : ",
      content: ` The state is committed to promoting sports participation among people of all ages, genders, abilities, and backgrounds. This includes creating opportunities for widespread participation in sports and physical activities, ensuring that everyone has access to facilities and programs that cater to their interests and needs.`,
    },
    {
      heading: "Health and Wellness :",
      content: `  Jharkhand recognizes the importance of sports in promoting physical fitness, mental health, and overall well-being. The vision includes using sports as a tool to combat lifestyle-related health issues such as obesity, sedentary behaviour, and non-communicable diseases, thereby promoting healthier and more active lifestyles among its citizens.`,
    },
    {
      heading: "Youth Empowerment : ",
      content: `The state aims to empower its youth through sports by providing them with opportunities for leadership, skill development, and personal growth. This involves offering scholarships, training programs, and mentorship opportunities to young athletes, as well as promoting sports as a pathway to education, employment, and social mobility.`,
    },
    {
      heading: "Community Engagement :",
      content: `Jharkhand seeks to engage communities in sports activities and events, fostering a sense of belonging, pride, and unity. The vision includes using sports as a platform for community development, cultural exchange, and social integration, thereby strengthening bonds and building bridges across diverse populations.`,
    },
    {
      heading: "Ethical Practices and Sportsmanship :",
      content: `The state is committed to upholding the highest standards of integrity, fairness, and sportsmanship in all aspects of sports. This involves promoting ethical conduct, respect for rules, and a spirit of fair play among athletes, coaches, officials, and administrators.`,
    },
  ];

  export const countriesData = [
    { name: "India", code: "91" },
    { name: "Afghanistan", code: "93" },
    { name: "Albania", code: "355" },
    { name: "Algeria", code: "213" },
    { name: "American Samoa", code: "1-684" },
    { name: "Andorra", code: "376" },
    { name: "Angola", code: "244" },
    { name: "Anguilla", code: "1-264" },
    { name: "Antarctica", code: "672" },
    { name: "Antigua and Barbuda", code: "1-268" },
    { name: "Argentina", code: "54" },
    { name: "Armenia", code: "374" },
    { name: "Aruba", code: "297" },
    { name: "Australia", code: "61" },
    { name: "Austria", code: "43" },
    { name: "Azerbaijan", code: "994" },
    { name: "Bahamas", code: "1-242" },
    { name: "Bahrain", code: "973" },
    { name: "Bangladesh", code: "880" },
    { name: "Barbados", code: "1-246" },
    { name: "Belarus", code: "375" },
    { name: "Belgium", code: "32" },
    { name: "Belize", code: "501" },
    { name: "Benin", code: "229" },
    { name: "Bermuda", code: "1-441" },
    { name: "Bhutan", code: "975" },
    { name: "Bolivia", code: "591" },
    { name: "Bosnia and Herzegovina", code: "387" },
    { name: "Botswana", code: "267" },
    { name: "Brazil", code: "55" },
    { name: "British Indian Ocean Territory", code: "246" },
    { name: "British Virgin Islands", code: "1-284" },
    { name: "Brunei", code: "673" },
    { name: "Bulgaria", code: "359" },
    { name: "Burkina Faso", code: "226" },
    { name: "Burundi", code: "257" },
    { name: "Cambodia", code: "855" },
    { name: "Cameroon", code: "237" },
    { name: "Canada", code: "1" },
    { name: "Cape Verde", code: "238" },
    { name: "Cayman Islands", code: "1-345" },
    { name: "Central African Republic", code: "236" },
    { name: "Chad", code: "235" },
    { name: "Chile", code: "56" },
    { name: "China", code: "86" },
    { name: "Colombia", code: "57" },
    { name: "Comoros", code: "269" },
    { name: "Cook Islands", code: "682" },
    { name: "Costa Rica", code: "506" },
    { name: "Croatia", code: "385" },
    { name: "Cuba", code: "53" },
    { name: "Curacao", code: "599" },
    { name: "Cyprus", code: "357" },
    { name: "Czech Republic", code: "420" },
    { name: "Democratic Republic of the Congo", code: "243" },
    { name: "Denmark", code: "45" },
    { name: "Djibouti", code: "253" },
    { name: "Dominica", code: "1-767" },
    { name: "Dominican Republic", code: "1-809, 1-829, 1-849" },
    { name: "East Timor", code: "670" },
    { name: "Ecuador", code: "593" },
    { name: "Egypt", code: "20" },
    { name: "El Salvador", code: "503" },
    { name: "Equatorial Guinea", code: "240" },
    { name: "Eritrea", code: "291" },
    { name: "Estonia", code: "372" },
    { name: "Ethiopia", code: "251" },
    { name: "Falkland Islands", code: "500" },
    { name: "Faroe Islands", code: "298" },
    { name: "Fiji", code: "679" },
    { name: "Finland", code: "358" },
    { name: "France", code: "33" },
    { name: "French Polynesia", code: "689" },
    { name: "Gabon", code: "241" },
    { name: "Gambia", code: "220" },
    { name: "Georgia", code: "995" },
    { name: "Germany", code: "49" },
    { name: "Ghana", code: "233" },
    { name: "Gibraltar", code: "350" },
    { name: "Greece", code: "30" },
    { name: "Greenland", code: "299" },
    { name: "Grenada", code: "1-473" },
    { name: "Guam", code: "1-671" },
    { name: "Guatemala", code: "502" },
    { name: "Guernsey", code: "44-1481" },
    { name: "Guinea", code: "224" },
    { name: "Guinea-Bissau", code: "245" },
    { name: "Guyana", code: "592" },
    { name: "Haiti", code: "509" },
    { name: "Honduras", code: "504" },
    { name: "Hong Kong", code: "852" },
    { name: "Hungary", code: "36" },
    { name: "Iceland", code: "354" },
    { name: "Indonesia", code: "62" },
    { name: "Iran", code: "98" },
    { name: "Iraq", code: "964" },
    { name: "Ireland", code: "353" },
    { name: "Isle of Man", code: "44-1624" },
    { name: "Myanmar", code: "95" },
    { name: "Namibia", code: "264" },
    { name: "Nauru", code: "674" },
    { name: "Nepal", code: "977" },
    { name: "Netherlands", code: "31" },
    { name: "Netherlands Antilles", code: "599" },
    { name: "New Caledonia", code: "687" },
    { name: "New Zealand", code: "64" },
    { name: "Nicaragua", code: "505" },
    { name: "Niger", code: "227" },
    { name: "Nigeria", code: "234" },
    { name: "Niue", code: "683" },
    { name: "North Korea", code: "850" },
    { name: "Northern Mariana Islands", code: "1-670" },
    { name: "Norway", code: "47" },
    { name: "Oman", code: "968" },
    { name: "Pakistan", code: "92" },
    { name: "Palau", code: "680" },
    { name: "Palestine", code: "970" },
    { name: "Panama", code: "507" },
    { name: "Papua New Guinea", code: "675" },
    { name: "Paraguay", code: "595" },
    { name: "Peru", code: "51" },
    { name: "Philippines", code: "63" },
    { name: "Pitcairn", code: "64" },
    { name: "Poland", code: "48" },
    { name: "Portugal", code: "351" },
    { name: "Puerto Rico", code: "1-787, 1-939" },
    { name: "Qatar", code: "974" },
    { name: "Republic of the Congo", code: "242" },
    { name: "Reunion", code: "262" },
    { name: "Romania", code: "40" },
    { name: "Russia", code: "7" },
    { name: "Rwanda", code: "250" },
    { name: "Saint Barthelemy", code: "590" },
    { name: "Saint Helena", code: "290" },
    { name: "Saint Kitts and Nevis", code: "1-869" },
    { name: "Saint Lucia", code: "1-758" },
    { name: "Saint Martin", code: "590" },
    { name: "Saint Pierre and Miquelon", code: "508" },
    { name: "Saint Vincent and the Grenadines", code: "1-784" },
    { name: "Samoa", code: "685" },
    { name: "San Marino", code: "378" },
    { name: "Sao Tome and Principe", code: "239" },
    { name: "Saudi Arabia", code: "966" },
    { name: "Senegal", code: "221" },
    { name: "Serbia", code: "381" },
    { name: "Seychelles", code: "248" },
    { name: "Sierra Leone", code: "232" },
    { name: "Singapore", code: "65" },
    { name: "Sint Maarten", code: "1-721" },
    { name: "Slovakia", code: "421" },
    { name: "Slovenia", code: "386" },
    { name: "Solomon Islands", code: "677" },
    { name: "Somalia", code: "252" },
    { name: "South Africa", code: "27" },
    { name: "South Korea", code: "82" },
    { name: "South Sudan", code: "211" },
    { name: "Spain", code: "34" },
    { name: "Sri Lanka", code: "94" },
    { name: "Sudan", code: "249" },
    { name: "Suriname", code: "597" },
    { name: "Svalbard and Jan Mayen", code: "47" },
    { name: "Swaziland", code: "268" },
    { name: "Sweden", code: "46" },
    { name: "Switzerland", code: "41" },
    { name: "Syria", code: "963" },
    { name: "Taiwan", code: "886" },
    { name: "Tajikistan", code: "992" },
    { name: "Tanzania", code: "255" },
    { name: "Thailand", code: "66" },
    { name: "Togo", code: "228" },
    { name: "Tokelau", code: "690" },
    { name: "Tonga", code: "676" },
    { name: "Trinidad and Tobago", code: "1-868" },
    { name: "Tunisia", code: "216" },
    { name: "Turkey", code: "90" },
    { name: "Turkmenistan", code: "993" },
    { name: "Turks and Caicos Islands", code: "1-649" },
    { name: "Tuvalu", code: "688" },
    { name: "U.S. Virgin Islands", code: "1-340" },
    { name: "Uganda", code: "256" },
    { name: "Ukraine", code: "380" },
    { name: "United Arab Emirates", code: "971" },
    { name: "United Kingdom", code: "44" },
    { name: "United States", code: "1" },
    { name: "Uruguay", code: "598" },
    { name: "Uzbekistan", code: "998" },
    { name: "Vanuatu", code: "678" },
    { name: "Vatican", code: "379" },
  ]
