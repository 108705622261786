import "./styles.scss";
import GridImages from "../../components/GridImages";
import { useEffect, useState, useRef } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { zIndex } from "material-ui/styles";

//import Header from "../../components/Header";

const Home = () => {

  const videoRef = useRef<HTMLVideoElement>(null);
  // useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  const [zIndex, setZIndex] = useState(-1);
  const [disPlay, setDisplay] = useState("none")

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setZIndex(prevZIndex => (prevZIndex === -1 ? 1 : 1));
      setDisplay(prevdisplay => (prevdisplay === "none" ? "flex" : "flex"));
    }
  };
  useEffect(() => {

    return () => {
      //window.removeEventListener('scroll', handleScroll);
    };
  }, [])




  return (
    // <div>
    //   <div className="banner-video ">
    //     <video
    //       autoPlay
    //       loop
    //       muted
    //       id="videoBack"
    //       //style={{ width: "100%", height: "100%", objectFit: "cover" }}
    //     >
    //       <source src="/assets/video/Jharkhand_video.mp4" type="video/mp4" />
    //     </video>

    //     <div className="home-banner">
    //       <div className="container  center_title home_banner_height">
    //         <div className="row justify-content-center align-items-center mt-5 ">
    //           <div className="col-sm-12 col-md-7 col-lg-7 ">
    //             <h2
    //               className="banner-header text-white "
    //               style={{ fontFamily: "Koulen" }}
    //             >
    //               Sports Authority
    //               <br /> of Jharkhand
    //             </h2>
    //             <div className="header-btn d-flex justify-content-center align-items-center py-3">
    //               {/* <ActionButton label="WATCH NOW" color="#ffffff" /> */}
    //             </div>
    //           </div>
    //           <div className="col-sm-12 col-md-5 col-lg-5 banner-right">
    //             <h2 className="form_home_banner_text ">
    //               From Roots to <br /> Records!
    //             </h2>
    //             <p className="form_home_banner_para">
    //               SAJHA is responsible for formulating sports policies,
    //               developing infrastructure, conducting training programs,
    //               hosting events, identifying talent, supporting athletes
    //               financially and medically, and promoting a culture of sports
    //               participation across Jharkhand to raise the level of sports in
    //               the state.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <GridImages />
    // </div>


    <div className="homepage conatiner-fluid" >


      {/* <div className="image-banner"> */}
      <div className="ban-container">
      <video className="ban-video" autoPlay muted loop>
        <source src="/assets/video/Jharkhand_video1.mp4" type="video/mp4" />
      </video>
      <div className="ban-overlay">
        <div className="ban-text">
        <div className="row" style={{ paddingTop: "150px" }} >
          <div className="col-lg-6 ">
            <h2 className="headerStyle mb-5">
              Sports Authority
              <br /> of Jharkhand
            </h2>
          </div>
          <div className="col-lg-6">
            <h2 className="header2Style">
              From Roots to <br /> Records!
            </h2>
            <p className="para">
              SAJHA is responsible for formulating sports policies,
              developing infrastructure, conducting training programs,
              hosting events, identifying talent, supporting athletes
              financially and medically, and promoting a culture of sports
              participation across Jharkhand to raise the level of sports in
              the state.
            </p>

          </div>
        </div>

          </div>
      </div>







      </div>
      <div className="scrollreveal">
        <h5>VIBRANT JHARKHAND : SCROLL TO REVEAL</h5>
        {/* <button onClick={handlePlay}>Play Background Video</button> */}
        <KeyboardDoubleArrowDownIcon
          className="bounce-icon"
          onClick={handlePlay}
          style={{ backgroundColor: "transparent", border: "none" }}
        />

      </div>
      <div className="contentdiv">


        <GridImages />
      </div>


    </div>







  );
};

export default Home;
