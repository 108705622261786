import React from 'react'
import Individual from '../../components/IndividualComponent/Individual'

const IndividualDahboard = () => {
  return (
    <>
   <Individual/>
    </>
  )
}

export default IndividualDahboard