import { ApiService, FileUploadService, UnAuthApiService } from "../config/apiServices";
import { DOCUMENT_UPDATE_URL, GET_INSITUTE_CORRECTION_LIST_URL, GET_ORGANIZATION_LIST_URL, LOGIN_URL, PROFILE_UPDATE_URL } from "../config/insititution/insititutionConfigUrl";
const userDataString = localStorage.getItem("login_Details");
const userData = JSON.parse(userDataString);
console.log(userData,"USERDATA===>")

export const adminloginApi = (loginData) => {
    return UnAuthApiService.post(LOGIN_URL,loginData);
}


export const profileUpdate = (data) => {
    return ApiService.post(PROFILE_UPDATE_URL,data);
};

export const documentUpdate = (data) => {
    return FileUploadService.post(DOCUMENT_UPDATE_URL,data);
};

export const getAllOrganisationList = (id) => {
    // if(userData.type == 'institute'){
    // return ApiService.get(`${GET_ORGANIZATION_LIST_URL}${id}`);
    // }
    return ApiService.get(`${GET_ORGANIZATION_LIST_URL}${id}`)
};


export const getInsituteCorrectionList = (id) => {
    // if(userData.type == 'institute'){
    //     return ApiService.get(`${GET_INSITUTE_CORRECTION_LIST_URL}${id}`);
    // }
    return ApiService.get(`${GET_INSITUTE_CORRECTION_LIST_URL}${id}`);
};
