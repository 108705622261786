import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import slider from "../../asserts/AboutUs/slider.png";
import { useLocation } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.scss';


function Index() {

    const [swiperRef, setSwiperRef] = useState(null);
    const appendNumber = useRef(500);
    const prependNumber = useRef(1);
    const [isHidden, setIsHidden] = useState(false);
    const navigate = useNavigate()
    const [menuData,setMenuData] = useState("aboutUs")

 const loaction = useLocation();

 console.log("texttoRender====>",loaction.pathname);


    const handleArrowKey = () => {

        setIsHidden(true);
        if (swiperRef) {
            swiperRef.slideNext();
        }
    };

    const handleSlideChange = () => {
        // Check if left slider is disabled (activeIndex is 0)
        if (swiperRef && swiperRef.activeIndex === 0) {
            setIsHidden(false);
        }
        if (swiperRef && swiperRef.activeIndex === 1) {
            setIsHidden(true);
        }
    };


    const menusliderdata = [
        {
            id: 1,
            title: "News",
            link: "/latest-news",
            count:"1"
        },
        {
            id: 2,
            title: "Videos",
            link: "/latest-videos",
            count:"2"
        },
        {
            id: 3,
            title: "Photo Gallery",
            link: "/photoGallery",
            count:"3"
        },
     
       
    ];



    return (
        <>
            <Swiper
                modules={[Virtual, Navigation, Pagination]}
                onSwiper={setSwiperRef}
                slidesPerView={5}
                centeredSlides={true}
                spaceBetween={15}
                navigation={true}
                onSlideChange={handleSlideChange}
                className='mySlider '
                virtual

            >
                <SwiperSlide>
                    <div className={isHidden ? 'hidden' : ''} onClick={handleArrowKey}>
                        <img width="5" src={slider} />
                    </div>
                </SwiperSlide>
               
                {
                    menusliderdata.map((item) => {
                        return (
                            <>
                                <SwiperSlide key={item.id}>
                                    <div onClick={() => navigate(`${item.link}`)}>
                                        <div className={`${loaction.pathname === item.link ? "active_menu_slider":"slider_menu_title" }`}>
                                            <p>{item.count}</p>
                                            <p>{item.title}</p>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            </>
                        )
                    })
                }

            </Swiper>

        </>
    );
}

export default Index

