import React, { createContext, useState } from 'react';

// Create a context
export const AadhaarContext = createContext();

// Create a provider component
export const AadhaarProvider = ({ children }) => {
  const [aadhaarData, setAadharData] = useState({
    adhaarNumber: "",
    name: "",
    
  }); 

  return (
    <AadhaarContext.Provider value={{ aadhaarData, setAadharData }}>
      {children}
    </AadhaarContext.Provider>
  );
};
