import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../asserts/login/sport_logo 1.png";
import uttharakhand_rajya from "../../asserts/login/jharkhand logo 2.png";
import "./style.scss";
import {
  departmentsOfSports,
  menuPopUpOptions,
  aboutUsData,
  AthletesData,
  NotificationData,
  InfrastructureData,
  LatestData,
} from "../../common";
import { Col, Container, Row } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";

const MenuPopUp = ({ handleClose }) => {
  const navigate = useNavigate();
  const [popupItem, setPopupItem] = useState();
  const [latestPopup, setLatestPopup] = useState();
  const [notificationPopup, setNotificationPopup] = useState();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuData, setSubmenuData] = useState(null);

  const loginToken =
    localStorage.getItem("login_Details") ||
    localStorage.getItem("currentStep");
  const currentStep = JSON.parse(localStorage.getItem("currentStep")) || {};
  let status = localStorage.getItem("status")
  const loginData  = JSON.parse(localStorage.getItem("login_Details"));
  // console.log("loginData===>",loginData.type);
  // console.log("loginToken",loginToken);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('login_Details');
    localStorage.removeItem('currentStep');
    localStorage.removeItem('user');
    localStorage.removeItem('IndividualcurrentStep');
    localStorage.removeItem('status');
    navigate('/login_email', {replace: true});
    


    
  };

  const handleClick = (value, arrowIcon) => {
    if (arrowIcon) {
      setShowSubmenu(true);
    }

    if (value === "aboutUs") {
      // Toggle popupItem state to open/close the popup for "About Us"
      setPopupItem(popupItem === "aboutUs" ? null : "aboutUs");
      setSubmenuData(aboutUsData);
    } else if (value === "athletes") {
      setLatestPopup(latestPopup === "latest" ? null : "latest");
      setSubmenuData(AthletesData);
    } else if (value == "notification") {
      setNotificationPopup(
        notificationPopup === "notification" ? null : "notification"
      );
      setSubmenuData(NotificationData);
    } else if (value == "infrastructure") {
      setSubmenuData(InfrastructureData);
    } else if (value == "latest") {
      setSubmenuData(LatestData);
    } else {
      // Navigate to the corresponding page
      navigate(`/${value}`);
      localStorage.setItem("activePage", value);
      handleClose();
    }
  };

  const handleSubmenu = (label) => {
    if (label == "Rankings") {
      localStorage.setItem("label", "Rankings");
    } else {
      localStorage.setItem("label", "Profile");
    }
  };

  const handleBacktoRegistration = () => {
    if (loginData?.type === "institute") {
      navigate("/final-status", { replace: true });
    } else {
      if (status) {
        console.log("statusofBack===>", status);
        navigate("/final-submit_individual_registration", { replace: true });
      } else if (currentStep?.value) {
        navigate(`/${currentStep.value}`, { replace: true });
      } else {
        console.error("No valid step to navigate to.");
      }
    }
};

  return (
    <>
      <div className="menu-popup-main">
        <Container fluid>
          <Row>
            <Col lg={4} className="default_container_wrapper px-4">
              <div className="menu-inner-left">
                <div className="cls-btn py-3 mb-5 mt-2">
                  <button onClick={() => handleClose()}> X</button>
                </div>
                {departmentsOfSports?.map((items, index) => (
                  <div
                    className="menu-inner-deptofSports "
                    key={index}
                    onClick={() => handleClick(items?.value, items.arrowIcon)}
                  >
                    {items.arrowIcon ? (
                      <div className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2">
                        <div>
                          <p className="py-2">{items.label}</p>{" "}
                        </div>

                        {items.arrowIcon && (
                          <div>
                            {" "}
                            <GoArrowRight className="left_bar_arrow_icon" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <a href={items.link} className="menu_link   w-100">
                        <div
                          className="d-flex justify-content-between w-100 menu_hover_title px-2  mb-2"
                          onClick={() =>
                            handleClick(items?.value, items.arrowIcon)
                          }
                        >
                          <div>
                            <p className="py-2">{items.label}</p>
                          </div>
                        </div>
                      </a>
                    )}
                  </div>
                ))}

                <div className="menu-inner-down ">
                  {menuPopUpOptions?.map((items, index) => (
                    <div className="menu-inner-deptofSports " key={index}>
                      {items.arrowIcon ? (
                        <div
                          className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2"
                          onClick={() =>
                            handleClick(items?.value, items.arrowIcon)
                          }
                        >
                          <div>
                            <p className="py-2">{items.label}</p>{" "}
                          </div>

                          {items.arrowIcon && (
                            <div>
                              {" "}
                              <GoArrowRight className="left_bar_arrow_icon" />
                            </div>
                          )}
                        </div>
                      ) : (
                        <a href={items.link} className="menu_link mt-2  w-100">
                          <div
                            className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2 "
                            onClick={() =>
                              handleClick(items?.value, items.arrowIcon)
                            }
                          >
                            <div>
                              <p className="py-2">{items.label}</p>
                            </div>
                          </div>
                        </a>
                      )}
                    </div>
                  ))}
                </div>
                {loginToken ? (
                  <>
                    <div
                      className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2 "
                      onClick={handleLogout}
                    >
                      <div>
                        <p className="py-2">Log Out</p>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2 "
                      onClick={handleBacktoRegistration}
                    >
                      <div>
                        <p className="py-2">Back to Registration</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <a href="/login_email" className="menu_link mt-2  w-100">
                      <div className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2 ">
                        <div>
                          <p className="py-2">Login</p>
                        </div>
                      </div>
                    </a>
                    <a href="/registration" className="menu_link mt-2  w-100">
                      <div className="d-flex justify-content-between w-100 menu_hover_title px-2 mb-2 ">
                        <div>
                          <p className="py-2">Registration</p>
                        </div>
                      </div>
                    </a>
                  </div>
                )}

                <div className="image-container ">
                  <img src={logo} alt="" className="menu-images" />
                  <img
                    src={uttharakhand_rajya}
                    alt=""
                    className="menu-images mx-3"
                  />
                </div>
              </div>
              <div className="footer-menupopup">Powered by Khelo Tech</div>
            </Col>
            <Col
              lg={4}
              className={`submenu-container p-4 mx-4  ${
                showSubmenu ? "show slide-in " : ""
              }`}
            >
              <h4 className="submenu_headeing mt-5">
                {submenuData && submenuData.name}
              </h4>
              <ul className="m-0 p-0">
                {submenuData?.listofSubmenu?.map((data) => (
                  <li className="submenu_list" key={data.id}>
                    <a
                      href={data.link}
                      className="menu_link"
                      onClick={() => handleSubmenu(data.label)}
                    >
                      {data.label}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MenuPopUp;
