

// Email API's
export const EMAIL_OTP_GENERATION_URL = "/registration/sendOtpViaEmail"
export const EMAIL_OTP_VERIFICATION_URL = "/registration/verifyEmailOtp"
export const RESEND_EMAIL_OTP_GENERATION_URL = "/registration/resendEmailOtp"

// Number API's
export const MOBILE_OTP_GENERATION_URL = "/registration/sendOtpViaSms"
export const MOBILE_OTP_VERIFICATION_URL = "/registration/verifySmsOtp"
export const RESEND_MOBILE_OTP_GENERATION_URL = "/registration/resendSmsOtp"