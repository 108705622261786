import "./styles.scss";
import { useState, useEffect, useContext } from "react";
// import {
//   getAllCityList,
//   getAllCountryList,
//   getAllStateList,
// } from "../../../apiServices/basicDetailsApi";

import layer1 from "../../../../asserts/Registration/Layer_1.png";
import layer2 from "../../../../asserts/Registration/layer2.png";
import layer3 from "../../../../asserts/Registration/layer3.png";
import layer4 from "../../../../asserts/Registration/layer4.png";
import layer5 from "../../../../asserts/Registration/layer5.png";
import layer6 from "../../../../asserts/Registration/layer6.png";
import layer7 from "../../../../asserts/Registration/layer7.png";
import bg from "../../../../asserts/Registration/img-bg.png";
import DataContext from "../../../../helpers/UserDataContext";
import { BasicDetailsContext } from "../../../contexts/basicDetailsContext/basicDetailsContext";
import { CFormCheck, CFormSelect } from "@coreui/react";
import { sports } from "../../../../common";
import { LocationContext } from "../../../../Context/LocationContext";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import { SportsContext } from "../../../../Context/SportsContext";
import { getCategorySubTypeById } from "../../../apiServices/basicDetailsApi";

function BasicDetailsSupportStaff() {
  const { kitSizeList, fetchKitSizes, sportsList, fetchSportsList } =
    useContext(SportsContext);
  const [subCategories, setSubCategories] = useState([]);
  // const { PersonalDetails, setUserData } = useContext(DataContext);
  const { countries, states, cities, fetchStates, fetchCities } =
    useContext(LocationContext);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const { userData, setUserData, correctionData } = useContext(
    VerticalStepperContext
  );

  useEffect(() => {
    fetchSportsList();
  }, []);

  useEffect(() => {
    fetchStates(userData?.country || "101");
  }, [userData?.country]);

  useEffect(() => {
    fetchCities(userData?.state);
  }, [userData?.state]);

  async function getCategories(payload) {
    let res = await getCategorySubTypeById(payload);
    setSubCategories([...res?.data]);
  }

  useEffect(() => {
    getCategories(6);
  }, []);

  const handleRadioButtonChange = (e) => {
    const selectedValue = e?.target?.value;
    // console.log("subcatid",userData)
    setUserData((prev) => {
      return { ...prev, sub_category_type: selectedValue };
    });
  };

  const handleChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  console.log(
    "subuser",
    userData.status === "ask_for_resubmit",
    !correctionData?.hasOwnProperty("sub_category_type")
  );

  return (
    <div className="basic-details-container">
      <div className="bd-header">
        <p>Basic Details</p>
      </div>
      <div>
        <label className="sport-label">Sub User Type</label>
      </div>
      <div className="bd-container-lower">
        <div className="bd-container-lower-childrens">
          {/* /// 1 */}
          {subCategories?.map((subCategory) => {
            return (
              <>
                <div className="bd-container-lower-child">
                  <div>
                    <div className="bd-lower-bg">
                      <img width="40" height="auto" src={bg} alt="" />
                    </div>
                    <div className="bd-lower-img">
                      <img width="15" height="auto" src={layer1} alt="" />
                    </div>
                  </div>
                  <div>
                    <p>{subCategory?.sub_category_name}</p>
                  </div>
                </div>
              </>
            );
          })}

          {/* ///end */}
        </div>

        <div className="bd-lower-radio-buttons">
          {subCategories.map((subCategory) => {
            return (
              <>
                <div>
                  <CFormCheck
                    type="radio"
                    name="exampleRadios"
                    // id="exampleRadios1"
                    value={subCategory?.id}
                    onChange={handleRadioButtonChange}
                    defaultChecked={
                      userData?.sub_category_type == subCategory?.id
                    }
                    disabled={
                      userData.status === "ask_for_resubmit" &&
                      !correctionData?.hasOwnProperty("sub_category_type")
                    }
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div>
        <div className="sport-details">
          <label className="sport-label">Sport</label>
          <CFormSelect
            aria-label="Select Sport"
            name="sport_id"
            value={userData?.sport_id}
            options={[
              "Select Sport",
              ...sportsList?.map((sport) => ({
                label: sport?.sport_name,
                value: sport?.id,
              })),
            ]}
            className="widthField"
            // style={{ width: "90%" }}
            onChange={(e) => handleChange("sport_id", e?.target?.value)}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("sport_id")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.sport_id?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.sport_id.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.sport_id && (
                <>
                  <span style={{ color: "red" }}>Select Sport</span>
                </>
              ))
            }
          />
        </div>
      </div>

      <div className="select-country-container">
        <div className="sport-details-1">
          <label className="sport-label" style={{padding: "0px"}}>Country</label>
          <CFormSelect
            options={[
              "Select Country",
              { label: "India", value: "101" },

              ...countries?.map((country) => ({
                label: country?.name,
                value: country?.id,
              })),
            ]}
            value={userData?.country || "101"}
            onChange={(e) => handleChange("country", e.target.value)}
            style={{ padding: "7px", width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("country")
            }
           text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.country?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.country.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.country && (
                  <>
                    <span style={{ color: "red" }}>Select Country</span>
                  </>
                ))
              }
          />
        </div>
        <div className="sport-details-2">
          <label
            className=""
            style={{
              padding: "1rem 0rem 0rem 0rem",
              maxWidth: "cit-content",
            }}
          >
            State
          </label>
          <CFormSelect
            options={[
              "Select State",
              ...states?.map((state) => ({
                label: state?.name,
                value: state?.id,
              })),
            ]}
            value={userData?.state}
            onChange={(e) => handleChange("state", e.target.value)}
            style={{ padding: "7px", width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("state")
            }
            text={
              userData.status === "ask_for_resubmit" &&
              correctionData?.state?.remark && (
                <>
                  <span style={{ color: "red" }}>
                    {correctionData.state.remark}
                  </span>
                </>
              )
            }
          />
        </div>
        <div className="sport-details-3">
          <label
            className=""
            style={{
              padding: "1rem 0rem 0rem 0rem",
              maxWidth: "cit-content",
            }}
          >
            City
          </label>
          <CFormSelect
            options={[
              "Select City",
              ...cities?.map((city) => ({
                label: city?.name,
                value: city?.id,
              })),
            ]}
            value={userData?.city}
            onChange={(e) => handleChange("city", e.target.value)}
            style={{ padding: "7px", width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("city")
            }
            text={
              userData.status === "ask_for_resubmit" &&
              correctionData?.city?.remark && (
                <>
                  <span style={{ color: "red" }}>
                    {correctionData.city.remark}
                  </span>
                </>
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default BasicDetailsSupportStaff;

//    {/* //2  */}
//    <div className="bd-container-lower-child">
//    <div>
//      <div className="bd-lower-bg">
//        <img width="40" height="auto" src={bg} alt="" />
//      </div>
//      <div className="bd-lower-img">
//        <img width="15" height="auto" src={layer1} alt="" />
//      </div>
//    </div>
//    <div>
//      <p>Sports Scientist</p>
//    </div>
//  </div>
//  {/* //3  */}
//  <div className="bd-container-lower-child">
//    <div>
//      <div className="bd-lower-bg">
//        <img width="40" height="auto" src={bg} alt="" />
//      </div>
//      <div className="bd-lower-img">
//        <img width="15" height="auto" src={layer2} alt="" />
//      </div>
//    </div>
//    <div>
//      <p>Masseurs</p>
//    </div>
//  </div>
//  {/* //4  */}
//  <div className="bd-container-lower-child">
//    <div>
//      <div className="bd-lower-bg">
//        <img width="40" height="auto" src={bg} alt="" />
//      </div>
//      <div className="bd-lower-img">
//        <img width="15" height="auto" src={layer3} alt="" />
//      </div>
//    </div>
//    <div>
//      <p>Physiotherapist</p>
//    </div>
//  </div>
//  {/* //5  */}
//  <div className="bd-container-lower-child">
//    <div>
//      <div className="bd-lower-bg">
//        <img width="40" height="auto" src={bg} alt="" />
//      </div>
//      <div className="bd-lower-img">
//        <img width="15" height="auto" src={layer4} alt="" />
//      </div>
//    </div>
//    <div>
//      <p>Psychologist</p>
//    </div>
//  </div>
//  {/* //6  */}
//  <div className="bd-container-lower-child">
//    <div>
//      <div className="bd-lower-bg">
//        <img width="40" height="auto" src={bg} alt="" />
//      </div>
//      <div className="bd-lower-img">
//        <img width="15" height="auto" src={layer5} alt="" />
//      </div>
//    </div>
//    <div>
//      <p>Nutritionist</p>
//    </div>
//  </div>
