import "./styles.scss";
import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import search from "../../asserts/E-tender/search.png";
import pdf from "../../asserts/E-tender/pdf.png";
import greenPdf from "../../asserts/E-tender/green_pdf.png";
import table_bg from "../../asserts/E-tender/tableBG.png";
import Slider from "../../components/SwipperSlider/index";
// * Core UI Components
import { CButton, CFormSelect } from "@coreui/react";
//

// api calling function
import { getAllWebsiteNotification } from "../../services/notificationForWebsite/notificationForWebsite";

function Index() {
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const { hash } = useLocation();
  const [data, setData] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const debounceTimeout = useRef(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [value, onChange] = useState([]);

  const toggle = () => setOpen(!open);
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  async function fetchData(formData = {}) {
    try {
      let res = await getAllWebsiteNotification(formData);
      setData(res?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  //
  useEffect(() => {
    fetchData();
  }, []);

  const debounceSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      let formData = new FormData();
      formData.append("title", value);
      await fetchData(formData);
    }, 500);
  };
  const handleChange = (e) => {
    setsearchValue(e.target.value);
    debounceSearch(e.target.value);
  };
  return (
    <div>
      <div className="etender-container">
        <div className="etender-container">
          <div className="text-center">
            <p className="text-white e-tender-head">WEBSITE NOTIFICATION</p>
          </div>
          <div className="e-tender-search-field">
            <div>
              <input
                placeholder="Search..."
                value={searchValue}
                onChange={handleChange}
                className="input"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="e-tender-table-section" id="E-Tender">
        <div className="e-tender-filter-options">
          {/* <div className="archived-tender-field">
            <CFormSelect
              options={[
                "Archived Tenders",
                { label: "Refernec No.", value: "1" },
                { label: "Desc", value: "2" },
                { label: "Other", value: "3" },
              ]}
              style={{
                padding: "7px",
                width: "200px",
                backgroundColor: "#F6F7F9",
              }}
            />
          </div>
          <div className="filters-e-tender">
            <CFormSelect
              options={[
                "Filter",
                { label: "Refernec No.", value: "1" },
                { label: "Desc", value: "2" },
                { label: "Other", value: "3" },
              ]}
              style={{
                padding: "7px",
                width: "200px",
                marginLeft: "10px",
                backgroundColor: "#F6F7F9",
              }}
            />
          </div> */}
        </div>

        <div className="e-tender-table-content">
          <div className="table2" id="jharkhand-e-tender-table">
            <div
              className={`${`E-tender-fopTableHeader`} position-relative py-4 body-text`}
            >
              <div>S. No</div>
              <div>Title</div>
              <div>Message</div>
              <div>URL</div>
              <div>Documents</div>
              <div>Created On</div>
            </div>
            <div className="bodyContainer">
              {data.map((item, index) => {
                return (
                  <>
                    {item?.notification_status === "1" && (
                      <div
                        className={`E-tender-fopDivContainer ${
                          hoveredItemId === item.id ? "hovered" : ""
                        }`}
                        key={index}
                        onMouseEnter={() => setHoveredItemId(item.id)}
                        onMouseLeave={() => setHoveredItemId(null)}
                      >
                        <div>{index + 1}</div>
                        <div>{item?.title}</div>
                        <div className="m-4">{item?.message}</div>
                        <div className="m-4">
                          <Link
                            className="text-decoration-none"
                            to={`${item?.url}`}
                          >
                            URL
                          </Link>
                        </div>
                        <div className="m-4">
                          <img
                            src={hoveredItemId === item.id ? greenPdf : pdf}
                            alt="PDF Icon"
                            width="30"
                            height="auto"
                          />
                        </div>
                        <div>
                          {new Date(item?.created_at).toLocaleString("en-us", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          })}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
