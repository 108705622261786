import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Stack } from "react-bootstrap";
import Loader from "../../registrationServices/registrationComponent/Loader/Loader";

function SkeletonComp({
  count = 10,
  variant = "rounded",
  height = 40,
  width = "100%",
  gap = 3,
}) {
  const [countArray] = React.useState(new Array(count).fill(0));
  return (
    <Stack gap={gap} className="">
      <Loader />
    </Stack>
  );
}

export default SkeletonComp;

{
  /* <Skeleton
  key={index}
  variant={variant}
  width={width}
  height={height}
/> */
}
