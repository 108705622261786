import { createContext , useState } from "react";



const DataContext = createContext()
export const DataContextProvider = ({ children }) => {
    
    
    // const [ContactDetails ,setContactDetails]  = useState({});
    // const [AadharVerification ,setAadharVerification] = useState({});
    // const [RegisterAs ,setRegisterAs]  = useState({});
    // const [AtomAuthentication,setAtomAuthentication] = useState({});
// asTo
    const [PersonalDetails ,setPersonalDetails] = useState({
        
    });
    // const [SportDetails ,setSportDetails] = useState({});
    // const [EducationDetails,setEducationDetails] = useState({});
    // const [BankDetails , setBankDetails] = useState({});

    // //reg as to
    // const [BasicDetails,setBasicDetails]= useState('');
    // const [PersonalRegisterDetails ,setPersonalRegisterDetails]=useState('');
    // const [BasicDetailsSupportStaff,setBasicDetailsSupportStaff] = useState('');



    return <DataContext.Provider value={ {
        // ContactDetails, setContactDetails, 
        // AadharVerification,  setAadharVerification,
        // RegisterAs, setRegisterAs, 
        // AtomAuthentication, setAtomAuthentication,
        PersonalDetails, setPersonalDetails,
        // SportDetails, setSportDetails,
        // EducationDetails,  setEducationDetails,
        // BankDetails,  setBankDetails,
        // BasicDetails,  setBasicDetails,
        // PersonalRegisterDetails,  setPersonalRegisterDetails, 
        // BasicDetailsSupportStaff, setBasicDetailsSupportStaff

     }}>  {children} </DataContext.Provider>


}

export default DataContext;
