import React from "react";
import { CFormSelect } from "@coreui/react";
import "./styles.scss";
import RangeBar from "../../HeadtoHead/RangeBar";
import { Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import user from "../../../asserts/AboutUs/User Circle.png"
import ReactApexChart from 'react-apexcharts';


const Statistics = () => {
  const monthListData = [
    {
      id: 1,
      head: "1",
      para: "Carrier High Rank"
    },
    {
      id: 2,
      head: "10",
      para: "Longest Win Streak"
    },
    {
      id: 3,
      head: "6",
      para: "3 Month Trending"
    },
  ]

  const rankData = [
    {
      id: 1,
      date: "2024-4-29",
      rank: "25"
    },
    {
      id: 2,
      date: "2024-4-22",
      rank: "30"
    },
    {
      id: 3,
      date: "2024-4-15",
      rank: "40"
    },
    {
      id: 4,
      date: "2024-4-08",
      rank: "45"
    },
    {
      id: 5,
      date: "2024-4-01",
      rank: "55"
    },
  ];

  const chartData = {
    options: {
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      colors: ['#059E70'], // Set the color to green
      stroke: {
        width: 2, // Adjust the width of the line
      },
    },
    series: [
      {
        name: 'Sales',
        data: [30, 40, 35, 50, 49, 60],
      },
    ],
  };
  return (
    <div className="athlete-profile-statistics">
      <Container>
        <Row>
          <Col lg={12}>
          <div className="headingSetion">
        <div className="statistics-filters1">

          <CFormSelect
            options={[
              "Last 12 Months",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            style={{ padding: "7px", width: "200px", marginLeft: "10px" }}
          />
          <CFormSelect
            options={[
              "Category",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            style={{ padding: "7px", width: "200px", marginLeft: "10px" }}
          />
          <CFormSelect
            options={[
              "Seasons",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            style={{ padding: "7px", width: "200px", marginLeft: "10px" }}
          />
        </div>
        <div className="win-los-container">
          <div>  <h5 className="professional_title">   Professional Win - Loss Matches</h5>

          </div>
          <RangeBar

            totalWins={7}
            teamAWins={6}
            teamBWins={1}
            title="Matches"
            leftSideText="Wins"
            rightSideText="Lost"

          />
          <div className="statistics-listing blue-bg">

            <div className="d-flex"
              style={{ "m": "none" }}
            >
              <div>
                <p className="tournaments_title">1</p>
              </div>
              <div className="py-4 tournaments_para">
                <span> Tournaments <br /> Wrestled</span>
              </div>

            </div>
            <div className="listing-box"> <p>1</p> <span> Medal <br /> Won</span> </div>
            <div className="listing-box"> <p>7</p> <span className="mx-2"> Matches <br /> Wrestled</span> </div>

          </div>

        </div>
      </div>
          </Col>
        </Row>
      </Container>
      

      <Container className="py-4">
        <Row>
          <Col lg={6}>
            <div className="card_month py-4 px-3 ">
              <div>
                <h5 className="month_title mb-5 mt-2">Last 12 Months</h5>
              </div>
              <ul className="d-lg-flex justify-content-between py-3 px-0 m-0">
                {monthListData.map((data) => {
                  return (
                    <>
                      <li className="month_list">
                        <div>
                          <h5 className="month_number">{data.head}</h5>
                          <p className="month_para">{data.para}</p>
                          <div className="border_bottom"></div>
                        </div>
                      </li>
                    </>
                  )
                })}


              </ul>
            </div>


          </Col>

          <Col lg={6}>
            <div className="card_month py-4 px-3 ">
              <div>
                <h5 className="month_title mb-4">Best Win - Last 12 Months</h5>
              </div>
              <ul className=" px-0 m-0">

                <li className="month_list  w-100">
                  <div className="d-lg-flex justify-content-between">
                    <div>
                      <h5 className="">A. Murray</h5>
                    </div>
                    <div>
                      <p className="month_para"><span className="me-3">4</span> <span className="me-3">6</span> <span className="me-3">6</span></p>
                    </div>



                  </div>
                </li>
                <li className="month_list  w-100">
                  <div className="d-lg-flex justify-content-between">
                    <div>
                      <h5 className="">M. Berrettini</h5>
                    </div>
                    <div>
                      <p className="month_para"><span className="me-3">6</span> <span className="me-3">3</span> <span className="me-3">4</span></p>
                    </div>



                  </div>
                </li>

              </ul>
              <hr />
              <div className="d-flex justify-content-center ">
                <div>
                  Ranchi, Jharkhand
                </div>
                <div className="mx-2">
                  •
                </div>
                <div >
                  Wed Mar 20
                </div>
              </div>
            </div>


          </Col>
        </Row>
      </Container>

      <Container className="card_month">
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between py-3">
              <div>
                <h4 className="historical_title mb-0">Historical - Last 12 Months</h4>
                <p className="char_work">How the Chart Work</p>
              </div>
              <div className="char_work">
              Rank History
              </div>
              
           
            </div>

           
            <div className="line-chart">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={300} // Set your desired height
              />
            </div>
          </Col>
        </Row>
      </Container>



      <Container className="py-4">
        <div>
          <h5 className="month_title mb-4 fullstack_title">Full Rank History</h5>
        </div>
        <Row className="text_center_title  mb-3">
          <Col lg={4}>

          </Col>
          <Col lg={4}>
            <div className="raking_title">Date</div>
          </Col>
          <Col lg={4}>
            <div className="raking_title ">Rank</div>
          </Col>
        </Row>
        {rankData.map((item) => {
          return (
            <>
              <Row className=" raking_cards mb-3">
                <Col lg={4}>
                  <img src={user} alt="" />
                </Col>
                <Col lg={4}>
                  <div className="raking_title">{item.date}</div>
                </Col>
                <Col lg={4}>
                  <div className="raking_title ">{item.rank}</div>
                </Col>
              </Row>
            </>
          )
        })}
      </Container>



    </div>
  );
};

export default Statistics;
