import Header from "../../components/Header";
import "./styles.scss";
import Temple from "../../asserts/AboutUs/Temple.png";
import vision from "../../asserts/MissionVision/VISION.png";
import boysPlaying from "../../asserts/MissionVision/boysPlaying.png";
import Jharkhand from "../../asserts/AboutUs/Jharkhand.png";
import archery from "../../asserts/MissionVision/archery.png";
import archeryRight from "../../asserts/MissionVision/vision_right.svg";
import Aboutus1 from "../../asserts/AboutUs/aboutus1.png";
import Aboutus2 from "../../asserts/AboutUs/aboutus2.png";
import girlRacing from "../../asserts/MissionVision/girlRacing.png";
import AboutusNew from "../../asserts/AboutUs/Jharkhand_grp.svg";
import girlPlaying from "../../asserts/MissionVision/girlPlaying.png";
import IntroductBG from "../../asserts/AboutUs/introduction-bg.webp";
import Slider from "../../components/SwipperSlider/index";
import Marque from "../../components/Marque/Index"
import {
  Vision,
  missionContent,
  missionRightContent,
  utility,
} from "../../common/aboutUs";

const MissionVision = () => {
  return (
    <div>
  
      <div className="mission-vision-container  center_title">
    
        <div className="container">
          <div className="mession_heading text-center ">
            <p
              className="text-white header-mission-vission"
              style={{ alignItems: "center" }}
            >
              MISSION AND VISION
            </p>
          </div>
          <div className="slider-cont">
            <Slider />
          </div>
        </div>
      </div>

      <div className="blue-bg mission-vision-main-container">
        <div className="row justify-content-center w-100">
          <div className="col-lg-9 text-inner text-center py-3">
            <h3 className="mission-lower-heading">Mission</h3>
            <p className="text-white mv-content">
              To encompass several key objectives aimed at promoting sports,
              nurturing talent, and fostering a healthy and active
              <br /> lifestyle across the state. While specific mission
              statements may vary, the overarching goals typically include :
            </p>
          </div>
        </div>
        <div className="container mission-right-container">
          <div className="row justify-content-center mv-lower-rl">
            <div className="col-lg-6 main-text-container">
              <div>
                {missionContent?.map((item, index) => {
                  return (
                    <p className="text-white" key={index}>
                      {item?.content}
                    </p>
                  );
                })}
              </div>
            </div>

            <div className="col-lg-6 mt-lg-5 ">
              {missionRightContent?.map((item, index) => {
                const splitContent = item?.content?.length === 2 ? true : false;
                return (
                  <>
                    <p className="text-white" key={index}>
                      {splitContent ? item?.content[0] : item?.content}
                    </p>
                    {splitContent && (
                      <p className="text-white">{item?.content[1]}</p>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="Introduction-right-girl-playing">
        <div className="img-1">
          <img
            src={girlPlaying}
            alt=""
            className="Introduction-right-girl-playing"
          />
        </div>
      </div>
      <div className="container">
        <div className="row mixed-images pb-5">
          <div className="left-image col-lg-4 mt-3">
            <img src={archery} alt="" />
          </div>
          <div className="right-image col-lg-8 mt-5">
            <img className="mb-2" src={Jharkhand} alt="" />
            <img src={archeryRight} alt="" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mission_section">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <h1 className="vision-heading">Vision:</h1>
            
              <div>
                {Vision?.map((item, index) => {
                  return (
                    <p key={index}>
                      <span className="font-vision-header">
                        {item?.heading}
                      </span>
                      <br />
                      <div className="font-vision-content">
                      {item?.content}
                      </div>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-7"></div>
          </div>
          <img width="100%" src={boysPlaying} alt="" />
        </div>
      </div>

      <div className="container py-5">
        <div className="mission-vision-bottom-section">
          <div className="col-lg-5">
            <img
              width="100%"
              className="girlRacing-img"
              src={girlRacing}
              alt=""
            />
          </div>

          <div className="mv-bottom-right">
            <div>
              {utility?.map((item, index) =>{
                return (
                  <p key={index}>
                    <span className="font-weight-bold">{item?.heading}</span>
                    <br />
                    <div className="utility-content">
                    {item?.content}
                    </div>
                  </p>
                );
              })}
            </div>
            <div>
              <div className="utility-content">
                Overall, the vision of Jharkhand sports is to harness the power
                of sports to inspire, empower, and unite its people, creating a
                healthier, more prosperous, and more inclusive society for
                generations to come.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
