import React, { useEffect } from "react";
import "./styles.scss";
import dayjs from "dayjs";
import location from "../../asserts/Calender/location.png";
import arrowKey from "../../asserts/Calender/arrow.png";
import wresting from "../../asserts/Calender/wrestling.png";
import mapPoint from "../../asserts/Calender/Map Point.svg";
import share from "../../asserts/Calender/share.png";
import favorite from "../../asserts/Calender/favorite.png";
import bookTicket from "../../asserts/Calender/Ticker Star.png";
import aeroplane from "../../asserts/Calender/airport.png";
import shirt from "../../asserts/Calender/shirt.png";
import tab from "../../asserts/Calender/tab.png";
import mobile from "../../asserts/Calender/mobile.png";
import notebook from "../../asserts/Calender/notebook.png";
import left from "../../asserts/Calender/left.png";
import forward from "../../asserts/Calender/forward.png";
import star from "../../asserts/Calender/Star.png";
import Slider from "../../components/SwipperSlider/index";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { calendar_details } from "../../common/calender";
import Marque from "../../components/Marque/Index";
import { DateRangePicker } from '@mui/x-date-pickers';
import ComingSoon from "../../components/ComingSoonPage/ComingSoon";

function Index() {
  const [value, setValue] = React.useState(dayjs());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="calender-container">
        <div className="container">
          <div className="text-center">
            <p className="text-white koulen-regular common-heading">CALENDAR</p>
          </div>
        </div>
      </div>

      <div className="calender-body">
        <div className="calenders-default-container">
          <div className="calender-dflex">
            <div className="calender-month">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateCalendar
                    
                    defaultValue={dayjs()}
                    views={["month", "year"]}
                    openTo="month"
                  />
                </DemoItem>
              </LocalizationProvider>
            </div>

            {/* <div className="date-picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                  defaultValue={[dayjs("2024-04-17"), dayjs("2024-04-24")]}
                />
              </LocalizationProvider>
            </div> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                <DemoItem>
                  <DateCalendar style={{width:"auto"}}value={value} onChange={(newValue) => setValue(newValue)} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>

            <div className="events-btn-calender" >
              <div className="Previous-event">Previous Events</div>
              <div className="featured-event">Featured Events</div>
              <div className="Live-event">Live Events</div>
              <div className="Upcoming-event">Upcoming Events</div>
            </div>
          </div>
        </div>

        {/* <div className="calender-event-details">
          <div className="calender-event-childs">
            <p className="calender-event-heading">Previous Event</p>
            <div className="d-flex-calender">
              <div>
                <img width="60" height="auto" src={location} alt="" />
              </div>

              <div className="d-flex-calender-content">
                <div>
                  <p>
                    U- I5 Jharkhand State Wrestling
                    <br /> Championship 2023
                  </p>
                </div>
                <div>
                  <p>02/05/2023 | Ranchi</p>
                </div>
              </div>
            </div>

            <div>
              <img
                width="50"
                height="auto"
                src={arrowKey}
                className="bottom-right"
                alt=""
              />
            </div>
          </div>

          <div className="calender-event-childs-2">
            <p className="calender-event-heading">Featured Event</p>
            <div className="d-flex-calender">
              <div>
                <img width="60" height="auto" src={location} alt="" />
              </div>

              <div className="d-flex-calender-content">
                <div>
                  <p>
                    U- I5 Jharkhand State Wrestling
                    <br /> Championship 2023
                  </p>
                </div>
                <div>
                  <p>02/05/2023 | Ranchi</p>
                </div>
              </div>
            </div>

            <div>
              <img
                width="50"
                height="auto"
                src={arrowKey}
                className="bottom-right"
                alt=""
              />
            </div>
          </div>

          <div className="calender-event-childs-3">
            <p className="calender-event-heading">Live Event</p>
            <div className="d-flex-calender">
              <div>
                <img width="60" height="auto" src={location} alt="" />
              </div>

              <div className="d-flex-calender-content">
                <div>
                  <p>
                    U- I5 Jharkhand State Wrestling
                    <br /> Championship 2023
                  </p>
                </div>
                <div>
                  <p>02/05/2023 | Ranchi</p>
                </div>
              </div>
            </div>

            <div>
              <img
                width="50"
                height="auto"
                src={arrowKey}
                className="bottom-right"
                alt=""
              />
            </div>
          </div>

          <div className="calender-event-childs">
            <p className="calender-event-heading">Upcoming Event</p>
            <div className="d-flex-calender">
              <div>
                <img width="60" height="auto" src={location} alt="" />
              </div>

              <div className="d-flex-calender-content">
                <div>
                  <p>
                    U- I5 Jharkhand State Wrestling
                    <br /> Championship 2023
                  </p>
                </div>
                <div>
                  <p>02/05/2023 | Ranchi</p>
                </div>
              </div>
            </div>

            <div>
              <img
                width="50"
                height="auto"
                src={arrowKey}
                className="bottom-right"
                alt=""
              />
            </div>
          </div>
        </div> */}

        {/* <p className="feb-2023">Feb 2023</p>

        <div className="calender-table-section">
          {calendar_details.map((event, index) => (
            <div key={index} className="calender-section-1">
              <div className="wrestling-row">
                <div>
                  <img width="70" height="auto" src={wresting} alt="" />
                </div>
                <div className="cal-content-1">
                  <div>
                    <strong>{event.dateTime}</strong>
                  </div>
                  <div>
                    <b>{event.time}</b>
                  </div>
                  <div>
                    <strong>{event.date}</strong>
                  </div>
                </div>
                <div className="cal-content-2">
                  <div>
                    <strong className="j-k-wrestling">{event.title}</strong>
                  </div>
                  <div>
                    <bold>{event.category}</bold>
                  </div>
                  <div className="flex-1">
                    <div>
                      <img width="20" height="auto" src={mapPoint} alt="" />
                    </div>
                    <div>
                      <p>{event.location}</p>
                    </div>
                  </div>
                </div>
                <div className="favorite-share-section flex-1">
                  <div>
                    <img width="15" height="auto" src={forward} alt="" />
                    <span className="sf-s">share</span>
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    <img width="20" height="auto" src={star} alt="" />
                    <span className="sf-s">favorite</span>
                  </div>
                </div>
              </div>

              <div className="book-tickets-section">
                <div className="container-calender">
                  <div className="section-buys-ticket">
                    <div className="left-section">
                      <img width="180" height="60" src={left} alt="" />
                    </div>
                    <div className="right-section">
                      <button className="book-ticket-btn">Book Ticket</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Index;
