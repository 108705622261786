import React, { createContext, useState } from 'react';

// Create a context
export const VerifyContactContext = createContext();

// Create a provider component
export const VerifyContactProvider = ({ children }) => {
  const [contactVerification, setContactVerification] = useState({
    mobile: "",
    mobileOtp: "",
    email: "",
    emailOtp: "",
    isMobileVerified: false,
    isEmailVerified:false,
    isEmailDisabled: false,
    isMobileDisabled: false,
    isMobileOtpDisabled: false,
    isEmailOtpDisabled:false
  }); 

  return (
    <VerifyContactContext.Provider value={{ contactVerification, setContactVerification }}>
      {children}
    </VerifyContactContext.Provider>
  );
};
