import { ApiService } from "../../config/apiServices";
import { GET_KIT_SIZE, GET_SPORTS_LIST } from "../config/sportsDetailsUrl/configUrl";



export const getAllKitSize = () => {
    return ApiService.get(`${GET_KIT_SIZE}`)
}

export const getAllSports = () =>{
    return ApiService.get(`${GET_SPORTS_LIST}`)
}

