import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import { MDBSpinner } from "mdb-react-ui-kit";

function AuthRegistration() {
  const navigate = useNavigate();
  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    setUploadedDocuments,
    uploadedDocuments,
  } = useContext(VerticalStepperContext);
  const token = localStorage.getItem("access_token") || null;
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));
  // console.log(loginDetails, "loginDetails");

  let value;
  useEffect(() => {
    if (loginDetails) {
      // if (loginDetails?.type !== "individual") {
      //   setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
      //   navigate("/registration", { replace: true });
      //   localStorage.removeItem("currentStep");
      //   localStorage.removeItem("bankDetails");
      //   localStorage.removeItem("login_Details");
      //   localStorage.removeItem("access_token");
      // }
      let userType =
        loginDetails?.user?.category_type == "5"
          ? "Athlete"
          : loginDetails?.user?.category_type == "6"
          ? "Support Staff"
          : loginDetails?.user?.category_type == "7"
          ? "Technical Official"
          : "register_as";

      if (userType === "Athlete") {
        value = "register_as_athlete";
      } else if (userType === "Support Staff") {
        value = "register_as_support_staff";
      } else if (userType === "Technical Official") {
        value = "register_as_technical_official";
      } else {
        value = "register_as";
      }

      if (loginDetails?.user?.status == "ask_for_resubmit") {
        localStorage.setItem(
          "currentStep",
          JSON.stringify({
            level: 3,
            isRegistered: true,
            userType: userType,
            value: value,
          })
        );

        setCurrentStep({
          level: 3,
          isRegistered: true,
          userType: userType,
          value: value,
        });
      } else {
        localStorage.setItem(
          "currentStep",
          JSON.stringify({
            level: loginDetails?.user?.steps,
            isRegistered: true,
            userType: userType,
            value: value,
          })
        );

        setCurrentStep({
          level: loginDetails?.user?.steps,
          isRegistered: true,
          userType: userType,
          value: value,
        });
      }
    }

    console.log(value);
    if (loginDetails) {
      // alert(`/${currentStep.value}`)
      window.location.href = `/${value || "register_as_athlete"}`;
      //   navigate(`/${currentStep.value}`);
    } else {
      let currentStep = JSON?.parse(localStorage.getItem("currentStep"));
      if (currentStep?.value) {
        currentStep.level = 3;
        localStorage.setItem("currentStep", JSON.stringify(currentStep));
        window.location.href = `${currentStep.value} `;
      } else {
        window.location.href = `register_as`;
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
      navigate("/registration", { replace: true });
      localStorage.removeItem("currentStep");
      localStorage.removeItem("bankDetails");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("access_token");
    } else if (loginDetails) {
      if (loginDetails?.type !== "individual") {
        setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
        navigate("/registration", { replace: true });
        localStorage.removeItem("currentStep");
        localStorage.removeItem("bankDetails");
        localStorage.removeItem("login_Details");
        localStorage.removeItem("access_token");
      }
    }
  }, []);

  const spinnerStyle = {
    width: "100px",
    height: "100px",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  useEffect(() => {
    let handlepopState = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handlepopState);
    return () => {
      window.removeEventListener("popstate", handlepopState);
    };
  }, []);

  return (
    <div>
      <div style={containerStyle}>
        <MDBSpinner style={spinnerStyle} role="status">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    </div>
  );
}

export default AuthRegistration;
