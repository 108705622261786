import "./styles.scss";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CFormSelect,
  CFormInput,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { getAtomAuthenticationOptions } from "../../../apiServices/registerAsApi";
import { atomAuthenticationSubUser } from "../../../apiServices/atomApi";

import CIcon from "@coreui/icons-react";
import { cilArrowRight, cilArrowCircleRight, cilPencil } from "@coreui/icons";
import copy from "../../../../asserts/Athlete/copy.png";
import Swal from "sweetalert2";

import DataContext from "../../../../helpers/UserDataContext";
import { get_Registration_Listing } from "../../../../config/registrationConfigUrl";
import { AtomContext } from "../../../contexts/atomContext/atomContext";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import { LoadingContext } from "../../../../Context/Loading/LoadingContext";
import SkeletonComp from "../../../../components/Skeleton/SkeletonComp";

function Index() {
  const { loading, setLoading } = useContext(LoadingContext);
  const { PersonalDetails, setPersonalDetails } = useContext(DataContext);
  const [otherOption, setOtherOption] = useState("");
  const [visible, setVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [sportInstitutionName, setSportInstitutionName] = useState("");
  const [email, setEmail] = useState("");
  const [atomOptions, setAtomOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { atom, setAtom } = useContext(AtomContext);
  const [subUserOptions, setSubUserOptions] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [matchedOption, setMatchedOption] = useState("");
  const { userData, setUserData, correctionData } = useContext(
    VerticalStepperContext
  );
  const options = [
    { category_name: "Club" },
    { category_name: "Institute" },
    { category_name: "Academy" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(userData, "+++===>>");
    // alert(`${userData?.organization_data?.sub_category_type}`)
    setSelectedSubCategory(userData?.organization_data?.sub_category_type);

    if (
      userData?.status === "ask_for_resubmit" ||
      userData?.status === "ongoing"
    ) {
      let categoryName = "";

      switch (userData?.organization_data?.sub_category_type) {
        case 1:
          categoryName = "Club";
          break;
        case 2:
          categoryName = "Academy";
          break;
        case 3:
          categoryName = "Institute";
          break;
        default:
          break;
      }

      if (categoryName) {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        user.category_name = categoryName;
        localStorage.setItem("user", JSON.stringify(user));
      }
    }
  }, []);
  // console.log(userData.organization_data.sub_category_type, "ATOM==>")

  const getAtomOptions = async () => {
    try {
      setLoading(true);
      let response = await getAtomAuthenticationOptions();
      if (response?.data?.status === true) {
        setAtomOptions(response?.data?.data);
        // console.log(response, "atomoptions")
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.error}`,
          });
        } else {
          console.log("error", response.data.error);
        }
      }
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const getAtomSubOptions = async () => {
    try {
      setLoading(true);

      let response = await atomAuthenticationSubUser({
        user_category_main: 2,
        category_type:
          userData.category_name ||
          userData?.organization_data?.sub_category_type,
      });

      if (response?.status == 200) {
        // console.log(response.data, "response===>");
        setSubUserOptions(response?.data?.data);
      } else {
        if (response?.data?.error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.error}`,
          });
        } else {
          console.log("error", response.data.error);
        }
      }
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAtomSubOptions();
  }, [selectedOption, userData]);

  useEffect(() => {
    if (userData?.orgnization_id) {
      const matched = subUserOptions.find(
        (option) => option.id === userData.orgnization_id
      );

      if (matched) {
        setMatchedOption(matched.name_of_legel_entity);
      }
    }
  }, [userData, subUserOptions]);
  // console.log(matchedOption, "matched==>");

  useEffect(() => {
    getAtomOptions();
    if (!atomOptions) {
      setAtomOptions(options);
    }
  }, []);

  const handleOptionClick = (data) => {
    setSelectedSubCategory(data.value);
    setSelectedOption(data?.value);
    setAtom((prev) => {
      return {
        ...prev,
        [data.name]: data.value,
      };
    });

    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      user = {
        ...user,
        category_name: data?.name,
      };
      localStorage.setItem("user", JSON.stringify(user));
    }

    setUserData((prev) => {
      return {
        ...prev,
        category_name: data?.value,
      };
    });
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setPersonalDetails((prev) => {
      return { ...prev, sportInstitution: value };
    });
  };

  const handleOtherOptionChange = (e) => {
    // Find the selected option based on the selected value in the dropdown
    const selectedOption = subUserOptions.find(
      (option) => option?.name_of_legel_entity === e?.target?.value
    );

    // Update the userData state with the selected orgnization_id
    setUserData((prevState) => ({
      ...prevState,
      orgnization_id: selectedOption?.id || "", // Set the new orgnization_id, reset if none is selected
    }));
  };

  //   useEffect(() => {
  //     const handleBackButton = (event) => {
  //         event.preventDefault();
  //         navigate(1); // Prevents going back
  //     };

  //     window.history.pushState(null, null, window.location.href);
  //     window.addEventListener('popstate', handleBackButton);

  //     return () => {
  //         window.removeEventListener('popstate', handleBackButton);
  //     };
  // }, [navigate]);

  useEffect(() => {
    if (PersonalDetails.OtherOption === "Others") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [PersonalDetails.OtherOption]);

  // console.log(userData, "atomPage==>");

  const handleSubmit = () => {
    Swal.fire({
      text: "Link Sent successfully.",
      icon: "success",
    });
  };

  const handleCopy = () => {
    const placeholderValue =
      "https://sports.jharkhand.gov.in/Tender_Docs/3c86f4a8NIT12.pdf.";
    navigator?.clipboard
      ?.writeText(placeholderValue)
      ?.then(() => {
        console.log("Text copied to clipboard");
        // Optionally, you can set a state variable to indicate that the copy action was successful
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        // Optionally, you can set a state variable to indicate that the copy action failed
      });
  };

  const handleModalSubmit = () => {
    setVisible(false);
    setPersonalDetails((prev) => {
      return {
        ...prev,
        OtherOption: "",
        sportInstitutionName: sportInstitutionName,
        sportInstitutionEmail: email,
      };
    });
    // console.log(email, sportInstitutionName, "-------");
  };
  // console.log(`${userData.orgnization_id, "id ", userData.status}  ongoing details`);

  // console.log(subUserOptions, "subuser option---")

  return (
    <div className="atom-container">
      {!loading ? (
        <>
          <div className="atom-header-container">
            <p className="atom-header">Institution Details</p>
            <span style={{ color: "red" }}>
              Selecting one of the category is compulsory
            </span>
          </div>
          <div className="select-option-atom">
            {atomOptions?.slice(0, 3)?.map((option) => (
              <div
                key={option.id}
                className={`inner-atom ${
                  (userData?.category_name ||
                    userData?.organization_data?.sub_category_type) ===
                  option?.id
                    ? "selected-option"
                    : ""
                }`}
                name="category_name"
                onClick={
                  userData?.status !== "ask_for_resubmit"
                    ? () =>
                        handleOptionClick({
                          name: option?.category_name,
                          value: option?.id,
                        })
                    : null
                }
                style={{
                  cursor:
                    userData?.status === "ask_for_resubmit"
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                <p>{option?.category_name}</p>
              </div>
            ))}
          </div>
          <div className="selectTag-atom">
            <div className="atom">
              {userData.status === "ask_for_resubmit" ? (
                <span>{matchedOption}</span>
              ) : (
                <CFormSelect
                className="stretchable-select"
                  aria-label="Select other user Type"
                  onChange={handleOtherOptionChange}
                  value={
                    subUserOptions.find(
                      (option) => option.id === userData.orgnization_id
                    )?.name_of_legel_entity || ""
                  }
                  disabled={
                    userData.status === "ask_for_resubmit" &&
                    !correctionData?.orgnization_id
                  }
                  text={
                    (userData.status === "ask_for_resubmit" &&
                      correctionData?.orgnization_id?.remark && (
                        <span style={{ color: "red" }}>
                          {correctionData.orgnization_id.remark}
                        </span>
                      )) ||
                    (userData.status === "ongoing" &&
                      !userData.orgnization_id && (
                        <span style={{ color: "red" }}>
                          Please select Organization
                        </span>
                      ))
                  }
                  
                >
                  {!userData.orgnization_id && (
                    <option value="" disabled>
                      Select Organization
                    </option>
                  )}
                  {subUserOptions.map((option) => (
                    <option key={option.id} value={option.name_of_legel_entity}>
                      {option.name_of_legel_entity}
                    </option>
                  ))}
                </CFormSelect>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="mt-3">
          <SkeletonComp />
        </div>
      )}

      {/* <div className="inputField-atom">
        <div className="input-atom">
          <CFormInput
            type="text"
            placeholder="https://atom.registration_of_sports_institution.link."
            aria-label="default input example"
            disabled
          />
        </div>
        <div>
          <img
            width="65"
            className="mx-1"
            src={copy}
            onClick={() => {
              handleCopy();
              setCopied(true);
            }}
            alt="Copy"
            style={{ cursor: "pointer" }}
          />
          {copied && <span style={{ color: "green" }}>Copied!</span>}
        </div>
      </div> */}

      {/* MODAL */}

      {visible && (
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            setVisible(false);
            setPersonalDetails((prev) => {
              return { ...prev, OtherOption: "" };
            });
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              Enter Input Fields:
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <label className="modal-label">
              Enter {PersonalDetails.sportInstitution} Name:
            </label>
            <CFormInput
              type="text"
              placeholder={`Enter ${PersonalDetails.sportInstitution} Name`}
              value={sportInstitutionName}
              onChange={(e) => setSportInstitutionName(e.target.value)}
            />
          </CModalBody>
          <CModalBody>
            <label className="modal-label">Enter Email:</label>
            <CFormInput
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
                setPersonalDetails((prev) => {
                  return { ...prev, OtherOption: "" };
                });
              }}
            >
              Close
            </CButton>
            <CButton color="primary" onClick={handleModalSubmit}>
              Submit
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </div>
  );
}

export default Index;
