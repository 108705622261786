import React, { createContext, useState, useEffect, useContext ,useCallback} from "react";
import { getAllOrganisationList } from "../../services/insitituteApiService";

// Create a Context for the location data
export const InsituteRegistrationContext = createContext();

// export const useLocation = () => useContext(LocationContext);

// Create a Provider component
export const InsituteRegistrationProvider = ({ children }) => {
  const userDataString = localStorage.getItem("login_Details");
  const userData = JSON.parse(userDataString);
  const [organisationList, setOrganisationList] = useState({});
  const [loading, setLoading] = useState(true);

  const ListOganisationById = useCallback(async (userId) => {
    setLoading(true); 
    try {
      const response = await getAllOrganisationList(userId);
      setOrganisationList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Login error:", error);
    }finally {
      setLoading(false);
    }
  },[]);


  // useEffect(() => {
  //   ListOganisationById(userData?.user?.id);
  // }, []);

  return (
    <InsituteRegistrationContext.Provider
      value={{ organisationList, ListOganisationById ,loading,setLoading}}
    >
      {children}
    </InsituteRegistrationContext.Provider>
  );
};
