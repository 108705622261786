export const LOGIN_URL = "/api/login/user";
export const PROFILE_UPDATE_URL = "/registration-organization/updateRegistrationOrganizationData";
export const DOCUMENT_UPDATE_URL = "/registration-organization/uploadDocumentOrganization";
export const GET_ORGANIZATION_LIST_URL = "/registration-organization/getRegistrationOrganizationDataById/"


// insititue Form correction url
export const GET_INSITUTE_CORRECTION_LIST_URL = "/registration-organization/getCorrection/2/"

export const GET_COUNTRY_URL = "/api/country";
export const GET_STATE_URL = "/api/state/";
export const GET_CITY_URL = "/api/city/";

