import React, { createContext, useState } from "react";

// Create a context
export const PersonalDetailsContext = createContext();

// Create a provider component
export const PersonalDetailsProvider = ({ children }) => {
  const [personalDetails, setPersonalDetails] = useState({
    dob: "",
    age: "",
    gender: "",
    mobile: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    pincode:"",
    landmark: "",
    country: "",
  });

  return (
    <PersonalDetailsContext.Provider value={{ personalDetails, setPersonalDetails }}>
      {children}
    </PersonalDetailsContext.Provider>
  );
};
