import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from "../../components/SwipperSlider/index";
import Card from 'react-bootstrap/Card';
import "./styles.css";
import { scholarCardData, scholarListData } from "../../common/scholarship"
import blackCap from "../../asserts/Scholar/Rectangle 20.png";
import Cap from "../../asserts/Scholar/Rectangle 19.png";
import women from "../../asserts/Scholar/Rectangle 21.png";
import Jharkhand from "../../asserts/AboutUs/Jharkhand.png"
import ListGroup from 'react-bootstrap/ListGroup';


const Index = () => {
    const [selectedHead, setSelectedHead] = useState("1. Eligibility Criteria");

    const accordionHandler = ((head) => {

        setSelectedHead(head);
    })

    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);
    return (
        <>
            {/* Hero section */}
            <Container fluid className="scholarship_container_bg py-5">
                <Row className="py-5">
                    <Col lg={12} className="py-5">
                        <h5 className="banner_heading">Scholarship</h5>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className="slider-cont">
                            <Slider />
                        </div>
                    </Col>
                </Row>

            </Container>

            {/* SCHOLARSHIP CARD SECTION */}

            <Container fluid className="scholarship_card_bg ">
                <Container>
                    <Row className="" id="jharkhand-sports-scholarship">
                        <Col lg={12} className="">
                            <h5 className="card_heading">Grants, Schemes & Scholarships</h5>
                            <p className="scholar_para">The Sports Authority of Jharkhand offers several scholarships to support talented student-athletes in pursuing their academic and sporting ambitions. We believe in nurturing young talent and providing them with the resources they need to excel in both academics and sports.</p>
                        </Col>
                    </Row>

                    <Row className="card_container_wrap"   >
                        {scholarCardData.map((item) => {
                            const { id, head, para } = item;
                            return (
                                <>
                                    <Col lg={4} className="">
                                        <Card className="scholarship_card_container" key={id}>
                                            <Card.Body>
                                                <h3 className="scholar_title">{head}</h3>
                                                <p className="scholar_para scholar_card_para">
                                                    {para}
                                                </p>
                                                <div className="w-100 py-3">
                                                    <button className="scholar_apply_btn w-100">Apply Now</button>
                                                </div>

                                            </Card.Body>
                                        </Card>

                                    </Col>
                                </>
                            )
                        })}

                    </Row>
                </Container>
            </Container>

            {/* SCHOLARSHIP MULTICARD SECTION */}

            <Container className="py-5 ">
                <Row className="">
                    <Col lg={4} >
                        <img src={blackCap} alt="" className="w-100" />
                    </Col>
                    <Col lg={8} className="">
                        <div>
                            <img src={Jharkhand} alt="" className="w-100 mx-lg-3 mb-4" />
                        </div>

                        <div className="white_image_wrapper">
                            <div>
                                <img src={Cap} alt="" className="w-100 multi_overlap_img " />
                            </div>

                            <div className="browen_temple_wrapper ">
                                <img src={women} alt="" className="w-100" />
                            </div>

                        </div>

                    </Col>
                </Row>
            </Container>


            {/* ACCORDION CARD SECTON */}
            <Container fluid className="scholarship_card_bg py-5">
                <Container>
                    <Row className="">
                        <Col lg={12} className="">
                            <h5 className="card_heading">Jharkhand State Sports Scholarship</h5>

                        </Col>
                    </Row>

                    <Row className="py-3">
                        <Col lg={4}>
                            <ul className="accordion_card_list_wrapper p-0 m-0">
                                {scholarListData?.map((data) => {
                                    const { id, head, para } = data;
                                    return (
                                        <>
                                            <li className="accordion_card_list py-4 px-3" key={id} onClick={() => accordionHandler(head)}>{head}</li>
                                        </>
                                    )
                                })}


                            </ul>


                        </Col>
                        <Col lg={8} className="accordion_card_des_wrapper px-5 center_title">
                            <div>
                                {/* <h5 className="eligibilty_title mx-2">Eligibility Criteria:</h5> */}
                                <ul className="">
                                    {scholarListData?.map((data) => {
                                        const { id, head, para, title, paradata } = data;
                                        return (
                                            selectedHead === head && (
                                                <div key={id}>
                                                    <h5 className="eligibilty_title mx-3">{title}</h5>
                                                    <ul className="p-0 m-0 custom-list">
                                                        {paradata?.map((item) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <p className="px-3 eligibilty_title mb-2" key={item.id}>{item?.para}</p>
                                                                    </li>
                                                                </>
                                                            )
                                                        })}

                                                    </ul>

                                                </div>
                                            )
                                        );
                                    })}


                                </ul>
                            </div>

                        </Col>


                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Index