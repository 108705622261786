import "./styles.scss";
import { useState, useContext, useRef, useEffect } from "react";
import thimb_scanner from "../../../asserts/Registration/thumb_scanner.png";
import aadhar_input from "../../../asserts/Registration/aadhar_label.png";
import mobile from "../../../asserts/Registration/Isolation_Mode.png";
import { AadhaarContext } from "../../contexts/aadhaarContext/adharContext";
import { PersonalDetailsContext } from "../../contexts/personalDetailContext/personalDetailsContext";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import DataContext from "../../../helpers/UserDataContext";
import { FaExclamationTriangle } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import { checkAadharIdentity } from "../../apiServices/aadharApi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function RegisterWithAadhar() {
  // const { PersonalDetails, setPersonalDetails } = useContext(DataContext);
  const { aadhaarData, setAadharData } = useContext(AadhaarContext);
  const { personalDetails, setPersonalDetails } = useContext(
    PersonalDetailsContext
  );
  const { currentStep, setUserData, setCurrentStep } = useContext(
    VerticalStepperContext
  );
  const [showOTPContainer, setShowOTPContainer] = useState(false);
  const [showAadharContainer, setShowAadharContainer] = useState(true);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const inputTimeoutRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProceedClick = async () => {

    try {
      const resData = await checkAadharIdentity({
        identity_card: aadhaarData.adhaarNumber,
      });

      if (resData?.data?.status == true) {
        // Swal.fire({
        //   title: "Thank You!",
        //   text: `${resData?.data?.message}`,
        //   icon: "success",
        // });
        setCurrentStep({ ...currentStep, level: 1 });
        // setShowOTPContainer(true);
        // setShowAadharContainer(false);
      } else {
        if (resData.data.message) {
          // Swal.fire({
          //   title: "Success!",
          //   text: resData?.data?.message,
          //   icon: "success",
          // });
          setShow(false);
          // setShowOTPContainer(true);
          // setShowAadharContainer(false);
        } else {
          console.log("error", resData.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === 'object' && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === 'string') {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
      setShow(false);
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Prevent the first character from being 0 or 1
    if (value.startsWith('0') || value.startsWith('1')) {
      value = value.slice(1);
    }


    setAadharData({ ...aadhaarData, adhaarNumber: value });

    if (inputTimeoutRef.current) {
      clearTimeout(inputTimeoutRef.current);
    }

    inputTimeoutRef.current = setTimeout(() => {
      if (value.length > 0 && value.length < 12) {
        setError("Please enter valid Aadhaar number");
      } else {
        setError("");
      }
    }, 1000);
  };

  const handleConfirm = () => {
    //let axios
    //if(rs=bad){ // return}e
    // let data= res.data.data
    let res = {
      data: {
        client_id: "aadhaar_v2_rCVObUoEpbHOUsmykmjr",
        full_name: "Vishal Singh Rathore",
        aadhaar_number: "1234567891234",
        dob: "1857-07-25",
        gender: "M",
        address: {
          country: "India",
          dist: "XYZ",
          state: "XYZ",
          po: "XYZ",
          loc: "XYZ",
          vtc: "XYZ",
          subdist: "XYZ",
          street: "XYZ",
          house: "XYZ",
          landmark: "XYZ",
        },
        face_status: false,
        face_score: -1,
        zip: "000000",
        profile_image:
          "/9j/4AAQSkZJRgABAgAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIs",
        has_image: true,
        email_hash: "57856f904d16e86c0cd87256b5",
        mobile_hash: "a21cdfc6058437e79a454328",
        raw_xml:
          "https://aadhaar-kyc-docs.s3.ama551420231217131441607-2023-12-17-074441792607.xml?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credenti4d8dcca346a636ada0a460fb6fc1486820ade5c4bb799ff6b55d8cd6caa41f3a",
        zip_data:
          "https://t&X-Amz-Date=20231217T074441Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=d6140e0b723bf151ab924feb45241eb4594ea6a27182685977f5214aafb14a50",
        care_of: "S/O: XXX ",
        share_code: "9820",
        mobile_verified: false,
        reference_id: "1234567897897",
        aadhaar_pdf: null,
        status: "success_aadhaar",
        uniqueness_id: "5385fd86a30a3ab99aee07b9e5b086dffb31b82f5b3a3657d",
      },
      status_code: 200,
      success: true,
      message: null,
      message_code: "success",
    };
    let data = res?.data;

    setUserData((details) => {
      return {
        ...details,
        full_name: data?.full_name || "",
        dob: data?.dob || "",
        age: data?.age || "",
        gender: data?.gender || "",
        password: "",
        address: "",
        city: "",
        state: data?.state || "",
        pincode: "",
        landmark: data?.landmark || "",
        country: data?.country || "",
        abc: "this is abc",
      };
    });

    setCurrentStep({ ...currentStep, level: 1 });
  };

  const handleResendotp = () => {
    Swal.fire({
      text: "The OTP has been sent to your Registered mobile number.",
      icon: "success",
    });
  };
  // function handleFormDetails(e) {
  //   setPersonalDetails((prev) => {
  //     return { ...prev, [e.target.name]: e.target.value };
  //   });
  // console.log(PersonalDetails);
  // }

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

  };

  const handleDummyConfirm = () => { };

  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className="center_title">
            {showAadharContainer && (
              <div className="RegisterWithAadhar-container">
                
                <div>
                <div className="thumb-image-container mb-4">
                  <img width="80" height="auto" src={thimb_scanner} alt="" />
                </div>
                  <p style={{textAlign:"justify"}}>
                    You need to enter your Aadhaar number and click on proceed
                    button <br />
                  </p>
                </div>
                <div className="input-label-aadhar">
                  <div>
                    <label className="label boldtext">Aadhaar Number</label>
                  </div>
                  <div>
                    <input
                      value={aadhaarData.adhaarNumber}
                      type="text"
                      onPaste={(event) => {
                        const pasteData = event.clipboardData.getData('text');

                        // Check if the pasted content is a number and does not start with 0 or 1
                        if (!/^\d+$/.test(pasteData) || pasteData.startsWith('0') || pasteData.startsWith('1')) {
                          event.preventDefault();
                        }
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                      }}
                      onKeyPress={(event) => {
                        const inputValue = event.target.value;

                        // Prevent non-numeric characters
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }

                        // Prevent the first character from being 0 or 1
                        if (inputValue === '' && /[01]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}

                      maxLength={12}
                      name="AdharDetails"
                      className="adharInput_title"
                      placeholder="Enter Aadhaar Number"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                        padding: "0.6rem 0rem 0rem 1rem",
                      }}
                    >
                      {error && (
                        <FaExclamationTriangle style={{ marginRight: "5px" }} />
                      )}
                      {error}
                    </p>
                  </div>
                </div>
                <div className="proceed-btn-registration">
                  <button
                    type="submit"
                    onClick={handleShow}
                    className="w-100 register_btn"
                    disabled={aadhaarData?.adhaarNumber?.length < 12}
                  >
                    Proceed
                  </button>
                </div>
                <div className="size"style={{textAlign:"justify"}}>
                  {" "}
                  <p className="note-bold" style={{marginBottom:"10px !important"}}>Note</p>
                  <span>
                    {" "}
                    Please ensure that you enter your correct Aadhaar Number as
                    it will be verified for your participation in Training
                    Programs, Sports Events, and other Sports Activities on the
                    Unified Sports Portal. Providing an accurate Aadhaar Number
                    is crucial for your continued engagement in these
                    activities.
                  </span>
                </div>
              </div>
            )}

            {showOTPContainer && (
              <div className="RegisterWithAadhar-otp-container">
                <div className="thumb-image-container">
                  <img width="60" height="auto" src={mobile} alt="" />
                </div>
                <div>
                  <p className="p-tag-otp">
                    You need to enter your OTP received on your registered
                    mobile number
                    <br /> and click on Confirm button
                  </p>
                </div>
                <div className="input-label-otp">
                  <div className="">
                    <label className="otp-label">OTP</label>
                  </div>
                  <div className="">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          onKeyDown={handleKeyDown}
                        />
                      )}
                      className="w-100"
                      inputStyle={{
                        width: "65px",
                        height: "50px",
                        fontSize: "20px",
                        margin: "10px 10px 10px 10px",
                        border: "none",
                        backgroundColor: "#ffff",
                        border: "1px solid #efe9e9",
                        boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    />
                  </div>
                </div>
                <div className="did-not-get-code-ptag">
                  <p>
                    Didn't get the code ?{" "}
                    <a className="resend-btn" onClick={handleResendotp}>
                      Resend
                    </a>
                    <span>{"  "}0:00</span>
                  </p>
                </div>
                <div className="proceed-btn-registration">
                  <button
                    type="submit"
                    onClick={handleConfirm}
                    className="register_btn w-100"
                    disabled={otp.length < 6}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}

            {show && (
              <>
                {/* <Button variant="primary" onClick={handleShow}>
                Launch static backdrop modal
              </Button> */}

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  {/* <Modal.Header closeButton >
                     <Modal.Title></Modal.Title>
                  </Modal.Header> */}
                  <Modal.Body className="modalBox">
                    <div className="modalText">Are you confirming that you have entered your correct Aadhaar Number ?</div>
                    <div className="buttonDiv">
                      <Button className="noBtn" onClick={handleClose}>
                        No
                      </Button>
                      <Button className="yesBtn" onClick={handleProceedClick}>
                        Yes
                      </Button>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleProceedClick}>
                      Yes
                    </Button>
                  </Modal.Footer> */}
                </Modal>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RegisterWithAadhar;
