import React from 'react'
import "./styles.scss"
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import LoginLeft from "../../components/LoginLeft"
import sajhaImg from "../../asserts/Footer/jharkhand logo 1 (1).png"
import jk_img from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png"
import {ReactComponent as BackIcon} from "../../asserts/login/backIcon.svg";
import{ReactComponent as ForwardIcon} from "../../asserts/login/forwardIcon.svg"

const ForgetPassword = () => {
    const navigate = useNavigate()
    const { handleSubmit, register } = useForm();
    const onSubmit = (e) => console.log("data", e);
  return (
   <>
    <div className="sign_in_right_container">
      <div className="loginLeft">
        <LoginLeft />
        <div className="jharkhand_logos_login">
          <div>
            <Link to="/">
              <img src={jk_img} width="80" />
            </Link>
          </div>
          <div>
            <Link to="/">
              <img src={sajhaImg} width="75" />
            </Link>
          </div>
        </div>
      </div>

      <div className="loginRight loginRightv2">
        <h1>Forgot Password</h1>
        <p className="px-5 mb-3">Don’t worry ! It happens. Please enter the email we will send the OTP in this email address.</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Email Id/Phone Number"
            id=" phnumber"
            className="phoneNumber_otp"
            {...register("phnumber")}
          />

            <Link to="/otp-verification">
          <button className="submit-btn" type="submit">
          Send
          </button>
            </Link>
        </form>
        <div className="arrow_container_forger_password">
          <p onClick={()=>navigate('/login_email')}>Go Back</p>
          {/* <BackIcon onClick={()=>navigate('/login_email')}/>
          <ForwardIcon onClick={()=>navigate('/otp-verification')}/> */}
        </div>
      </div>
    </div>
   </>
  )
}

export default ForgetPassword