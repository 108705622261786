
import { Stepper, Step } from 'react-form-stepper';

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { documentUpdate, getAllOrganisationList } from '../../services/insitituteApiService';
import swal from "sweetalert";
import { InsituteRegistrationContext } from '../../Context/InsituteRegistrationAllData/InsituteRegistrationAllListData';

const IndividualUpdateProfile = () => {
	const { organisationList,ListOganisationById } = useContext(InsituteRegistrationContext);
	console.log("organisationList====>",organisationList)
	
	const [goSteps, setGoSteps] = useState(0);
	const [btndisable, setBtndisable] = useState(true);
	const [btnsubmit, setBtnsubmit] = useState(true);

	const userDataString = localStorage.getItem("login_Details");
	const userData = JSON.parse(userDataString);
	const [status, setStatus] = useState("ongoing");
	const navigate = useNavigate();


	useEffect(() => {
		
			ListOganisationById(userData?.user?.id);
		
		const savedStep = localStorage.getItem('IndividualcurrentStep');
		console.log('Saved Step:', savedStep); 
		if (savedStep !== null) {
		  setGoSteps(parseInt(savedStep, 10));
		}
	}, [ ListOganisationById, userData?.user?.id]);
	  
	  // Save the current step to localStorage whenever it changes
	//   useEffect(() => {
	// 	if(goSteps != 0){
	// 		localStorage.setItem('IndividualcurrentStep', goSteps);
	// 	}
	//   }, [goSteps]);

	  const updateStepCount = ((count) => {
		setGoSteps(count)
		localStorage.setItem('IndividualcurrentStep', count);
	  })
  
	

	
	const handleFormSubmit = async (values, status) => {
		const formData = new FormData();
		for (const key in values) {
			formData.append(key, values[key]);
		}
		formData.append("tax_identification_key", values?.identification_select);
		formData.append("affiliation_key", values?.affiliation_select);
		formData.append("id", userData?.user?.id);
		formData.append("status", status);

	
		try {
			const response = await documentUpdate(formData);
			setBtnsubmit(false);
			if (response?.data?.status === true) {
				swal("Success", response?.data?.message, "success");

				if (status === "final_submit") {
					navigate("/final-status", { state: { organisationList } });
				}
			} else {
				swal("Success", response.data.message, "success");
				if (status === "final_submit") {
					navigate("/final-status", { state: { organisationList } });
				}
			}
		} catch (error) {
			swal("Error", "An unexpected error occurred. Please try again.", "error");
		}
	};

	 const handleSubmit = async (values) => {
		// setStatus("final_submit");
		await handleFormSubmit(values, "final_submit");
	};
	return (
		<>
			<Container className="py-5">
				<div className="row">
					<div className="col-xl-12 col-xxl-12">
						<div className="form-wizard ">
							<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
								<Step className="nav-link" onClick={() => setGoSteps(0)} />
								<Step className="nav-link " onClick={() => setGoSteps(1)} />

							</Stepper>
							{goSteps === 0 && (
								<>
									<StepOne setBtndisable={setBtndisable} userData={userData} organisationList={organisationList} goSteps={goSteps} ListOganisationById={ListOganisationById} updateStepCount={updateStepCount}/>
									{/* <div className="text-end toolbar toolbar-bottom p-2">
										<button className="btn btn-success sw-btn-next" onClick={() => updateStepCount(1)} disabled={btndisable}>Next</button>
									</div> */}
								</>
							)}
							{goSteps === 1 && (
								<>
									<StepTwo setBtnsubmit={setBtnsubmit} userData={userData} handleFormSubmit={handleFormSubmit} setStatus={setStatus}  ListOganisationById={ListOganisationById} organisationList={organisationList} goSteps={goSteps} updateStepCount={updateStepCount}/>
									{/* <div className="text-end toolbar toolbar-bottom p-2">
										<div className="text-end toolbar toolbar-bottom p-2">
											<button className="btn btn-success sw-btn-prev me-1" onClick={() => updateStepCount(0)}>Prev</button>
											

											<button className="btn btn-success sw-btn-next ms-1" disabled={btnsubmit} setStatus="final_submit"
												onClick={handleSubmit}
												>Submit</button>

										</div>
									</div> */}
								</>
							)}



						</div>
					</div>
				</div>

			</Container>


		</>
	)
}

export default IndividualUpdateProfile
