import React from 'react'

const Association = ({ Field,ErrorMessage ,isReadOnly,organisationList }) => {
  return (
    <>
    <h6 className="txtGap"> Sports Programs:</h6>
    <div className="row">
       <div className="col-lg-2 mb-2">
          <div className="form-group mb-3">
             <label className="text-label labels">Programs Offered</label>
             <Field
                name="programs_Offered"
                className="form-control"
                placeholder=" Programs Offered"
                disabled={isReadOnly('program_offered')}
             />
             {(!isReadOnly('program_offered') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
             <ErrorMessage name="programs_Offered" component="div" className="text-danger" />
          </div>
       </div>
       <div className="col-lg-3 mb-2">
          <div className="form-group mb-3">
             <label className="text-label labels">Facilities Available</label>
             <Field
                name="facilities"
                className="form-control"
                placeholder="Facilities Available"
                disabled={isReadOnly('facilities')}
             />
             {(!isReadOnly('facilities') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
             <ErrorMessage name="facilities" component="div" className="text-danger" />
          </div>
       </div>
       <div className="col-lg-3 mb-2">
          <div className="form-group mb-3">
             <label className="text-label labels">Achievements</label>
             <Field
                name="achievements"
                className="form-control"
                placeholder="Achievements"
                disabled={isReadOnly('achievements')}
             />
             {(!isReadOnly('achievements') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
             <ErrorMessage name="achievements" component="div" className="text-danger" />
          </div>
       </div>
       <div className="col-lg-3 mb-2">
          <div className="form-group mb-3">
             <label className="text-label labels">Affiliated Organizations</label>
             <Field
                name="affiliated_sport_body"
                className="form-control"
                placeholder="Affiliated Organizations"
                disabled={isReadOnly('affiliated_sport_body')}
             />
             {(!isReadOnly('affiliated_sport_body') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
             <ErrorMessage name="affiliated_sport_body" component="div" className="text-danger" />
          </div>
       </div>



    </div>
 </>
  )
}

export default Association
