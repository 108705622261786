import React from "react";
import "./styles.scss";

type Tprops = {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  background?: string;
  color?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ActionButton: React.FC<Tprops> = ({
  label,
  onClick,
  disabled,
  background,
  color,
}) => {
  return (
    <button
      className="btn-style wrap_title"
      disabled={disabled}
      onClick={onClick}
      style={{ background: `${background}`, color: `${color}` }}
    >
      {label}
    </button>
  );
};

export default ActionButton;
