import * as verifyContactUrl from "../config/verifyContactUrl/configUrl"
import {UnAuthApiService} from "../../config/apiServices"



// For Generating Email OTP -->
export const emailOtpGeneration = async (payload) => {
    return UnAuthApiService.post(`${verifyContactUrl.EMAIL_OTP_GENERATION_URL}`, payload)
}

// For Email OTP Verification -->
export const emailOtpVerification = async (payload) => {
    return UnAuthApiService.post(`${verifyContactUrl.EMAIL_OTP_VERIFICATION_URL}`, payload)
}


//  For Re-sending the Email --> 
export const resendEmailOtp = async (payload) => {
    return UnAuthApiService.post(`${verifyContactUrl.RESEND_EMAIL_OTP_GENERATION_URL}`, payload)
}

// For Generating Mobile Number OTP -->

export const mobileOtpGeneration = async (payload) => {
    return UnAuthApiService.post(`${verifyContactUrl.MOBILE_OTP_GENERATION_URL}`, payload)
}

//For Mobile OTP Verification --> 

export const mobileOtpVerification = async (payload) => {
  return UnAuthApiService.post(`${verifyContactUrl.MOBILE_OTP_VERIFICATION_URL}`, payload)
}

//  For Re-sending the Email --> 
export const resendMobileOtp = async (payload) => {
  return UnAuthApiService.post(`${verifyContactUrl.RESEND_MOBILE_OTP_GENERATION_URL}`, payload)
}

