import "./styles.scss";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LeftRegistration from "../../components/LeftRegistration";
import RegisterWithAadhar from "../../registrationServices/registrationComponent/RegisterWithAadhar/index";
// import { StepTrackerContext } from "../../Context/StepTrackerContext/StepTracker";
import { VerticalStepperContext } from "../../Context/VerticalStepperCount/VerticalStepper";
import { VerifyContactContext } from "../../registrationServices/contexts/verifyDetailsContext/verifyContactContext";
import { AadhaarContext } from "../../registrationServices/contexts/aadhaarContext/adharContext";
import VerifyContactDetails from "../../registrationServices/registrationComponent/VerifyContactDetails/index";
import RegisterAs from "../../components/VerifyDetails/RegisterAs";
import { Col, Container, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { registerUser } from "../../config/registrationConfigUrl";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Context/Loading/LoadingContext";

function Registration() {
  let navigate = useNavigate();
  const { currentStep, setCurrentStep, handleBack, userData, handleNext } =
    useContext(VerticalStepperContext);
  const { contactVerification, setContactVerification } =
    useContext(VerifyContactContext);
  const { aadhaarData, setAadharData } = useContext(AadhaarContext);
  const [isRegisterBtn, setIsRegisterBtn] = useState(false);
  const { loading, setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (
      contactVerification.isEmailVerified &&
      contactVerification.isMobileVerified
    ) {
      setIsRegisterBtn(true);
    }
  }, [
    currentStep.isRegistered,
    contactVerification.isEmailVerified,
    contactVerification.isMobileVerified,
  ]);

  const handleViewForm = () => {};

  const handleRegistration = async () => {
    setLoading(true);
    try {
      let response = await registerUser({
        name: userData?.name || "Guest User",
        father_name: userData?.father_name || "",
        email: contactVerification.email,
        mobile: contactVerification.mobile,
        identity_card: aadhaarData.adhaarNumber,
        steps: 2,
        user_category_main: 1,
      });

      if (response?.data?.status === true) {
        console.log(response, "res");
        Swal.fire({
          title: "Success!",
          text: `Registration Successful`,
          icon: "success",
        });

        setCurrentStep({ ...currentStep, isRegistered: true, level: 0 });
        // localStorage.setItem("currentStep",isRegistered: true, level: 0 )
        const user = {
          id: response?.data?.user?.id,
          name: response?.data?.user?.name,
        };
        localStorage.setItem(
          "access_token",
          response?.data?.authorisation?.token
        );
        localStorage.setItem("user", JSON.stringify(user));

        // window.location.href="/auth_registration"
        navigate("/auth_registration", { replace: true });
      } else {
        if (response.data.message) {
          Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      // console.log("error", error);
      const firstKey = Object.keys(error?.response?.data?.message)[0];
      const firstValue = error?.response?.data?.message[firstKey][0];
      // console.log(firstKey, firstValue, "error==>");
      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3} className="registration-container">
            <LeftRegistration />
          </Col>
          <Col lg={9}>
            <div className="right-registration-conatiner">
              <div>
                {currentStep.level === 0 && <RegisterWithAadhar />}
                {currentStep.level === 1 && <VerifyContactDetails />}
                {currentStep.level === 2 && <RegisterAs />}
              </div>
              <div className="backAndnext-Btn">
                <div></div>
                {/* <div className="back-btn">
                  {currentStep.level !== 0 && currentStep.level !== 2 && (
                    <button className="previous_btn" onClick={handleBack}>
                      <span>
                        <FiChevronLeft />
                      </span>
                      Previous{" "}
                    </button>
                  )}

                  {currentStep.level === 7 && (
                    <button className="previous_btn" onClick={handleViewForm}>
                      <span>
                        <FiChevronLeft />
                      </span>
                      View Form{" "}
                    </button>
                  )}
                </div> */}
                <div className="next-btn">
                  {currentStep.level !== 2 &&
                    currentStep.level !== 1 &&
                    currentStep.level !== 0 && (
                      <button className="Continue_btn" onClick={handleNext}>
                        Continue{" "}
                        <span>
                          <FiChevronRight />
                        </span>
                      </button>
                    )}
                  {currentStep.level == 1 && (
                    <button
                      disabled={!isRegisterBtn}
                      className="Continue_btn"
                      onClick={handleRegistration}
                    >
                      Register{" "}
                      <span>
                        <FiChevronRight />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Registration;
